import type {ErrorCode} from '@/ts/types/dto/error-codes-dto';

export class AppError extends Error {
  // eslint-disable-next-line @typescript-eslint/parameter-properties
  constructor(public message: string, public statusCode: ErrorCode) {
    super(message);
    this.name = this.constructor.name;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    Error.captureStackTrace(this, this.constructor);
  }
}
