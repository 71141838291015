<script lang="ts" setup>
import {useApi} from '@/ts/composables/stateful/use-api';
import {pagesPath} from '@/ts/router/pages-path';
import {useLoadingNotification} from '@/ts/composables/pure/use-loading-notification';
import {useRouter} from 'vue-router';
import {useInterventionLinks} from '@/ts/composables/stateful/use-links';
import {useIntervention} from '@/ts/composables/stateful/use-intervention';
import {useInterventionStore} from '@/ts/store/intervention-store';
import BalmBtn from '@/vue/atoms/balm-btn.vue';
import {PublicationState} from '@/ts/types/dto/interventions-dto';
import NoClickBtn from '@/vue/molecules/no-click-btn.vue';
import DescriptionLeftEnter from '@/vue/molecules/description-left-enter.vue';


const intervention = useInterventionStore();

const {hasContent} = useIntervention()!;
const api = useApi();
const router = useRouter();
const {aboutLink, sessionsLink, currentSessionLink, recapLink} = useInterventionLinks();
const [enroll, enrollingInProgress] = useLoadingNotification(
  async() => api.me.enrollIntervention(intervention.value!.id),
  (newIntervention) => {
    intervention.value = newIntervention;
    void router.push(currentSessionLink.value!);
  },
);
</script>
<template>
  <div class="intervention-details-page">
    <div class="header">
      <q-tabs class="tabs">
        <q-route-tab :to="aboutLink" :label="$t('about')"/>
        <q-route-tab v-if="hasContent" :to="sessionsLink" :label="$t('sessions')"/>
        <q-route-tab v-if="intervention!.publicationState === PublicationState.COMPLETED" :to="recapLink" :label="$t('recap')"/>
      </q-tabs>
    </div>

    <div class="menu">
      <description-left-enter :name="intervention!.name" size="h1" :to="pagesPath.intervention.interventionsList"/>
      <div>
        {{ intervention!.description }}
      </div>
      <div class="q-mt-auto"/>
      <no-click-btn
        v-if="[PublicationState.COMPLETED, PublicationState.IN_PROGRESS].includes(intervention!.publicationState!)"
        class="registered"
        icon="check.svg"
        theme="p3-7"
        :title="intervention!.publicationState === PublicationState.IN_PROGRESS ? $t('registered') : $t('completed')"
      />
      <balm-btn
        v-else-if="hasContent"
        paddings="p3-7"
        theme="blue"
        class="enroll-btn"
        :label="$t('enroll')"
        :loading="enrollingInProgress"
        :disable="enrollingInProgress"
        @click="enroll"
      />
    </div>

    <div class="main">
      <router-view/>
    </div>
  </div>
</template>
<style lang="scss" scoped>

.tabs {
  @include tabs;
}

@import '@/assets/sass/menu-layout';

.intervention-details-page {
  @include menu-layout; // also wraps .header .menu .main
}

.enroll-btn {
  flex-shrink: 0; // if narrow screen height it will consume space from button
  width: 100%;
}

.registered {
  margin: 0 auto;
  width: 100%;
}

.menu {
  gap: var(--Spacing-spacing-6);
}

.header {
  gap: var(--Spacing-spacing-4);
  justify-content: space-between;
}

</style>
