<script lang="ts" setup>
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';

withDefaults(defineProps<{
  label?: string;
  to?: string;
  loading?: boolean;
  icon?: string;
  title?: string;
  disable?: boolean;
  type?: 'button' | 'submit';
  theme?: 'blue' | 'green' | 'grey' | 'white' | 'plain-underline';
  paddings?: 'p1-4-1-3' | 'p2-5' | 'p3-1' | 'p3-7' | 'none';
  iconPosition?: 'left' | 'right';
}>(), {
  loading: false,
  type: 'button',
  theme: 'blue',
  paddings: 'p3-7',
  disable: false,
  iconPosition: 'right',
});
</script>

<template>
  <q-btn
    rounded
    :round="!label"
    :label="label"
    :title="title"
    :loading="loading"
    :disable="disable"
    :type="type"
    :to="to"
    :class="[theme, paddings, {'icon-only': !label}, `icon-${iconPosition}`]"
    :flat="theme === 'plain-underline'"
    class="balm-btn"
  >
    <div v-if="icon" class="icon-holder">
      <svg-illustration :name="icon"/>
    </div>
  </q-btn>
</template>
<style lang="scss" scoped>

@mixin paddings($top, $right, $bottom: $top, $left: $right) {
  &.icon-only {
    padding: $top $top;
  }

  &:not(.icon-only) {
    padding: $top $right $bottom $left;
  }
}

.icon-left .icon-holder {
  order: 1;
  margin-right: 10px;
}

.icon-right .icon-holder {
  order: 3;
  margin-left: 10px;
}

.balm-btn {
  flex-shrink: 0;
  @include body-medium-3;
  @include focus-visible;
  border-radius: var(--button-border-radius);
  width: fit-content;

  :deep(.q-btn__content) {
    display: flex;
    flex-direction: row;

    > span:first-child {
      order: 2;
    }
  }

  &.white {
    background-color: var(--Neutral-UI-neutral-ui-0);
  }

  &.grey {
    background-color: var(--Neutral-UI-neutral-ui-20);
  }

  &.grey {
    background-color: var(--Neutral-UI-neutral-ui-20);
  }

  &.blue {
    color: var(--Neutral-UI-neutral-ui-100);
    background-color: var(--Secondary-secondary-color-1);
  }

  &.green {
    color: var(--Neutral-UI-neutral-ui-100);
    background-color: var(--Secondary-secondary-color-2);
  }

  &.plain-underline {
    text-decoration: underline;
    @include body-medium-2();
  }

  &.p3-7 {
    @include paddings(var(--Spacing-spacing-3), var(--Spacing-spacing-7));
  }

  &.p2-5 {
    @include paddings(var(--Spacing-spacing-2), var(--Spacing-spacing-5));
  }

  &.p3-1 {
    @include paddings(var(--Spacing-spacing-3), var(--Spacing-spacing-1));
  }
  &.p1-4-1-3 {
    @include paddings(var(--Spacing-spacing-1), var(--Spacing-spacing-4), var(--Spacing-spacing-1), var(--Spacing-spacing-3));
  }
}
</style>
