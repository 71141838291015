<script setup lang="ts">
import BalmBtn from '@/vue/atoms/balm-btn.vue';
import {computed} from 'vue';
import {
  type Intervention,
  type Page,
  type Session,
  SessionState,
} from '@/ts/types/dto/interventions-dto';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';

const props = defineProps<{
  sessionId: UuidDTO;
  intervention: Intervention
}>();

defineEmits<{
  openHomework: [],
}>();

const pageSession = computed<Session>(() => props.intervention.sessions.find(ses => props.sessionId === ses.id)!);

const homeworkAvailable = computed(() => pageSession.value.state === SessionState.HOMEWORK_PENDING);
const homeworkPage = computed<Page | null>(() => pageSession.value.homework[0]?.pages[0] ?? null);

</script>
<template>
  <div v-if="homeworkPage && pageSession!.state !== SessionState.COMPLETED" class="sess-home-next-green">
    <div class="homework-title">
      {{ $t('homework') }}
    </div>
    <div v-if="homeworkAvailable" class="homework-descr">
      {{ $t('we_need_to_complete_homework', {homework: homeworkPage!.name}) }}
    </div>
    <div v-else class="homework-descr">
      {{ $t('good_job_completing_homework') }}
    </div>
    <balm-btn
      v-if="homeworkAvailable"
      paddings="p3-7"
      theme="white"
      class="homework-btn"
      :label="$t('open_homework')"
      @click="$emit('openHomework')"
    />
  </div>
</template>
<style scoped lang="scss">
.sess-home-next-green {
  padding: var(--Spacing-spacing-7) var(--Spacing-spacing-9) var(--Spacing-spacing-7) var(--Spacing-spacing-7);
  border-radius: var(--card-border-radius);
  display: flex;
  flex-direction: column;
  background: var(--Secondary-secondary-color-2);
}

.homework-descr {
  @include heading-5;
}


.homework-title {
  @include body-small-3;
}

.homework-btn {
  margin-top: var(--Spacing-spacing-7);
}
</style>
