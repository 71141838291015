import {pagesPath} from '@/ts/router/pages-path';
import {
  computed,
  type ComputedRef,
} from 'vue';
import {useInterventionStore} from '@/ts/store/intervention-store';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {getVueLink} from '@/ts/composables/stateful/use-route-params';
import {
  PublicationState,
  SessionState,
} from '@/ts/types/dto/interventions-dto';
import {useIntervention} from '@/ts/composables/stateful/use-intervention';
import {usePageId} from '@/ts/composables/stateful/use-page';
import {
  useSessionId,
  useSessionProgress,
} from '@/ts/composables/stateful/use-session';
import type {InterventionInProgress} from '@/ts/types/dto/home-dto';

export function useInterventionLinks(): {
  aboutLink: ComputedRef<string | null>;
  sessionsLink: ComputedRef<string | null>;
  currentSessionLink: ComputedRef<string | null>;
  recapLink: ComputedRef<string | null>;
} {
  const st = useInterventionStore();
  const {currentSession} = useIntervention();
  const aboutLink = computed(() => {
    return st.value ? getVueLink(pagesPath.intervention.interventionInfoAbout, {iid: st.value!.id}) : null;
  });
  const sessionsLink = computed(() => {
    return st.value ? getVueLink(pagesPath.intervention.interventionInfoSessions, {iid: st.value!.id}) : null;
  });
  const currentSessionLink = computed(() => {
    return st.value ? getVueLink(
      pagesPath.session.sessionSummary,
      {
        iid: st.value!.id,
        sid: currentSession.value!.id,
      },
    ) : null;
  });
  const recapLink = computed(() => {
    return st.value ? getVueLink(pagesPath.intervention.interventionRecap, {iid: st.value!.id}) : null;
  });
  return {aboutLink, sessionsLink, currentSessionLink, recapLink};
}

export function useNextLink(
  sid: ComputedRef<UuidDTO>,
  pid: ComputedRef<number>,
): ComputedRef<string> {
  const st = useInterventionStore();
  const {nextPageId} = usePageId(sid, pid);
  const {session} = useSessionId(sid);
  return computed(() => {
    if (session.value?.state !== SessionState.UNLOCK) {
      return getVueLink(
        pagesPath.session.sessionSummary,
        {
          sid: sid.value!,
          iid: st.value!.id,
        },
      );
    }
    if (nextPageId.value) {
      return getVueLink(
        pagesPath.session.sessionComponent,
        {
          pid: nextPageId.value!,
          sid: sid.value!,
          iid: st.value!.id,
        },
      );
    }
    return getVueLink(pagesPath.session.sessionSummary, {
      iid: st.value!.id,
      sid: sid.value!,
    });
  });
}

export function useCurrentPageLink(
  props: {
    intervention: InterventionInProgress | null
  },
): ComputedRef<string | null> {
  return computed(() => {
    if (!props.intervention) {
      return null;
    }
    if (!props.intervention.currentSessionState) {
      return getVueLink(pagesPath.intervention.interventionInfoAbout, {iid: props.intervention.interventionId});
    }
    if ([SessionState.LOCK, SessionState.HOMEWORK_PENDING].includes(props.intervention.currentSessionState)) {
      return getVueLink(pagesPath.session.sessionSummary, {
        iid: props.intervention.interventionId,
        sid: props.intervention.currentSessionId!,
      });
    }
    return getVueLink(pagesPath.session.sessionComponent, {
      iid: props.intervention.interventionId,
      sid: props.intervention.currentSessionId!,
      // e.g. if lastPageIndexCompleted == 1 is completed,
      // means first 2 are completed and current is 3rd
      pid: props.intervention.currentPageIndex! + 1,
    });
  });
}

export function useSessionsLinksId(id: ComputedRef<UuidDTO | null>): {
  summaryLink: ComputedRef<string>;
  currentPageLink: ComputedRef<string | null>;
} {
  const {session} = useSessionId(id);
  const {currentPageId} = useSessionProgress(id);
  const intervention = useInterventionStore();
  const summaryLink = computed(() => {
    return getVueLink(pagesPath.session.sessionSummary, {
      iid: intervention.value!.id,
      sid: session.value!.id,
    });
  });
  const currentPageLink = computed(() => {
    if (intervention.value?.publicationState === PublicationState.COMPLETED) {
      return getVueLink(pagesPath.session.sessionSummary, {
        iid: intervention.value!.id,
        sid: intervention.value?.sessions[0]?.id,
      });
    }
    if ((!currentPageId.value) || !intervention.value || intervention.value!.publicationState !== PublicationState.IN_PROGRESS) {
      return null;
    }
    // includes undefined return false
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    if ([SessionState.LOCK, SessionState.HOMEWORK_PENDING].includes(session.value?.state!)) {
      return getVueLink(pagesPath.session.sessionSummary, {
        iid: intervention.value!.id,
        sid: session.value!.id,
      });
    }
    return getVueLink(pagesPath.session.sessionComponent, {
      iid: intervention.value!.id,
      sid: session.value!.id,
      pid: currentPageId.value!,
    });
  });
  return {summaryLink, currentPageLink};
}
