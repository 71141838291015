import {defineStore} from 'pinia';
import type {BalmSidebarType} from '@/ts/types/component/balm-sidebar';

export interface DrawerStoreState {
  balmSidebarState: BalmSidebarType | null;
  isMenuDrawerOpen: boolean, // mobile only
  isUserDrawerOpen: boolean, // moblie only
}

export interface DrawerStoreActions {
  openBalmSidebarDrawer(state: BalmSidebarType): void;
  closeBalmSidebarDrawer(): void;
  openHamburgerMenuDrawer(): void;
  closeHamburgerMenuDrawer(): void;
  openUserDrawer(): void;
  closeUserDrawer(): void;
}

export const useDrawerStore = defineStore<'drawer-store', DrawerStoreState, any, DrawerStoreActions>('drawer-store', {
  state: () => ({
    balmSidebarState: null,
    isMenuDrawerOpen: false,
    isUserDrawerOpen: false,
  }),
  actions: {
    openBalmSidebarDrawer(state: BalmSidebarType): void {
      this.balmSidebarState = state;
      this.isUserDrawerOpen = false;
      this.isMenuDrawerOpen = false;
    },

    closeBalmSidebarDrawer(): void {
      this.balmSidebarState = null;
    },

    openHamburgerMenuDrawer(): void {
      this.isMenuDrawerOpen = true;
      this.isUserDrawerOpen = false;
      this.balmSidebarState = null;
    },

    closeHamburgerMenuDrawer(): void {
      this.isMenuDrawerOpen = false;
    },

    openUserDrawer(): void {
      this.isUserDrawerOpen = true;
      this.isMenuDrawerOpen = false;
      this.balmSidebarState = null;
    },

    closeUserDrawer(): void {
      this.isUserDrawerOpen = false;
    },
  },
});