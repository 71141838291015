<script setup lang="ts">
import {pagesPath} from '@/ts/router/pages-path';
import {GIT_HASH, SHOW_REVISION} from '@/ts/utils/consts';
</script>

<template>
  <router-link :to="pagesPath.main.home" class="header-logo focus-visible">
    <img src="@/assets/img/balm.svg" class="logo"/>
    <div v-if="SHOW_REVISION" class="git-hash text-sm-light">
      {{ GIT_HASH }}
    </div>
  </router-link>
</template>

<style lang="scss" scoped>

.header-logo {
  position: relative;
}

.git-hash {
  position: absolute;
  top: 17px;
  left: 0px;
  font-size: 10px;
  color: var(--Neutral-UI-neutral-ui-80);
  width: 200px;
}

</style>
