export enum ErrorCodesDto {
    UNKNOWN = 'unknown',
    RESOURCE_NOT_FOUND = 'resource_not_found',
    UNAUTHORIZED = 'unauthorized',
    BAD_REQUEST = 'bad_request',
    CONFLICT = 'conflict',
    MISMATCH_INTERVENTION = 'mismatch_intervention',
    MISMATCH_SESSION = 'mismatch_session',
    MISSING_QUESTION_ANSWERED = 'missing_question_answered',
    INVALID_AGE = 'invalid_age',
    DUPLICATED_EMAIL = 'duplicated_email',
    MISMATCH_TOS_VERSION = 'mismatch_tos_version',
    METHOD_ARGUMENT_TYPE_MISMATCH = 'method_argument_type_mismatch',
    INVALID_MARKETING_CODE = 'invalid_marketing_code',
    INVALID_PASSWORD = 'invalid_password',
    PASSWORD_CHANGE_ISSUE = 'password_change_issue',
}

export enum AppErrorCode {
    NETWORK_ERROR = 'network_error',
    SESSION_EXPIRED = 'session_expired',
    UNKNOWN = 'unknown',
}

export type ErrorCode = AppErrorCode | ErrorCodesDto;
