<script lang="ts" setup>
import type {Journal} from '@/ts/types/dto/interventions-dto';
import ReadOnlyNonCollapsableTitle from '@/vue/molecules/read-only/helpers/read-only-non-collapsable-title.vue';

defineProps<{
  journal: Journal;
}>();
</script>

<template>
  <read-only-non-collapsable-title
    :content="journal.prompt"
    :content2="journal.answer"
    :header="journal.name"
    :active="true"
  />
</template>

