<script lang="ts" setup>
import CompletedProgress from '@/vue/atoms/completed-progress.vue';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {computed} from 'vue';
import PartLeftBlock from '@/vue/molecules/part-left-block.vue';
import {useSessionsLinksId} from '@/ts/composables/stateful/use-links';
import {useSessionId} from '@/ts/composables/stateful/use-session';
import {PartLeftBlockClass} from '@/ts/types/component/part-left-block';
import {useRouterSectionParams} from '@/ts/composables/stateful/use-route-params';
import {SessionState} from '@/ts/types/dto/interventions-dto';

const props = defineProps<{ sessionId: UuidDTO, forceState?: PartLeftBlockClass}>();
const sessionId = computed(() => props.sessionId);
const {sid} = useRouterSectionParams();
const {session, order, completedPercent, inProgress} = useSessionId(sessionId);
const {summaryLink} = useSessionsLinksId(sessionId);
const state = computed<PartLeftBlockClass>(() => {
  if (sessionId.value === sid.value) {
    return PartLeftBlockClass.CURRENT_PAGE;
  }
  if (props.forceState) {
    return props.forceState;
  }
  if (session.value?.state === SessionState.COMPLETED) {
    return PartLeftBlockClass.COMPLETED;
  }
  if (inProgress.value) {
    return PartLeftBlockClass.LAST_IN_PROGRESS;
  }
  return PartLeftBlockClass.NOT_STARTED;
});

</script>
<template>
  <part-left-block
    :to="summaryLink"
    :title="`${$t('session')} ${order}`"
    :state="state"
    :description="session!.name"
  >
    <completed-progress v-if="inProgress " :completed="completedPercent"/>
  </part-left-block>
</template>
