import type {RouteRecordRaw} from 'vue-router';
import {pagesPath} from '@/ts/router/pages-path';
import InterventionDetailsPage from '@/vue/pages/interventions/intervention-details-page.vue';
import InterventionAboutPage from '@/vue/pages/interventions/intervention-about-page.vue';
import InterventionRecapPage from '@/vue/pages/interventions/intervention-recap-page.vue';
import InterventionSessionPage from '@/vue/pages/interventions/intervention-sessions-page.vue';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {getVueLink} from '@/ts/composables/stateful/use-route-params';

export const interventionPages: RouteRecordRaw[] = [
  {
    path: pagesPath.intervention.interventionFetch,
    redirect: (to) => getVueLink(pagesPath.intervention.interventionInfo, {iid: to.params.iid as UuidDTO}),
  },
  {
    path: pagesPath.intervention.interventionInfo,
    component: InterventionDetailsPage,
    meta: {
      disablePageScrollingInTemplate: true,
    },
    children: [
      {
        path: pagesPath.intervention.interventionInfo,
        redirect: (to) => getVueLink(pagesPath.intervention.interventionInfoAbout, {iid: to.params.iid as UuidDTO}),
      },
      {
        path: pagesPath.intervention.interventionInfoAbout,
        component: InterventionAboutPage,
      },
      {
        path: pagesPath.intervention.interventionInfoSessions,
        component: InterventionSessionPage,
      },
      {
        path: pagesPath.intervention.interventionRecap,
        component: InterventionRecapPage,
      },
    ],
  },
];
