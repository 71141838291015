<script setup lang="ts">
import {useInterventionStore} from '@/ts/store/intervention-store';
import RecapComponent from '@/vue/molecules/components/recap-component.vue';

const intervention = useInterventionStore();

</script>
<template>
  <div class="page">
    <recap-component v-if="intervention" :intervention="intervention"/>
  </div>
</template>
<style lang="scss" scoped>
.page {
  margin: 0 auto;
  @include q-page-left
}
</style>
