<script setup lang="ts">
import type {LegalDocItem} from '@/ts/types/dto/tos-dto';
import SignUpAgreementCheck from '@/vue/organisms/sign-up/sign-up-agreement-check.vue';
import HtmlFormatted from '@/vue/molecules/html-formatted.vue';
import BalmDialog from '@/vue/atoms/balm-dialog.vue';
import {ref} from 'vue';
import {QForm} from 'quasar';

defineProps<{
  terms: LegalDocItem;
  privacy: LegalDocItem;
}>();

const signUpForm = ref<QForm>(null!);

function submitForm(): void {
  signUpForm.value!.submit();
}

defineEmits<{
  finish: []
}>();

defineExpose({
  submitForm,
});
const dialongOpened = ref<boolean>(false);

</script>
<template>
  <q-form ref="signUpForm" @submit="$emit('finish')">
    <h2 class="h1">
      {{ $t('consent') }}
    </h2>
    <p class="consent-p">
      {{ $t('consent_body') }}
    </p>
    <sign-up-agreement-check
      :modal-html="terms.legalCopyHTML"
      :header="$t('terms_of_use_star')"
      :text="$t('i_agree_to_terms')"
      :further-info-key="$t('terms_of_use_star')"
    />
    <sign-up-agreement-check
      :modal-html="privacy.legalCopyHTML"
      :header="$t('health_data')"
      :text="$t('i_agree_to_health_data')"
    />
    <sign-up-agreement-check
      :modal-html="privacy.legalCopyHTML"
      :header="$t('product_improvement')"
      :text="$t('i_agree_to_product_improvement')"
    />
    <sign-up-agreement-check
      :modal-html="privacy.legalCopyHTML"
      :header="$t('anonymization')"
      :text="$t('i_agree_to_anonymization')"
    />
    <p class="consent-p">
      {{ $t('consent_affirmation') }}
      <span class="further-info" @click.stop="dialongOpened = true">{{ $t('privacy_policy') }}</span>
      <balm-dialog v-model="dialongOpened" theme="neutral-0">
        <html-formatted :html="privacy.legalCopyHTML" background="neutral-0"/>
      </balm-dialog>
    </p>
  </q-form>
</template>
<style lang="scss" scoped>
.h1 {
  margin-bottom: var(--Spacing-spacing-3);
}

.further-info {
  text-decoration: underline;
  margin-left: 0;
  cursor: pointer;
}
</style>
