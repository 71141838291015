import {nextTick, onMounted, onUnmounted, ref, watchEffect, type Ref} from 'vue';

export function useFocusTrap(focusRef: Ref<HTMLElement|undefined>, enabled: Ref<boolean>): void {
  const firstFocusable = ref<HTMLElement|null>(null);
  const lastFocusable = ref<HTMLElement|null>(null);

  function updateFocusableElements(): void {
    if (!focusRef.value) {
      return;
    }

    const focusableElements = focusRef.value.querySelectorAll(
      'a[href], button, textarea, input, select, [tabindex]:not([tabindex="-1"])',
    );

    firstFocusable.value = focusableElements[0] as HTMLElement;
    lastFocusable.value = focusableElements[focusableElements.length - 1] as HTMLElement;
  }

  function trapFocus(event: KeyboardEvent): void {
    if (!enabled.value || !focusRef.value) {
      return;
    }

    const isTabForward = event.key === 'Tab' && !event.shiftKey;
    const isTabBackward = event.key === 'Tab' && event.shiftKey;
    if (isTabForward && document.activeElement === lastFocusable.value) {
      event.preventDefault();
      firstFocusable.value?.focus();
    } else if (isTabBackward && document.activeElement === firstFocusable.value) {
      event.preventDefault();
      lastFocusable.value?.focus();
    }
  }

  watchEffect(() => {
    if (enabled.value) {
      updateFocusableElements();
      document.addEventListener('keydown', trapFocus);
      void nextTick(() => {
        firstFocusable.value?.focus();
      });
    } else {
      document.removeEventListener('keydown', trapFocus);
    }
  });

  onMounted(updateFocusableElements);
  onUnmounted(() => {
    document.removeEventListener('keydown', trapFocus);
  });
}
