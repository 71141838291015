<script lang="ts" setup>
import {useRouterSectionParams} from '@/ts/composables/stateful/use-route-params';
import {useApi} from '@/ts/composables/stateful/use-api';
import {useCreateIntervention} from '@/ts/store/intervention-store';
import {useLoadingData} from '@/ts/composables/pure/use-loading-data-ref';
import {
  watch,
} from 'vue';
import LoadingError from '@/vue/templates/loading-error.vue';
import {useLogger} from '@/ts/composables/pure/use-logger';

const {iid} = useRouterSectionParams();

const api = useApi();
const logger = useLogger();
const intervention = useCreateIntervention(null, 'intervention-fetch-page');

const [submitHandler, load, err] = useLoadingData(intervention, async() => api.me.getIntervention(iid.value!));

watch(iid, async(value, oldValue) => {
  logger.debug(`Fetching intervention onWatch ${oldValue} -> ${value}`)();
  await submitHandler();
}, {immediate: true});

</script>
<template>
  <loading-error id="intervention-provider-page" :loading="load" :error="err" type="page">
    <router-view/>
  </loading-error>
</template>
