<script setup lang="ts">
import type {Question} from '@/ts/types/dto/interventions-dto';
import type {
  QuestionAnswerBody,
  QuestionAnswerMultiChoiceBody,
  QuestionAnswerNumericBody,
  QuestionnaireAnswers,
} from '@/ts/types/dto/session-request-dto';
import {
  computed,
} from 'vue';
import ErrorBox from '@/vue/molecules/error-box.vue';
import QuestionNumericComponent from '@/vue/molecules/components/question/question-numeric-component.vue';
import QuestionMultichoiceComponent from '@/vue/molecules/components/question/question-multichoice-component.vue';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';

const props = defineProps<{
  question: Question;
  questionnaireId: UuidDTO;
}>();

defineEmits<{
  readyToGoNext: [],
}>();

const model = defineModel<QuestionnaireAnswers | null>();

const value = computed<QuestionAnswerBody | null>({
  get() {
    return model.value?.answers ?? null;
  },
  set(newValue) {
    model.value = {
      answers: newValue!,
      userComponentId: props.question.userComponentId,
      questionId: props.question.contentId,
      questionnaireId: props.questionnaireId,
    };
  },
});

</script>
<template>
  <question-numeric-component
    v-if="question.numeric"
    v-model="value as QuestionAnswerNumericBody|null"
    :question="question"
  />
  <question-multichoice-component
    v-else-if="question.multiChoice"
    v-model="value as QuestionAnswerMultiChoiceBody|null"
    :question="question"
    @ready-to-go-next="$emit('readyToGoNext')"
  />
  <error-box v-else/>
</template>
