<script setup lang="ts">
import BalmBtn from '@/vue/atoms/balm-btn.vue';
import {
  type Intervention,
  PublicationState,
} from '@/ts/types/dto/interventions-dto';
import {getVueLink} from '@/ts/composables/stateful/use-route-params';
import {pagesPath} from '@/ts/router/pages-path';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {SHOW_WITHDRAW_BTN} from '@/ts/utils/consts';
import SessionGoToCurrent from '@/vue/molecules/session-summary/session-go-to-current.vue';
import SessionHomeworkLink from '@/vue/molecules/session-summary/session-homework-link.vue';

const props = defineProps<{
  sessionId: UuidDTO;
  intervention: Intervention
}>();

defineEmits<{
  openHomework: [],
}>();

</script>
<template>
  <session-homework-link
    :intervention="intervention"
    :session-id="sessionId"
    @open-homework="$emit('openHomework')"
  />
  <session-go-to-current
    v-if="intervention.publicationState === PublicationState.IN_PROGRESS"
    :intervention="intervention"
    :session-id="sessionId"
  />
  <balm-btn
    v-if="SHOW_WITHDRAW_BTN"
    :label="$t('view_edit')"
    :to="getVueLink(pagesPath.session.sessionComponent, {
      iid: props.intervention.id,
      sid: sessionId,
      pid: 1,
    })"
  />
</template>
