<script setup lang="ts">
import {
  type Questionnaire,
  QuestionnaireType,
} from '@/ts/types/dto/interventions-dto';
import {
  computed,
  ref,
  watch,
} from 'vue';
import {
  AnswerComponentType,
  type QuestionnaireAnswers,
  type SessionRequestQuestionnaire,
} from '@/ts/types/dto/session-request-dto';
import QuestionComponent from '@/vue/molecules/components/question/question-component.vue';
import {useLogger} from '@/ts/composables/pure/use-logger';
import ContentBlock from '@/vue/molecules/components/helpers/content-block.vue';

const props = defineProps<{
  questionnaire: Questionnaire;
}>();
const logger = useLogger();
const model = defineModel<SessionRequestQuestionnaire[] | null>();
const questionsClass = computed(() => {
  if (props.questionnaire.type === QuestionnaireType.GRID_LAYOUT) {
    return 'questionnaire-grid';
  }
  if (props.questionnaire.type === QuestionnaireType.SHOW_ONLY_QUESTIONS) {
    return 'questionnaire-only-questions';
  }
  throw new Error('Invalid questionnaire type');
});

// this should be a separate value as v-model=model[i] doesn't execute computed setter
const value = ref<QuestionnaireAnswers[]>([]);

watch(
  value,
  (newValue) => {
    logger.debug(`watch ${props.questionnaire.id} {}`, newValue)();
    model.value = newValue.map(((nv: QuestionnaireAnswers) => ({
      type: AnswerComponentType.QUESTIONNAIRE,
      questionnaireAnswers: nv,
    })));
  },
  {deep: true},
);

</script>
<template>
  <content-block
    v-if="questionnaire.image || questionnaire.description || questionnaire.name"
    :content="questionnaire.description"
    :image="questionnaire.image"
    class="questionnaire-grid-cont"
    :name="questionnaire.name"
  />
  <div class="questionnaire-grid-vert" :class="questionsClass">
    <question-component
      v-for="(question, i) in questionnaire.questions"
      :key="question.contentId"
      v-model="value[i]"
      :questionnaire-id="props.questionnaire.id"
      :question="question"
    />
  </div>
</template>
<style scoped lang="scss">

.questionnaire-grid-cont {
  margin-bottom: var(--Spacing-spacing-7);
}

.questionnaire-grid {
  gap: var(--Spacing-spacing-4);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.questionnaire-grid-vert {
   --btn-background-on-card: var(--Neutral-UI-neutral-ui-10);
}

.questionnaire-only-questions {
  display: flex;
  gap: var(--Spacing-spacing-8);
  flex-direction: column;
}

</style>
