import {
  computed,
  type ComputedRef,
} from 'vue';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import type {Page} from '@/ts/types/dto/interventions-dto';
import {useSessionId} from '@/ts/composables/stateful/use-session';

export function usePageId(sid: ComputedRef<UuidDTO>, pid: ComputedRef<number>): ({
  page: ComputedRef<Page>
  nextPageId: ComputedRef<number | null>
}) {
  const {session} = useSessionId(sid);
  const page = computed(() => session.value!.pages[pid.value - 1]!);
  const nextPageId = computed(() => {
    if (session.value!.pages.length > pid.value) {
      return pid.value + 1;
    }
    return null;
  });
 return {page, nextPageId};
}
