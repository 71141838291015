<script lang="ts" setup>
import type {Table} from '@/ts/types/dto/interventions-dto';
import TableComponent from '@/vue/molecules/components/table-component.vue';
import ReadOnlyNonCollapsable from '@/vue/molecules/read-only/helpers/read-only-non-collapsable.vue';

defineProps<{
  table: Table;
}>();
</script>

<template>
  <read-only-non-collapsable :active="false" default-opened>
    <table-component :table="table" class="table" merged/>
  </read-only-non-collapsable>
</template>
<style lang="scss" scoped>
.table {
  margin: calc(-1*var(--Spacing-spacing-3)) 0 ;
}
</style>
