import type {FetchWrapper} from '@/ts/api/fetch-wrapper';
import type {
  GetInterventionResponse,
  GetInterventionsResponse,
  Homework,
  QuestionnaireReview,
  Session,
} from '@/ts/types/dto/interventions-dto';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import type {
  PutSessionRequest,
  QuestionnaireAnswers,
} from '@/ts/types/dto/session-request-dto';
import type {ContentHomeResponse} from '@/ts/types/dto/home-dto';
import type {UserResponse} from '@/ts/types/dto/user-dto';
import type {ChangeCurrentUserPasswordRequest} from '@/ts/types/dto/change-password-dto';

/**
 * {@link https://api-dev.ip.significo.dev/swagger-ui/index.html?urls.primaryName=me|Me API}
 */
export class MeApi {
  public constructor(private readonly fetch: FetchWrapper) {
  }

  public async getInterventions(): Promise<GetInterventionsResponse> {
    return this.fetch.get({
      url: '/interventions',
    });
  }

  public async getIntervention(id: UuidDTO): Promise<GetInterventionResponse> {
    return this.fetch.get({
      url: `/interventions/${id}`,
    });
  }

  public async postQuestionnaire(body: QuestionnaireAnswers[]): Promise<void> {
    return this.fetch.post({
      url: '/questionnaire',
      body,
      emptyResponse: true,
    });
  }

  public async changePassword(body: ChangeCurrentUserPasswordRequest): Promise<void> {
    return this.fetch.put({
      url: '/change-password',
      body,
      emptyResponse: true,
    });
  }

  public async getQuestionnaireReview(
    interventionId: UuidDTO,
    sessionId: UuidDTO,
    questionnaireReviewId: UuidDTO,
  ): Promise<QuestionnaireReview> {
    return this.fetch.get({
      url: `/interventions/${interventionId}/sessions/${sessionId}/questionnaire-review/${questionnaireReviewId}`,
    });
  }


  public async unlockSession(
    interventionId: UuidDTO,
  ): Promise<QuestionnaireReview> {
    return this.fetch.put({
      emptyResponse: true,
      url: `/qa/interventions/${interventionId}/unlock-current-session`,
    });
  }

  public async getMe(): Promise<UserResponse> {
    return this.fetch.get({
      url: '',
    });
  }

  public async getHomeContent(): Promise<ContentHomeResponse> {
    return this.fetch.get({
      url: '/home-content',
    });
  }

  public async putSession(
    interventionId: UuidDTO,
    sessionId: UuidDTO,
    pageId: UuidDTO,
    body: PutSessionRequest,
  ): Promise<void> {
    return this.fetch.put({
      url: `/interventions/${interventionId}/sessions/${sessionId}/pages/${pageId}`,
      body,
      emptyResponse: true,
    });
  }

  public async putHomework(
    interventionId: UuidDTO,
    sessionId: UuidDTO,
    userHomeworkId: UuidDTO,
    body: PutSessionRequest,
  ): Promise<void> {
    return this.fetch.put({
      url: `/interventions/${interventionId}/sessions/${sessionId}/homework/${userHomeworkId}`,
      body,
      emptyResponse: true,
    });
  }

  public async postHomeWork(
    interventionId: UuidDTO,
    sessionId: UuidDTO,
  ): Promise<Homework> {
    return this.fetch.post({
      url: `/interventions/${interventionId}/sessions/${sessionId}/homework`,
    });
  }

  public async enrollIntervention(id: UuidDTO): Promise<GetInterventionResponse> {
    return this.fetch.post({
      url: `/interventions/${id}/enroll`,
    });
  }

  public async withdrawIntervention(id: UuidDTO): Promise<void> {
    return this.fetch.delete({
      url: `/interventions/${id}/enroll`,
      emptyResponse: true,
    });
  }

  public async getSession(interventionId: UuidDTO, sessionId: UuidDTO): Promise<Session> {
    return this.fetch.get({
      url: `/interventions/${interventionId}/sessions/${sessionId}`,
    });
  }
}
