<script setup lang="ts">
import BalmHeader from '@/vue/organisms/header/balm-header.vue';
import BalmSidebar from '@/vue/organisms/sidebar/balm-sidebar.vue';
import {useUserStore} from '@/ts/store/user-store';
import ScreenSizeWarning from '@/vue/organisms/screen-size-warning.vue';
import type {NavigationItem} from '@/ts/types/component/navigation';
import {SHOW_SCREEN_SIZE} from '@/ts/utils/consts';
import {useScreenSize} from '@/ts/composables/stateful/use-screen-size';
import {useRouter} from 'vue-router';
import {computed} from 'vue';
import {useOopsieStore} from '@/ts/store/oopsie.store';
import {storeToRefs} from 'pinia';

const userStore = useUserStore();

const props = defineProps<{
  enableGuideDrawer?: boolean;
  mainNavigationItems?: NavigationItem[];
}>();

defineEmits<{
  clickMainNavigationItem: [string],
}>();

const router = useRouter();

const isScrollingDisabled = computed((): boolean => {
  return router.currentRoute.value.meta.disablePageScrollingInTemplate === true;
});

const {screenSize} = useScreenSize();

const oopsieStore = useOopsieStore();
const {oopsPageTemplateVisible} = storeToRefs(oopsieStore);

const enableGuideDrawer = computed(() => {
  return props.enableGuideDrawer && !oopsPageTemplateVisible.value;
});

</script>
<template>
  <div class="global-page-template">
    <balm-header
      :main-navigation-items="mainNavigationItems"
      @clickMainNavigationItem="$emit('clickMainNavigationItem', $event)"
    />
    <main class="main">
      <div class="content" :class="{'content--no-scroll': isScrollingDisabled}">
        <slot/>
      </div>
      <balm-sidebar
        v-if="enableGuideDrawer"
        :faq="userStore.homeContent!.faq"
        :resources="userStore.homeContent!.resources"
        :glossaries="userStore.homeContent!.glossaries"
        :imprint="userStore.homeContent!.imprint"
      />
    </main>
    <screen-size-warning/>
    <div v-if="SHOW_SCREEN_SIZE" class="screen-size">
      {{ screenSize }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.global-page-template {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.main {
  height: calc(100% - 50px); // mobile header 50px
  display: flex;
  flex-direction: row;

  @include screen-lg {
    height: calc(100% - 80px); // desktop header 80px
  }
}

.content {
  width: 100%;
  &:not(.content--no-scroll) {
    overflow: auto;
    //padding: var(--Spacing-spacing-7) 0;
  }
}

.screen-size {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: var(--Neutral-UI-neutral-ui-60);
  font-size: 10px;
  padding: 0 5px;
  z-index: 2;
  line-height: 16px;
  color: white;
  border-top-right-radius: 6px;
}

</style>
