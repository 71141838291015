<script lang="ts" setup>
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {
  computed,
  onBeforeMount,
} from 'vue';
import type {
  Option,
  QuestionMultiChoice,
} from '@/ts/types/dto/interventions-dto';
import {useLogger} from '@/ts/composables/pure/use-logger';
import FormRequired from '@/vue/atoms/form-required.vue';

const model = defineModel<UuidDTO[]>();
const props = defineProps<{
  question: QuestionMultiChoice;
  theme: 'grid' | 'vertical';
}>();
const logger = useLogger();
const emit = defineEmits<{
  readyToGoNext: [],
}>();

const options = computed<Option[]>(() => props.question.multiChoice.options);

const isSingleChoice = computed(() => {
  return props.question.multiChoice.maxOptions === 1;
});

onBeforeMount(() => {
  logger.debug(`onBeforeMount ${props.question.contentId}`)();
  if (model.value && model.value.length > 0) {
    logger.trace(`Value of ${props.question.contentId} already set`)();
  } else if (props.question.multiChoiceSelectedIds) {
    logger.trace(`emit ${props.question.contentId} => ${props.question.multiChoiceSelectedIds.join(',')}`)();
    model.value = props.question.multiChoiceSelectedIds;
  } else if (!model.value) {
    logger.trace(`emit ${props.question.contentId} => []`)();
    model.value = [];
  }
});

const isValid = computed(() => {
  return (model.value?.length ?? 0) >= props.question.multiChoice.minOptions
    && (model.value?.length ?? 0) <= props.question.multiChoice.maxOptions;
});

function onSelect(option: Option): void {
  if (model.value!.includes(option.id)) {
    logger.trace(`emit remove ${props.question.contentId} =>${option.id}`)();
    model.value = model.value!.filter((id) => id !== option.id);
  } else {
    let newVal: UuidDTO[] = [];
    if (isSingleChoice.value) {
      newVal = [];
    } else if (model.value!.length >= props.question.multiChoice.maxOptions) {
      return;
    } else {
      newVal = model.value!;
    }
    const emittedValue: UuidDTO[] = [...newVal, option.id];
    logger.trace(`emit add ${props.question.contentId} => ${emittedValue.join(',')}`)();
    model.value = emittedValue; // push doesn't work since we need to emit a new array
    if (isSingleChoice.value && emittedValue.length > 0) {
      emit('readyToGoNext');
    }
  }
}

</script>
<template>
  <div class="question-grid-select" :class="theme">
    <div class="question" v-html="question.name"/>
    <div class="answers">
      <q-btn
        v-for="option in options"
        :key="option.id"
        flat
        :label="option.text"
        class="answer"
        :class="{selected: model!.includes(option.id)}"
        @click="onSelect(option)"
      />
      <form-required
        :id="`question-grid-${question.contentId}`"
        :valid="isValid"
        :message="$t('answer_questionnaire')"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.question {
  flex: 1;
  margin-bottom: var(--Spacing-spacing-5);
  @include heading-5;
}

.answers {
  display: flex;
  flex-wrap: wrap;
  gap: var(--Spacing-spacing-2);
}

.vertical {
  display: flex;

  .question {
    padding: 0 var(--Spacing-spacing-6) 0 0;
    align-self: center;
    @include heading-2;
  }

  .answers {
    flex: 1;
    flex-direction: column;
  }

  .answer {
    width: 100%;
    background-color: var(--btn-background-on-card);
    border-radius: var(--Spacing-spacing-1);
    padding: var(--Spacing-spacing-3);
    @include body-medium-3;

    :deep(.q-btn__content) {
      justify-content: flex-start; // left the div
      text-align: left; // when the text is multiline it's centered, so this required as well
    }
  }

  .selected {
    color: var(--Neutral-UI-neutral-ui-100);
    background-color: var(--Secondary-secondary-color-1);

  }
}

.grid {
  .answer {
    background-color: var(--Neutral-UI-neutral-ui-10);
    color: var(--Neutral-UI-neutral-ui-60);
    min-width: 120px;
    padding: var(--Spacing-spacing-2);
    @include body-medium-3;
    border-radius: var(--button-border-radius);
  }

  .selected {
    color: var(--Neutral-UI-neutral-ui-100);
    background-color: var(--Secondary-secondary-color-2);
  }
}
</style>
