<script setup lang="ts">
import SessionProgressMenu from '@/vue/organisms/session-progress-menu.vue';
import ContentWithTocTemplate from '@/vue/templates/content-with-toc-template.vue';
import {usePageId} from '@/ts/composables/stateful/use-page';
import {useRouterSectionParams} from '@/ts/composables/stateful/use-route-params';

const {sid, pid} = useRouterSectionParams();
const {page} = usePageId(sid, pid);

</script>
<template>
  <content-with-toc-template>
    <template #toc>
      <session-progress-menu/>
    </template>

    <template #content-header>
      <h1 class="text-heading-1">
        {{ page.name }}
      </h1>
    </template>

    <template #content>
      <router-view/>
    </template>
  </content-with-toc-template>
</template>
<style scoped lang="scss">

</style>
