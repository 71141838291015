<script setup lang="ts">

defineProps<{
  total: number;
  current: number;
}>();

</script>
<template>
  <div class="balm-progress-wrapper">
    <div v-for="prog in total" :key="prog" class="balm-progress" :class="{passed: prog <= current + 1, current: prog === current + 1}"/>
    <div class="balm-progress-info">
      {{ current + 1 }} / {{ total }}
    </div>
  </div>
</template>
<style scoped lang="scss">

.balm-progress-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--Spacing-spacing-1);
}

.balm-progress-info {
  margin-left: var(--Spacing-spacing-2);
  @include body-medium-3;
}

.balm-progress {
  width: 12px;
  height: 6px;
  border-radius: var(--button-border-radius);
  background-color: var(--Neutral-UI-neutral-ui-100);
  transition: width 0.2s linear;

  &:not(.passed) {
    opacity: 0.2;
  }

  &.current {
    width: 18px;
  }
}

</style>
