<script lang="ts" setup>
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {
  computed,
  onBeforeMount,
  watch,
} from 'vue';
import type {QuestionMultiChoice} from '@/ts/types/dto/interventions-dto';
import SessionTwoBlocks from '@/vue/molecules/components/helpers/session-two-blocks.vue';
import LazyImage from '@/vue/atoms/lazy-image.vue';
import {useLogger} from '@/ts/composables/pure/use-logger';
import {calculateNumber} from '@/ts/composables/pure/use-question-slider';

const model = defineModel<UuidDTO[]>();
const props = defineProps<{
  question: QuestionMultiChoice;
}>();

const options = computed(() => props.question.multiChoice.options);
const min = computed(() => options.value.reduce((acc, op) => Math.min(acc, op.value), Infinity));
const max = computed(() => options.value.reduce((acc, op) => Math.max(acc, op.value), -Infinity));
const isImage = computed(() => props.question.multiChoice.options.some(op => Boolean(op.image)));

function valueToId(input: number): UuidDTO[] {
  return [options.value.find(op => op.value === input)!.id];
}

const val = computed<number>({
  get(): number {
    return calculateNumber(options.value, model.value, props.question) ?? min.value;
  },
  set(newValue) {
    model.value = valueToId(newValue);
  },
});

const label = computed(() => options.value.find(op => op.value === val.value)?.text);
const image = computed(() => options.value.find(op => op.value === val.value)?.image);

const step = computed(() => {
  if (options.value.length < 2) {
    return 1;
  }
  const res = (max.value - min.value) / (options.value.length - 1);
  if (Math.round(res) !== res) {
    throw Error('Invalid step size');
  }
  return res;
});

const logger = useLogger();

onBeforeMount(() => {
  logger.debug(`onBeforeMount ${props.question.contentId}`)();
  model.value = valueToId(val.value); // emit value to the parents
});

watch(() => props.question.contentId, () => {
  logger.debug(`watch ${props.question.contentId}`)();
  model.value = valueToId(val.value); // emit value to the parents
});
</script>
<template>
  <div v-if="isImage">
    <h5>{{ question.name }}</h5>
    <div v-if="image" class="img">
      <lazy-image :src="image" :height="132" skip-loader class="image"/>
    </div>
    <q-slider
      v-model="val"
      :label-value="label"
      :step="step"
      class="slider-width-image"
      markers
      marker-labels
      label-always
      :min="min"
      :max="max"
    />
  </div>
  <session-two-blocks v-else :description-html="question.name" no-card>
    <div class="slider-holder">
      <q-slider
        v-model="val"
        :label-value="label"
        :step="step"
        class="slider"
        markers
        marker-labels
        label-always
        :min="min"
        :max="max"
      />
    </div>
  </session-two-blocks>
</template>
<style scoped lang="scss">
.image {
  border-radius: var(--card-border-radius);
}

h5 {
  margin: var(--Spacing-spacing-2) 0;
}
.img {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--Spacing-spacing-8) 0;
}

.slider, .slider-width-image {
  :deep(.q-slider__track), :deep(.q-slider__thumb) {
    color: var(--Neutral-UI-neutral-ui-100) !important;
  }
}

.slider-width-image {
  min-width: 200px;
}

.slider-holder {
  padding: var(--Spacing-spacing-6) var(--Spacing-spacing-2);
  @include q-card-blue ;
}
</style>
