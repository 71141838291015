<script setup lang="ts">
import type {ContentFAQ} from '@/ts/types/dto/home-dto';
import {computed} from 'vue';
import type {SideBarGroupedSection} from '@/ts/types/component/balm-sidebar';
import SidebarName from '@/vue/molecules/sidebar/sidebar-name.vue';
import {groupByCategory} from '@/ts/composables/pure/use-content-sidebar';
import SidebarSection from '@/vue/molecules/sidebar/sidebar-section.vue';

const props = defineProps<{
  faq: ContentFAQ[];
}>();

const grouped = computed<SideBarGroupedSection[]>(() => groupByCategory(props.faq, item => ({
  id: item.id,
  html: item.answer,
  label: item.question,
})));

</script>
<template>
  <sidebar-name :name="$t('faq')"/>
  <sidebar-section
    v-for="group in grouped"
    :key="group.id"
    :name="group.name"
    :items="group.items"
  />
</template>
