<script lang="ts" setup>
import type {Questionnaire} from '@/ts/types/dto/interventions-dto';
import ReadOnlyQuestion from '@/vue/molecules/read-only/questionnaire/read-only-question.vue';
import ReadOnlyNonCollapsableTitle from '@/vue/molecules/read-only/helpers/read-only-non-collapsable-title.vue';

defineProps<{
  questionnaire: Questionnaire;
}>();
</script>

<template>
  <read-only-non-collapsable-title
    v-if="questionnaire.name && questionnaire.description"
    :active="false"
    :header="questionnaire.name"
    :content="questionnaire.description"
  />
  <read-only-question
    v-for="question in questionnaire.questions"
    :key="question.userComponentId"
    :question="question"
    class="question-only"
  />
</template>
