<script setup lang="ts">
import BalmLabel from '@/vue/molecules/balm-label.vue';
import type {Score} from '@/ts/types/dto/interventions-dto';

defineProps<{
  score: Score|null;
  loading: boolean;
}>();
</script>
<template>
  <div class="questionnaire-score">
    <q-skeleton v-if="loading" class="loading"/>
    <div v-else-if="score" class="questionnaire-score-content">
      <balm-label theme="blue" :label="$t('results')" class="res-label"/>
      <balm-label theme="blue" :label="$t('interpretation')" class="interpretation-label"/>
      <div class="res-body">
        {{ score.score }} - {{ score.label }}
      </div>
      <div class="interpretation-body" v-html="score.summary"/>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.questionnaire-score {
  height: 100%;
}

.loading {
  height: 100%;
}

.res-label, .interpretation-label {
  width: fit-content;
}

.questionnaire-score-content {
  gap: var(--Spacing-spacing-5);
  display: grid;
  grid-template-areas:
  "res-label interpretation-label"
  "res-body interpretation-body";
  grid-template-columns: 1fr 2fr;
}

.res-body {
  background-color: var(--Primary-primary-color-2);
  border-radius: var(--button-border-radius);
  color: var(--Neutral-UI-neutral-ui-10);
  padding: var(--Spacing-spacing-3) var(--Spacing-spacing-7);
  width: fit-content;
  height: fit-content;
}

</style>
