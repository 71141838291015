<script setup lang="ts">

defineProps<{
  html: string;
  background: 'neutral-10' | 'neutral-0';
}>();
</script>
<template>
  <div class="html-formatted" :class="background" v-html="html"/>
</template>
<style lang="scss" scoped>
.html-formatted {
  @include body-small-2;

  :deep(b),
  :deep(strong) {
    @include body-small-3;
  }

  :deep(p) {
    margin: var(--Spacing-spacing-1) 0;
  }

  :deep(ul) {
    list-style-position: inside;
    padding-left: 0;
  }

  :deep(li) {
    padding-left: var(--Spacing-spacing-1);
  }

  :deep(.grey-card),
  :deep(.white-card) {
    padding: var(--Spacing-spacing-4);
    margin: var(--Spacing-spacing-5) 0;
    border-radius: var(--card-border-radius);
    @include screen-md {
      padding: var(--Spacing-spacing-6);
    }
  }

  :deep(.hover-display-parent):hover .hover-display-content {
    display: block !important;
  }

  &.neutral-0 {
    :deep(.white-card) {
      border: 2px solid var(--Neutral-UI-neutral-ui-50);
      background-color: var(--color-white);
    }

    :deep(.grey-card) {
      background-color: var(--Neutral-UI-neutral-ui-10);
    }
  }

  &.neutral-10 {
    :deep(.white-card) {
      border: 2px solid var(--Neutral-UI-neutral-ui-50);
      background-color: var(--Neutral-UI-neutral-ui-0);
    }

    :deep(.grey-card) {
      background-color: var(--Neutral-UI-neutral-ui-20);
    }
  }
}
</style>
