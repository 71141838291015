import type {
  ComputedRef,
  Ref,
} from 'vue';
import {
  type Component,
  ComponentType,
  type ConceptualizationWorksheet,
  type Intervention,
  type Journal,
  type Page,
  PageState,
  type ProblemSolvingWorksheet,
  type Questionnaire,
} from '@/ts/types/dto/interventions-dto';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {
  AnswerComponentType,
  type AnswerConceptualizationWorksheet,
  type AnswerJournal,
  type AnswerProblemSolvingWorksheet,
  type QuestionAnswerMultiChoiceBody,
  type QuestionAnswerNumericBody,
  type QuestionnaireAnswers,
  QuestionnaireType,
  type SessionRequest,
} from '@/ts/types/dto/session-request-dto';

function patchQuestionnaire(questionnaire: Questionnaire, answer: QuestionnaireAnswers): void {
  questionnaire.isAnswered = true;
  const quest = questionnaire.questions.find(ques => ques.userComponentId === answer.userComponentId)!;
  quest.isAnswered = true;
  if (answer.answers.type === QuestionnaireType.MULTICHOICE) {
    quest.multiChoiceSelectedIds = (answer.answers as QuestionAnswerMultiChoiceBody).multiChoice;
  } else if (answer.answers.type === QuestionnaireType.NUMERIC) {
    quest.numericSelected = (answer.answers as QuestionAnswerNumericBody).numeric;
  }
}

function patchJournal(journal: Journal, answer: AnswerJournal): void {
  journal.isAnswered = true;
  journal.answer = answer.answer.answer;
}

function patchProblemSolving(originalWorksheet: ProblemSolvingWorksheet, answer: AnswerProblemSolvingWorksheet): void {
  originalWorksheet.problem = answer.answer.problem;
  originalWorksheet.goal = answer.answer.goal;
  originalWorksheet.rewards = answer.answer.rewards;
  originalWorksheet.solutions = answer.answer.solutions;
  originalWorksheet.isAnswered = true;
}

function patchConceptualization(originalWorksheet: ConceptualizationWorksheet, answer: AnswerConceptualizationWorksheet): void {
  originalWorksheet.situation.value = answer.answer.situation;
  originalWorksheet.thoughts.value = answer.answer.thoughts;
  originalWorksheet.behaviors.value = answer.answer.behaviors;
  originalWorksheet.physicalReactions.value = answer.answer.physicalReactions;
  originalWorksheet.copingThoughts.value = answer.answer.copingThoughts;
  originalWorksheet.primaryFeelingsIds.value = answer.answer.primaryFeelingsIds;
  originalWorksheet.secondaryFeelingsIds.value = answer.answer.secondaryFeelingsIds;
  originalWorksheet.thinkingTraps.value = answer.answer.thinkingTrapsIds;
  originalWorksheet.isAnswered = true;
  for (const questionnaire of answer.answer.questionnaireAnswers) {
    patchQuestionnaire(originalWorksheet.questionnaire, questionnaire);
  }
}

function patchPage(answers: SessionRequest[], components: Component[]): void {
  for (const answer of answers) {
    if (answer.type === AnswerComponentType.PROBLEM_SOLVING_WORKSHEET) {
      const originalWorksheet = components
        .filter(comp => comp.type === ComponentType.PROBLEM_SOLVING_WORKSHEET)
        .find(comp => comp.problemSolvingWorksheet.userComponentId === answer.answerProblemSolvingWorksheet.userComponentId)!
        .problemSolvingWorksheet;
      patchProblemSolving(originalWorksheet, answer.answerProblemSolvingWorksheet);
    } else if (answer.type === AnswerComponentType.CONCEPTUALIZATION_WORKSHEET) {
      const originalWorksheet = components
        .filter(cmp => cmp.type === ComponentType.CONCEPTUALIZATION_WORKSHEET)
        .find(cmp => cmp.conceptualizationWorksheet.userComponentId === answer.answerConceptualizationWorksheet.userComponentId)!
        .conceptualizationWorksheet;
      patchConceptualization(originalWorksheet, answer.answerConceptualizationWorksheet);
    } else if (answer.type === AnswerComponentType.JOURNAL) {
      const {journal} = (components
        .filter(cmp => cmp.type === ComponentType.JOURNAL)
        .find(cmp => cmp.journal.userComponentId === answer.answerJournal.userComponentId)!);
      patchJournal(journal, answer.answerJournal);
    } else if (answer.type === AnswerComponentType.QUESTIONNAIRE) {
      const {questionnaire} = (components
        .filter(cmp => cmp.type === ComponentType.QUESTIONNAIRE)
        .find(cmp => cmp.questionnaire.id === answer.questionnaireAnswers.questionnaireId)!);
      patchQuestionnaire(questionnaire, answer.questionnaireAnswers);
    }
  }
}

export function patchIntervention(
  {
    intervention,
    sid,
    pid,
    answers,
    allContentRead,
  }: {
    intervention: Ref<Intervention | null>,
    sid: ComputedRef<UuidDTO>,
    pid: ComputedRef<number>,
    answers: SessionRequest[],
    allContentRead: ComputedRef<boolean>,
  },
): void {
  const session = intervention.value!.sessions.find(sess => sess.id === sid.value)!;
  const page: Page = session.pages[pid.value! - 1];
  // store current state since it's reactive and changes upon state modification
  if (allContentRead.value) {
    session.pages.forEach((cPage, cIndex) => {
      if (cIndex < pid.value) { // pid starts from 1, index from 0
        // so if first page is finished it goes to completed with cIndex=0 and pid=1
        cPage.state = PageState.COMPLETED;
      } else if (cIndex === pid.value) {
        // the next component page goes unlock stage
        cPage.state = PageState.UNLOCK;
      }
    });
  }
  if (answers.length > 0) {
    patchPage(answers, page.components);
  }
}
