import {
  type Ref,
  computed,
  type ComputedRef,
} from 'vue';
import {
  type Intervention,
  PageState,
  type Session,
  SessionState,
} from '@/ts/types/dto/interventions-dto';


export interface UseSessionRes {
  session: ComputedRef<Session | null>;
  order: ComputedRef<number>;
  completedPercent: ComputedRef<number>;
  inProgress: ComputedRef<boolean>;
  lastPageIndexCompleted: ComputedRef<number | null>;
}

export function useSession(
  intervention: ComputedRef<Intervention> | Ref<Intervention | null>,
  session: ComputedRef<Session | null>,
): UseSessionRes {
  const completedPercent = computed<number>(() => {
    let res = 0;
    if (session.value && session.value!.pages.length > 1) {
      res = session.value!.pages.filter(pg => pg.state === PageState.COMPLETED).length / session.value!.pages.length;
    }
    return res;
  });
  // same as lastPageIndexCompleted for backend, starting with 0
  const lastPageIndexCompleted = computed<number | null>(() => {
    let res: number = -1;
    if (session.value) {
      res = session.value!.pages.findIndex(pg => pg.state !== PageState.COMPLETED);
      if (res < 0) {
        res = session.value!.pages.length;
      }
    }
    return res;
  });


  const order = computed<number>(() => intervention.value ?
    intervention.value!.sessions.findIndex(ses => ses.id === session.value?.id)! + 1 :
    0);
  const inProgress = computed<boolean>(
    () => session.value ? [SessionState.UNLOCK, SessionState.HOMEWORK_PENDING].includes(session.value!.state) : false,
  );
  return {session, completedPercent, order, inProgress, lastPageIndexCompleted};
}
