<script setup lang="ts">
import CompletedProgress from '@/vue/atoms/completed-progress.vue';
import {useSessionsLinksId} from '@/ts/composables/stateful/use-links';
import {useRouterSectionParams} from '@/ts/composables/stateful/use-route-params';
import PageProgresLeftBlock from '@/vue/organisms/page-progres-left-block.vue';
import {useSessionId} from '@/ts/composables/stateful/use-session';
import {
  onMounted,
  ref,
} from 'vue';
import DescriptionLeftEnter from '@/vue/molecules/description-left-enter.vue';

const {sid, pid} = useRouterSectionParams();
const {summaryLink} = useSessionsLinksId(sid);
const {session, order, completedPercent} = useSessionId(sid);
const wrapper = ref<HTMLElement>(null!);
onMounted(() => {
  wrapper.value.scrollTop = wrapper.value.scrollHeight;
});
</script>
<template>
  <div ref="wrapper">
    <description-left-enter :name="$t('session_index', {order})" :to="summaryLink!" size="m3"/>
    <completed-progress :completed="completedPercent"/>
    <div class="sessions">
      <page-progres-left-block
        v-for="(page, i) in session!.pages"
        :key="page.id"
        :current-page-id="pid"
        :index="i + 1"
        :page="page"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.sessions {
  @include dashed-between-elements(var(--Spacing-spacing-2));
  width: 100%;
  margin-top: var(--Spacing-spacing-3);
}
</style>
