<template>
  <div class="error">
    {{ $t('error') }}
  </div>
</template>
<style lang="scss" scoped>

.error {
  text-align: center;
  margin: var(--Spacing-spacing-2);
  color: var(--q-color-negative);
}
</style>
