<script setup lang="ts">
import SummaryGoals from '@/vue/organisms/summary-goals.vue';
import {useInterventionStore} from '@/ts/store/intervention-store';
import {useSessionsLinksId} from '@/ts/composables/stateful/use-links';
import {useIntervention} from '@/ts/composables/stateful/use-intervention';
import {computed} from 'vue';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {PublicationState} from '@/ts/types/dto/interventions-dto';
import BalmBtn from '@/vue/atoms/balm-btn.vue';

const intervention = useInterventionStore();
const {currentSession} = useIntervention();
const id = computed<UuidDTO | null>(() => currentSession.value?.id ?? null);
const {currentPageLink} = useSessionsLinksId(id);

</script>
<template>
  <div class="page">
    <summary-goals
      :image="intervention!.image"
      :title="$t('path_summary')"
      :finished="intervention!.publicationState === PublicationState.COMPLETED"
      :description="intervention!.description"
      :goals-title="$t('path_goals')"
      :goals="intervention!.goals"
    >
      <balm-btn
        v-if="currentPageLink"
        :label="$t('continue_session')"
        class="continue-button"
        icon="arrow-narrow-right.svg"
        theme="white"
        padding="p3-7"
        :to="currentPageLink"
      />
    </summary-goals>
  </div>
</template>
<style lang="scss" scoped>
.continue-button {
  margin-top: var(--Spacing-spacing-5);
  margin-left: auto;
  display: block;
}

.page {
  margin: 0 auto;
  @include q-page-left
}
</style>
