import {getCurrentInstance} from 'vue';
import type {Logger} from 'lines-logger';
import {loggerInstance} from '@/ts/instances/logger-instance';

export function useLogger(id?: string): Logger {
  const instance = getCurrentInstance();
  // eslint-disable-next-line no-underscore-dangle
  let logName = instance?.type.__name ?? 'vue-comp';
  if (id) {
    logName += `-${id}`;
  }
  return loggerInstance.getLogger(logName);
}
