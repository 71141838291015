<script lang="ts" setup>
import {formatICSDate} from '@/ts/utils/pure-functions';
import BalmBtn from '@/vue/atoms/balm-btn.vue';

const props = defineProps<{
  eventId: string,
  title: string,
  description?: string,
  startTime: string, // UTC ISO with ms YYYY-MM-DDT09:00:00.000Z
  endTime: string, // UTC ISO with ms YYYY-MM-DDT09:00:00.000Z
}>();

function downloadICS(): void {
  const icsData = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'PRODID:-//SIGNIFICO//NONSGML v1.0//EN',
    'BEGIN:VEVENT',
    `UID:${props.eventId}`,
    `DTSTAMP:${formatICSDate(new Date().toISOString())}`,
    `DTSTART:${formatICSDate(props.startTime)}`,
    `DTEND:${formatICSDate(props.endTime)}`,
    `SUMMARY:${props.title}`,
    `DESCRIPTION:${props.description ?? ''}`,
    'END:VEVENT',
    'END:VCALENDAR',
  ].join('\r\n');

  const blob = new Blob([icsData], {type: 'text/calendar'});
  const url = URL.createObjectURL(blob);

  const sanitizedTitle = props.title.replace(/[^a-zA-Z0-9]/gu, '_');
  const filename = `${sanitizedTitle || 'event'}.ics`;

  // Trigger file download
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = filename;
  document.body.appendChild(anchor);
  anchor.click();

  // Cleanup
  window.URL.revokeObjectURL(url);
  document.body.removeChild(anchor);
};
</script>
<template>
  <balm-btn
    id="download-ics"
    theme="grey"
    icon="calendar.svg"
    icon-position="left"
    :label="$t('add_to_calendar')"
    @click="downloadICS"
  />
</template>
<style lang="scss" scoped>
</style>
