<script setup lang="ts" generic="T">
import type {SideBarSectionItem} from '@/ts/types/component/balm-sidebar';

defineProps<{
  items: SideBarSectionItem[]
  name: string;
}>();
</script>
<template>
  <div class="section">
    <div class="section-category">
      {{ name }}
    </div>
    <q-expansion-item
      v-for="item in items"
      :key="item.id"
      class="section-item"
      :label="item.label"
      header-class="section-question"
    >
      <div class="section-answer" v-html="item.html"/>
    </q-expansion-item>
  </div>
</template>
<style scoped lang="scss">
.section {
  margin: var(--Spacing-spacing-4);
}


.section-category {
  padding-bottom: var(--Spacing-spacing-3);
  @include body-medium-3;
}

.section-item {
  margin-bottom: var(--Spacing-spacing-2);
  border-radius: var(--card-border-radius);
  background: var(--Neutral-UI-neutral-ui-20);

  :deep(.q-item) {
    padding: var(--Spacing-spacing-4) var(--Spacing-spacing-5);
    min-height: 0;
  }

  :deep(.section-question) {
    @include body-small-3;
  }
}

.section-answer {
  padding: 0 var(--Spacing-spacing-5) var(--Spacing-spacing-4);
  @include body-small-2;
}
</style>
