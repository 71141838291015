import type {ContentCategory} from '@/ts/types/dto/home-dto';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import type {SideBarGroupedSection,SideBarSectionItem} from '@/ts/types/component/balm-sidebar';

export interface Item {
  id: UuidDTO;
  category: ContentCategory;
}

export interface OutComeItem<T> {
  id: UuidDTO;
  name: string;
  items: T[];
}

export function groupByCategory<T extends Item>(items: T[], itemMapper: (item: T) => SideBarSectionItem): SideBarGroupedSection[] {
  const mapped: Record<UuidDTO, T[]> = {};
  for (const gloss of items) {
    if (!mapped[gloss.category.id]) {
      mapped[gloss.category.id] = [];
    }
    mapped[gloss.category.id].push(gloss);
  }
  return Object.entries(mapped).map(([key, value]) => {
    return {
      id: key as UuidDTO,
      name: value[0].category.name,
      items: value.map(itemMapper),
    };
  });
}
