<script lang="ts" setup>
import {useRequiredSelectRule} from '@/ts/composables/pure/use-input-rules';
import {
  computed,
  onBeforeMount,
  ref,
} from 'vue';
import type {QuasarOption} from '@/ts/types/component/select-multichoice';
import {useI18n} from 'vue-i18n';
import type {CountryCode} from '@/ts/types/dto/sign-in-dto';
import countryCodes from '@/locales/country-codes.json';


const model = defineModel<CountryCode|null>();
const {t: $t} = useI18n();

// translations are handled by i18n-iso-countries and i18n, keys here are used as i18n keys
// check build/i18-bundle-plugin.ts
const defaultCountryList = computed<QuasarOption<CountryCode>[]>(() => 
  countryCodes
    .map(([code]) => ({
      label: $t(code),
      value: code as CountryCode,
    })));

const options = ref<QuasarOption<CountryCode>[]>([]);
onBeforeMount(() => {
  options.value = defaultCountryList.value;
});

const requiredRule = useRequiredSelectRule();

const value = computed<QuasarOption<CountryCode>>({
  get() {
    return defaultCountryList.value.find(dcl => dcl.value === model.value)!;
  },
  set(newValue) {
    model.value = newValue.value;
  },
});

function filterFn(val: string, update: (arg: () =>  void) => void): void {
  if (val === '') {
    update(() => {
      options.value = defaultCountryList.value;
      // here you have access to "ref" which
      // is the Vue reference of the QSelect
    });
  } else {
    update(() => {
      options.value = defaultCountryList.value.filter(item => item.label.toLowerCase().includes(val.toLowerCase()));
    });
  }
}
</script>
<template>
  <q-select
    v-model="value"
    use-input
    :options="options"
    :label="$t('country')"
    name="country"
    :rules="[requiredRule]"
    @filter="filterFn"
  />
</template>
