<script setup lang="ts">
/* eslint-disable max-lines */
import {pagesPath} from '@/ts/router/pages-path';
import BalmBtn from '@/vue/atoms/balm-btn.vue';
import {computed, onMounted, onUnmounted} from 'vue';
import png404Ghost from '@/assets/img/404-ghost.png';
import png500Crash from '@/assets/img/500-crash.png';
import {useOopsieStore} from '@/ts/store/oopsie.store';
import {storeToRefs} from 'pinia';
import {useLogger} from '@/ts/composables/pure/use-logger';

const props = defineProps<{
  text: string;
  errorType: 'not-found-404'|'crash';
  ignoreHeader?: boolean;
}>();

defineEmits<{
  exit: [];
}>();

const {debug} = useLogger();

const oopsieStore = useOopsieStore();
const {oopsPageTemplateVisible} = storeToRefs(oopsieStore);

const backgroundImg = computed(() => {
  return `--error-img: url(${props.errorType === 'not-found-404' ? png404Ghost : png500Crash});`;
});

const distanceTop = computed(() => {
  return `--distance-top: ${props.ignoreHeader ? '0' : 'var(--header-height)'};`;
});

onMounted(() => {
  debug('onMounted')();
  oopsPageTemplateVisible.value = true;
});

onUnmounted(() => {
  debug('onUnmounted')();
  oopsPageTemplateVisible.value = false;
});

</script>
<template>
  <div class="error-page" :style="distanceTop">
    <div class="headline-text-wrapper">
      <div class="oops">
        {{ $t('oops') }}
      </div>

      <div class="text text-heading-1">
        {{ text }}
      </div>
    </div>

    <div
      class="background-img"
      :class="{'background-img--crash': errorType === 'crash'}"
      :style="backgroundImg"
      aria-hidden="true"
    />

    <div>
      <balm-btn
        class="continue-btn"
        theme="blue"
        paddings="p3-7"
        :label="$t('take_me_back')"
        :to="pagesPath.main.home"
        icon="arrow-narrow-right.svg"
        @click="$emit('exit')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.error-page {
  background-color: var(--oopsie-page-background);
  width: 100%;
  min-height: calc(100vh - var(--distance-top));
  display: flex;
  flex-direction: column;
  position: fixed;
  top: var(--distance-top);
  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--Spacing-spacing-4);
  overflow-y: auto;
  overflow-x: hidden;

  @include screen-lg {
    padding: var(--Spacing-spacing-7);
  }
}

.background-img {
  position: relative;
  width: 100%;
  padding-bottom: 60%;
  left: var(--Spacing-spacing-4);
  background-image: var(--error-img);
  background-position-y: bottom;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: contain;

  @include screen-md {
    position: absolute;
    height: 65vh;
    padding: 0;
    left: 0;
    bottom: 0;

    @media (orientation: portrait) {
      background-position: top;
    }
  }

  @include screen-lg {
    height: 65vh;
  }

  @include screen-2xl {
    height: 85vh;
  }

  &--crash {
    left: auto;
    right: 0px;
    background-position-x: right;
    right: -65px;

    @include screen-md {
      right: -125px;
    }

    @include screen-lg {
      left: 0;
      right: auto;
      height: 80vh;
      background-position-x: left;
      right: 0;
    }
  }
}

.headline-text-wrapper {
  position: relative;
  z-index: 1;
  @include screen-lg {
    display: flex;
    align-items: baseline;
    gap: var(--Spacing-spacing-9);
  }
}

.oops {
  position: relative;
  color: var(--Primary-primary-color-3);
  font-family: var(--Font-Primary-Font);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 320px */

  @include screen-lg {
    font-size: 200px;
  }
}

.text {
  position: relative;
  z-index: 1;
  color: var(--Neutral-UI-neutral-ui-100);
}

.continue-btn {
  margin-top: var(--Spacing-spacing-4);
}

</style>