<script setup lang="ts">
defineProps<{
  email?: string
}>();
</script>
<template>
  <div class="verification">
    <h1>{{ $t('verification_sent') }}</h1>
    <p>{{ $t('an_email_has_prefix') }} <b>{{ email }}</b> {{ $t('an_email_has_suffix') }}</p>
  </div>
</template>
<style scoped lang="scss">
h1 {
  @include heading-1
}

.verification {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
