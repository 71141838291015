<script setup lang="ts">
import SessionShort from '@/vue/organisms/session-short.vue';
import {useInterventionStore} from '@/ts/store/intervention-store';
import {
  ref,
} from 'vue';

const intervention = useInterventionStore();
const scrollable = ref<HTMLElement>(null!);

function onScroll(ev: WheelEvent): void {
  scrollable.value!.scrollLeft += ev.deltaY;
}

</script>
<template>
  <div ref="scrollable" class="intervention-sessions-page" @wheel.prevent="onScroll">
    <div class="scrollable">
      <session-short
        v-for="session in intervention!.sessions"
        :key="session.id"
        :session-id="session.id"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.intervention-sessions-page {
  width: 100%;
  overflow-x: auto;
  height: 100%;
}

.scrollable {
  display: flex;
  height: 100%;
  gap: var(--Spacing-spacing-4);
  padding-bottom: var(--Spacing-spacing-3);
  flex-direction: row;
}
</style>
