<script setup lang="ts">
// TODO refactor that shit
/*
 eslint-disable  max-lines
 */
import SectionComponent from '@/vue/molecules/components/section-component.vue';
import {useRouter} from 'vue-router';
import {useLoadingNotification} from '@/ts/composables/pure/use-loading-notification';
import {useApi} from '@/ts/composables/stateful/use-api';
import {useNextLink} from '@/ts/composables/stateful/use-links';
import {useInterventionStore} from '@/ts/store/intervention-store';
import {useRouterSectionParams} from '@/ts/composables/stateful/use-route-params';
import {
  computed,
  onBeforeMount,
  ref,
  watch,
} from 'vue';
import type {SectionComponentGoals} from '@/ts/types/component/section-component';
import {
  type Component,
  ComponentType,
  ContentDisplayType,
  type Intervention,
  PageState,
  SessionState,
} from '@/ts/types/dto/interventions-dto';
import {usePageId} from '@/ts/composables/stateful/use-page';
import {useSessionId} from '@/ts/composables/stateful/use-session';
import {useLogger} from '@/ts/composables/pure/use-logger';
import {QForm} from 'quasar';
import {
  AnswerComponentType,
  type SessionRequest,
} from '@/ts/types/dto/session-request-dto';
import BalmBtn from '@/vue/atoms/balm-btn.vue';
import {patchIntervention} from '@/ts/composables/pure/use-patch-intervention';
import type {ComponentPublicInstance} from '@vue/runtime-core';
import {useScrollTo} from '@/ts/composables/pure/use-scroll-to';

const api = useApi();
const logger = useLogger();
const intervention = useInterventionStore();
const {pid, sid, iid} = useRouterSectionParams();
const router = useRouter();
const hideContinue = ref(false);
const nextPageLink = useNextLink(sid, pid);
const model = ref<SessionRequest[][]>([]);
const currentIndex = ref(1);
const form = ref<ComponentPublicInstance>(null!);

const scrollNext = useScrollTo(form, 32);

function onValidationError(elem: any): void {
  ((elem as ComponentPublicInstance).$el as HTMLElement).scrollIntoView({behavior: 'smooth'});
}
const {session} = useSessionId(sid);
const {page} = usePageId(sid, pid);

const components = computed<SectionComponentGoals[]>(() => {
  if (pid.value === session.value!.pages.length) {
    return [
      ...page.value.components,
      {
        type: 'goals',
        goals: session.value!.goals,
      },
    ];
  }
  return page.value.components;
});

const allContentRead = computed(() => {
  return currentIndex.value >= components.value.length;
});

function assignCurrentIndex(): void {
  if (session.value?.state === SessionState.COMPLETED) {
    currentIndex.value = components.value.length - 1;
  } else {
    let number = page.value.components.findLastIndex(comp =>
      comp.questionnaire?.isAnswered ||
      comp.conceptualizationWorksheet?.isAnswered ||
      comp.problemSolvingWorksheet?.isAnswered ||
      comp.journal?.isAnswered);
    if (number < page.value.components.length - 1) {
      number++;
    }
    if (number === 0
      && components!.value.length > 0
      && components!.value[0].type === ComponentType.CONTENT
      && (components!.value[0] as Component).content?.displayType === ContentDisplayType.NO_TEXT) {
      number = 1;
    }
    currentIndex.value = number;
  }
}

const [saveSession, savingInProgress] = useLoadingNotification(
  async(goNext): Promise<Intervention> => {
    const currentModel: SessionRequest[] | null = model.value[currentIndex.value] ?? null;
    if (goNext) {
      logger.log(`Increasing currentIndex ${currentIndex.value}`)();
      currentIndex.value++;
      if (currentIndex.value - 1 < components.value.length) {
        scrollNext();
      }
    }
    if (
      (session.value?.state === SessionState.UNLOCK) && // if we session is not in progress we shouldn't even be here
      page.value.state === PageState.UNLOCK && // if we are browsing previus page or future page, skip this block
      (!goNext
        || ((currentModel?.length ?? 0) > 0) // if there are data to update
        || allContentRead.value /// if we need to mark current page as read
      )) {
      const answers: SessionRequest[] = model.value
        .flatMap((val: SessionRequest[]) => val)
        .filter((el: SessionRequest | null) => el);
      await api.me.putSession(
        iid.value,
        sid.value,
        page.value.id,
        // resubmit previous answers as well
        {
          answers,
          isContentRead: allContentRead.value,
        },
      );
      // for questionnaire we need score to be calculated
      if (pid.value === session.value!.pages.length || answers?.find(ans => ans.type === AnswerComponentType.QUESTIONNAIRE)) {
        // TOOD refactor this when backend provides endpoints
        intervention.value = await api.me.getIntervention(iid.value);
      } else {
        patchIntervention({intervention, sid, pid, answers, allContentRead});
      }
    }
    return intervention.value!;
  },
  (int, goNext) => {
    if (allContentRead.value && goNext) {
      void router.push(nextPageLink.value);
    }
  },
);


watch(pid, () => {
  logger.debug('pid')();
  model.value = [];
  assignCurrentIndex();
});

onBeforeMount(() => {
  logger.debug('onBeforeMount')();
  assignCurrentIndex();
});


</script>
<template>
  <q-form
    ref="form"
    class="qform"
    @submit="saveSession(true)"
    @validation-error="onValidationError"
  >
    <div class="form-inner">
      <template v-for="(component, i) in components" :key="(component as any).id || i">
        <section-component
          v-if="i <= currentIndex"
          v-model="model[i]"
          v-model:hide-continue="hideContinue"
          :thinking-traps="intervention!.thinkingTraps"
          :primary-feelings="intervention!.primaryFeelings"
          :loading="savingInProgress"
          :iid="iid"
          :sid="sid"
          :component="component"
          @update="saveSession(false)"
        />
      </template>
      <balm-btn
        v-if="!hideContinue"
        class="continue-btn"
        :label="nextPageLink ? $t('continue') : $t('finish')"
        type="submit"
        theme="blue"
        :loading="savingInProgress"
      />
    </div>
  </q-form>
</template>
<style scoped lang="scss">

.form-inner {
  margin: 0 auto;
  display: flex;
  width: 100%; // e.g. no-content block is too small w/o it
  max-width: $media-form-bigger-width;
  flex-direction: column;
  gap: var(--Spacing-spacing-4);
}

.continue-btn {
  margin-left: auto;
}
</style>
