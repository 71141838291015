<script lang="ts" setup>
import {useUserStore} from '@/ts/store/user-store';
import {storeToRefs} from 'pinia';

defineProps<{
  hideUsernameOnMobile?: boolean;
}>();

const userStore = useUserStore();
const {userData} = storeToRefs(userStore);

</script>

<template>
  <div class="user-avatar">
    <span
      v-if="userData"
      class="username text-md-bold"
      :class="{'username--hide-on-mobile': hideUsernameOnMobile}"
    >{{ userData.name }}</span>
    <div class="img-holder" aria-hidden="true">
      <!-- TODO avatar is hardcoded -->
      <img src="@/assets/img/ava.jpeg" class="ava" alt=""/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-avatar {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.username {

  margin-left: var(--Spacing-spacing-1);
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 160px;
  white-space: nowrap;

  &--hide-on-mobile {
    display: none;
    @include screen-lg {
      display: inline;
    }
  }
}


.img-holder {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ava {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>