<script lang="ts" setup>
import {
  ComponentType,
  type Page,
  type PrimaryFeeling,
  type ThinkingTrap,
} from '@/ts/types/dto/interventions-dto';
import ReadOnlyComponent from '@/vue/molecules/read-only/read-only-component.vue';
import {getId} from '@/ts/composables/pure/use-get-id';
import {computed} from 'vue';

const props = defineProps<{
  page: Page;
  primaryFeelings: PrimaryFeeling[];
  thinkingTraps: ThinkingTrap[];
  index: number;
  onlyMyContent: boolean;
}>();

const components = computed(() => {
  if (props.onlyMyContent) {
    return props.page.components.filter(comp => comp.type !== ComponentType.CONTENT);
  }

  return props.page.components;
});
</script>

<template>
  <q-expansion-item class="panel">
    <template #header>
      <div class="page-read-only">
        <h6>{{ index }}</h6>
        <span class="page-name">{{ page.name }}</span>
      </div>
    </template>
    <div class="read-only-page-body">
      <template v-if="components.length > 0">
        <read-only-component
          v-for="component in components"
          :key="getId(component)"
          :component="component"
          :primary-feelings="primaryFeelings"
          :thinking-traps="thinkingTraps"
        />
      </template>
      <p v-else>
        {{ $t('no_user_input') }}
      </p>
    </div>
  </q-expansion-item>
</template>
<style lang="scss" scoped>
.page-read-only {
  display: flex;
  width: 100%;
  padding: var(--Spacing-spacing-4) var(--Spacing-spacing-5);
  align-items: center;
  gap: var(--Spacing-spacing-5);
  align-self: stretch;
}

h6 {
  color: var(--Neutral-UI-neutral-ui-60);
}

.read-only-page-body {
  $vertical_padd: calc(var(--Spacing-spacing-5) + var(--Spacing-spacing-5));
  padding: var(--Spacing-spacing-1) var(--Spacing-spacing-8) $vertical_padd var(--Spacing-spacing-8);
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-1);
}

.panel {
  padding: 0;
  border-bottom: 1px solid var(--Neutral-UI-neutral-ui-30);

  :deep(> .q-expansion-item__container > .q-item) {
    padding: 0;
  }
}

.page-name {
  @include body-medium-3;
}
</style>
