import {Api} from '@/ts/api';
import {useRouter} from 'vue-router';
import {pagesPath} from '@/ts/router/pages-path';
import {useLang} from '@/ts/composables/stateful/use-lang';
import {
  LOGOUT_ON_401,
  ZITADEL,
} from '@/ts/utils/consts';
import {persistentStorage} from '@/ts/instances/persistant-storage';
import type {ZitadelGetTokenResponse} from '@/ts/types/dto/zitadel-dto';
import {useLogger} from '@/ts/composables/pure/use-logger';
import type {Logger} from 'lines-logger';

let tokenLock: Promise<void> | null = null;

async function refreshToken(logger: Logger, api: Api): Promise<void> {
  if (tokenLock) {
    logger.debug('Token is expired, waiting for other process to refresh it')();
    await tokenLock;
    logger.debug('Refresh token is done, continuing')();
  } else {
    logger.log('Token {} has been expired. Refreshing it and aquire the lock', persistentStorage.auth)();
    let resolve: () => void = null!;
    tokenLock = new Promise(res => {
      resolve = res;
    });
    try {
      const response = await api.zitadel.refreshToken(
        persistentStorage.auth!.refresh_token,
        ZITADEL,
      );
      // TODO, this could lead to an issue
      // eslint-disable-next-line require-atomic-updates
      persistentStorage.auth = response;
      logger.log('Token is refreshed to {}. Releasing lock', response)();
      // eslint-disable-next-line require-atomic-updates
      tokenLock = null;
      resolve();
    } catch (error) {
      logger.error('Token is failed to refresh, due to {}. Releasing lock', error)();
      // eslint-disable-next-line require-atomic-updates
      tokenLock = null;
      resolve();
    }
  }
}

export function useApi(): Api {
  const {lang} = useLang();
  const logger = useLogger();
  const router = useRouter();
  const api = new Api(
    // incorrect types in Zitadel OpenID
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    async(): Promise<ZitadelGetTokenResponse> => {
      if (persistentStorage.authExpired) {
        // prevent updating token multiple times in parallel
        await refreshToken(logger, api);
      }
      return persistentStorage.auth!;
    },
    () => lang.value,
    async(): Promise<void> => {
      if (LOGOUT_ON_401) {
        persistentStorage.auth = null;
        await router.push(pagesPath.public.signUp);
      }
    },
    logger,
  );
  return api;
}
