import {useI18n} from 'vue-i18n';
import {
  computed,
  type ComputedRef,
} from 'vue';
import type {Language} from '@/ts/types/component/localization.type';
import {persistentStorage} from '@/ts/instances/persistant-storage';
import {hotjarInstance} from '@/ts/instances/hotjar-instance';

export function useLang(): {
  lang: ComputedRef<Language>,
  setLanguage(lang: Language): void,
} {
  const i18n = useI18n();
  const lang = computed<Language>(() => i18n.locale.value as Language);

  function setLanguage(newVal: Language): void {
    persistentStorage.language = newVal;
    i18n.locale.value = newVal;
    hotjarInstance.setLanguage(newVal);
  }

  return {
    lang, setLanguage,
  };
}
