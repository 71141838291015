import {
  onBeforeMount,
  type Ref,
  ref,
  shallowRef,
  type ShallowRef,
} from 'vue';
import {loggerInstance} from '@/ts/instances/logger-instance';
import type {GenericOrNullable} from '@/ts/types/utility';
import {useI18n} from 'vue-i18n';
import {AppError} from '@/ts/utils/errors';
import {AppErrorCode} from '@/ts/types/dto/error-codes-dto';

const logger = loggerInstance.getLogger('async');

export function extractError(err: unknown, $t: (a: string) => string): string {
  if (err instanceof AppError) {
    const res = $t(err.statusCode);
    if (res?.includes('{}')) {
      return res.replace('{}', err.message);
    }
    return res;
  }
  return $t(AppErrorCode.UNKNOWN);
}

export type UseLoadingReturn<R> = [
  ShallowRef<R>,
  Ref<boolean>,
  Ref<string | null>,
];


export function useLoadingCb<R>(
  cb: () => Promise<R>,
  onSuccess: (data: R) => void,
  onErr?: () => void,
): [
  () => Promise<void>,
  Ref<boolean>,
  Ref<string | null>,
] {
  const loading: Ref<boolean> = ref<boolean>(false);
  const error: Ref<string | null> = ref<string | null>(null);
  const {t: $t} = useI18n();
  const id = String(cb);

  async function submitHandler(): Promise<void> {
    error.value = null;
    loading.value = true;
    logger.debug('Executing {}', id)();
    try {
      const res: R = await cb();
      onSuccess(res);
      loading.value = false;
      logger.debug(`Successfully executed ${id}`)();
    } catch (err) {
      if (onErr) {
        onErr();
      }
      logger.error(`Failed ${id} because of {}`, err)();
      error.value = extractError(err, $t);
      loading.value = false;
    }
  }

  return [submitHandler, loading, error];
}


export function useLoadingData<R>(
  data: ShallowRef<GenericOrNullable<R>>,
  cb: () => Promise<R>,
  defaultValue: GenericOrNullable<R> = null,
): [
  () => Promise<void>,
  Ref<boolean>,
  Ref<string | null>,
] {
  const [submitHandler, loading, error] = useLoadingCb<R>(
    cb,
    res => {
      data.value = res;
    },
    () => {
      data.value = defaultValue;
    },
  );
  return [submitHandler, loading, error];
}

/**
 * Proper way to handle any asynchronous operation, e.g. http request.
 * Upon error will store it in error returned Ref.
 * Upon success will call cb and store result in data returned Ref
 * Upon load will set {loading} state to true.
 * To start an asynchronous operation you have to call returned submitHandler
 *
 * @param cb will be called when you call submitHandler
 * @param defaultValue overwrite default value for data ref
 * @returns {loading} will be set to true/false when operation is in progress.
 * @returns {error} will contain rejected Promise stringified error
 * @returns {data} will data of resolved promise or null
 * @returns {submitHandler} is a trigger, that must be called to execute cb function argument
 * const {submitHandler, loading, data, error} = useLoading(() => api.postOnboardQuestionnaireAnswers(answers));
 */
export function useLoadingDataRefNull<R>(
  cb: () => Promise<R>,
  defaultValue: GenericOrNullable<R> = null,
): UseLoadingReturn<GenericOrNullable<R>> {
  const data: ShallowRef<GenericOrNullable<R>> = shallowRef<GenericOrNullable<R>>(defaultValue);
  const [submitHandler, loading, error] = useLoadingData(data, cb, defaultValue);

  onBeforeMount(async() => submitHandler());

  return [data, loading, error];
}
