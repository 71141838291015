<script setup lang="ts">
import CurrentSessionMenu from '@/vue/organisms/session-description-menu.vue';
import CurrentLabel from '@/vue/molecules/current-label.vue';
import {useRouterSectionParams} from '@/ts/composables/stateful/use-route-params';
import {SessionState} from '@/ts/types/dto/interventions-dto';
import {useSessionId} from '@/ts/composables/stateful/use-session';
import ContentWithTocTemplate from '@/vue/templates/content-with-toc-template.vue';

// TODO this thing is using older backend.getFullInterventionById
// ANd it's gonna be fixed in another PR
const {sid} = useRouterSectionParams();
const {session, order} = useSessionId(sid);

</script>
<template>
  <content-with-toc-template>
    <template #content-header>
      <h1 class="headline">
        {{ $t('session_index', {order}) }}
      </h1>
      <current-label v-if="session!.state === SessionState.UNLOCK" theme="blue"/>
    </template>
    <template #toc>
      <current-session-menu class="menu"/>
    </template>
    <template #content>
      <div class="main">
        <router-view :key="sid"/>
      </div>
    </template>
  </content-with-toc-template>
</template>
<style scoped lang="scss">
.headline {
  margin-right: var(--Spacing-spacing-4);
  @include heading-9
}

.main {
  margin: 0 auto;
  width: 100%;
  @include q-page-left;
  height: 100%;
}
</style>
