<script setup lang="ts">

import BalmBtn from '@/vue/atoms/balm-btn.vue';

defineProps<{
  name: string;
  active: boolean;
}>();

</script>
<template>
  <balm-btn
    :class="{active}"
    class="chip"
    paddings="p1-4-1-3"
    icon-position="left"
    icon="sign-up/asterisk-02.svg"
    :label="name"
  />
</template>
<style lang="scss" scoped>
.chip {
  @include body-small-3;

  :deep(.q-btn__content) {
    display: flex;
    gap: var(--Spacing-spacing-2);
  }

  &:not(.active) {
    background: var(--Neutral-UI-neutral-ui-10) !important;
    border: none;
  }

  &.active {
    background-color: var(--Secondary-secondary-color-5);
    color: var(--Neutral-UI-neutral-ui-100);
    border: none;
  }
}
</style>
