<script lang="ts" setup>
import {
  type Question,
  QuestionDisplayType,
  QuestionType,
} from '@/ts/types/dto/interventions-dto';
import ReadOnlyMultichoice from '@/vue/molecules/read-only/questionnaire/read-only-multichoice.vue';
import ReadOnlyNumeric from '@/vue/molecules/read-only/questionnaire/read-only-numeric.vue';
import ReadOnlyNonCollapsable from '@/vue/molecules/read-only/helpers/read-only-non-collapsable.vue';
import ReadOnlyQuestionSlider from '@/vue/molecules/read-only/questionnaire/read-only-question-slider.vue';
import ReadOnlyGridMultichoice from '@/vue/molecules/read-only/questionnaire/read-only-grid-multichoice.vue';

defineProps<{
  question: Question;
}>();
</script>

<template>
  <read-only-non-collapsable active class="read-only-question">
    <b v-html="question.name"/>
    <template v-if="question.type === QuestionType.MULTICHOICE">
      <read-only-question-slider
        v-if="question.displayType === QuestionDisplayType.HORIZONTAL_SLIDER"
        :question="question.multiChoice"
        :answers="question.multiChoiceSelectedIds"
      />
      <read-only-grid-multichoice
        v-else-if="question.displayType === QuestionDisplayType.GRID"
        :question="question.multiChoice"
        :answers="question.multiChoiceSelectedIds"
      />
      <read-only-multichoice
        v-else
        :question="question.multiChoice"
        :answers="question.multiChoiceSelectedIds"
      />
    </template>
    <read-only-numeric
      v-else-if="question.type === QuestionType.NUMERIC"
      :answer="question.numericSelected"
    />
  </read-only-non-collapsable>
</template>
<style lang="scss" scoped>
.read-only-question {
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-3);
}
</style>
