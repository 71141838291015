<script setup lang="ts">
import type {ContentGlossary} from '@/ts/types/dto/home-dto';
import {computed} from 'vue';
import type {SideBarGroupedSection} from '@/ts/types/component/balm-sidebar';
import SidebarName from '@/vue/molecules/sidebar/sidebar-name.vue';
import {groupByCategory} from '@/ts/composables/pure/use-content-sidebar';

const props = defineProps<{
  glossaries: ContentGlossary[];
}>();

const grouped = computed<SideBarGroupedSection[]>(() => groupByCategory(props.glossaries, item => ({
  id: item.id,
  html: item.definition,
  label: item.term,
})));

</script>
<template>
  <sidebar-name :name="$t('glossary')"/>
  <table class="glossary">
    <template v-for="group in grouped" :key="group.id">
      <tr class="glossary-category">
        <td colspan="2">
          {{ group.name }}
        </td>
      </tr>
      <tr v-for="term in group.items" :key="term.id" class="glossary-item">
        <td class="glossary-term">
          {{ term.label }}
        </td>
        <td class="glossary-definition" v-html="term.html"/>
      </tr>
    </template>
  </table>
</template>
<style scoped lang="scss">
.glossary {
  margin: var(--Spacing-spacing-4);
}

.glossary-category td {
  padding-bottom: var(--Spacing-spacing-3);
  @include body-medium-3;
}

.glossary-item td {
  padding: var(--Spacing-spacing-4) 0;
  border-bottom: 1px solid var(--Neutral-UI-neutral-ui-30, #CCCAC1);
}

.glossary-term {
  vertical-align: top;
  min-width: 200px;
  @include body-medium-3;
}

.glossary-definition {
  vertical-align: top;
  @include body-small-2;
}
</style>
