<script lang="ts" setup>

import SvgIllustration from '@/vue/atoms/svg-illustration.vue';

defineProps<{
  icon: string;
  active: boolean;
  passed: boolean;
  title: string;
}>();
</script>
<template>
  <div
    class="stepper-header-step"
    :class="{active, passed}"
  >
    <svg-illustration :name="icon"/>
    <span class="title">{{ title }}</span>
  </div>
</template>
<style scoped lang="scss">
.stepper-header-step {
  display: flex;
  align-items: center;
  border-radius: var(--button-border-radius);
  gap: var(--Spacing-spacing-2);
  box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.05);
  padding: var(--Spacing-spacing-1) var(--Spacing-spacing-4);

  &.passed {
    background: var(--Secondary-secondary-color-2);
  }

  &.active {
    background: var(--Secondary-secondary-color-1);
  }

  &:not(.passed) {
    background: var(--Neutral-UI-neutral-ui-10);
  }

  &.passed {
    color: var(--Neutral-UI-neutral-ui-100);
  }

  &:not(.passed) {
    color: var(--Neutral-UI-neutral-ui-70);
  }

  &:not(.active) > .title {
    display: none;
  }

  .title {
    display: none;
    @include screen-md {
      display: inline;
    }
  }
}

</style>
