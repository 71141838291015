<script lang="ts" setup>

import type {MultiChoice} from '@/ts/types/dto/interventions-dto';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {computed} from 'vue';

const props = defineProps<{
  question: MultiChoice;
  answers: UuidDTO[];
}>();

const answer = computed(() => props.answers.flatMap((uuid) => props.question.options.find(opt => opt.id === uuid)));
</script>

<template>
  <div>
    {{ `${answer[0]?.value}: ${answer[0]?.text}` }}
  </div>
</template>
<style lang="scss" scoped>

</style>
