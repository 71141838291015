<script setup lang="ts">
import {ref} from 'vue';
import {useAlert} from '@/ts/composables/pure/use-alert';
import {useI18n} from 'vue-i18n';

const MIN_SUPPORTED_WIDTH = 1300;
const continueNavigation = ref<boolean>(false);
const {showAlertDialog} = useAlert();
const {t: $t} = useI18n();
const isMobileScreen = window.innerWidth < MIN_SUPPORTED_WIDTH;
if (isMobileScreen && !continueNavigation.value) {
  void showAlertDialog($t('screen_size_warn'), {
    okLabel: $t('continue'),
    headline: $t('screen_size_warn_headline'),
  });
}

</script>
<template>
  <div/>
</template>
