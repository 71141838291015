<script lang="ts" setup>
import {useDialogPluginComponent} from 'quasar';
import BalmBtn from '@/vue/atoms/balm-btn.vue';

defineProps<{
  message: string,
  cancelBtnDisplayed: boolean,
  headline?: string,
  cancelLabel?: string,
  okLabel?: string,
}>();

defineEmits([
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits,
]);

const {dialogRef, onDialogOK, onDialogCancel} = useDialogPluginComponent();

</script>

<template>
  <q-dialog
    ref="dialogRef"
    persistent
    transition-show="jump-up"
    transition-hide="jump-down"
  >
    <div class="modal-inner">
      <h2 v-if="headline">
        {{ headline }}
      </h2>

      <div class="text-md-normal message">
        {{ message }}
      </div>
      <div class="buttons">
        <balm-btn
          v-if="cancelBtnDisplayed"
          class="button"
          :label="cancelLabel ?? $t('cancel')"
          theme="grey"
          paddings="p2-5"
          @click="onDialogCancel"
        />
        <balm-btn
          class="button"
          paddings="p2-5"
          theme="blue"
          :label="okLabel ?? $t('ok')"
          @click="onDialogOK"
        />
      </div>
    </div>
  </q-dialog>
</template>

<style lang="scss" scoped>
.modal-inner {
  display: flex;
  flex-direction: column;
  background-color: var(--Neutral-UI-neutral-ui-10);
  padding: var(--Spacing-spacing-4);
  border-radius: var(--card-border-radius);
  min-width: 300px;
}

.message {
  margin-bottom: var(--Spacing-spacing-4);
}

.buttons {
  display: flex;
  gap: var(--Spacing-spacing-3);
  flex-direction: column-reverse;

  @include screen-sm {
    flex-direction: row;
    justify-content: center;
  }
}

.button {
  width: 100%;
  @include screen-sm {
    width: auto;
  }
}
</style>

