<script setup lang="ts">
import {ref, onUnmounted} from 'vue';
import {calculateMediaUrl} from '@/ts/utils/pure-functions';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';

defineProps<{
  audio?: UuidDTO | null;
}>();

function playAudio(event: Event): void {
  const audioElement = event.target as HTMLAudioElement;
  if (currentAudio.value && currentAudio.value !== audioElement) {
    currentAudio.value.pause();
    currentAudio.value.currentTime = 0;
  }
  currentAudio.value = audioElement;
}

onUnmounted(() => {
  currentAudio.value?.pause();
});
</script>
<script lang="ts">
const currentAudio = ref<HTMLAudioElement | null>(null);
</script>
<template>
  <audio
    v-if="audio"
    :src="calculateMediaUrl(audio)"
    controls
    class="audio"
    @play="playAudio"
  />
</template>
