<script setup lang="ts">
import SessionTwoBlocks from '@/vue/molecules/components/helpers/session-two-blocks.vue';
import ExampleBlock from '@/vue/molecules/components/helpers/example-block.vue';
import {useRequiredRule} from '@/ts/composables/pure/use-input-rules';
import FlatIconBtn from '@/vue/molecules/flat-icon-btn.vue';

defineProps<{
  name: string;
  exampleHtml?: string | null;
  descriptionHtml?: string | null;
}>();

const model = defineModel<string[]>();
const requiredRule = useRequiredRule();

function deleteItem(int: number): void {
  model.value!.splice(int, 1);
}
</script>
<template>
  <session-two-blocks :name="name" :description-html="descriptionHtml" :show-btn="true" @add="model!.push('')">
    <template #left>
      <example-block v-if="exampleHtml" :example-html="exampleHtml"/>
    </template>
    <div class="inputs">
      <div v-for="(item, i) in model" :key="i" class="each-row">
        <flat-icon-btn
          v-if="(i + 1) === model!.length && i >= 1"
          class="trash"
          icon="trash.svg"
          @click="deleteItem(i)"
        />
        <q-input
          v-model="model![i]"
          bg-color="white"
          :rules="[requiredRule]"
          type="textarea"
        />
      </div>
    </div>
  </session-two-blocks>
</template>
<style scoped lang="scss">

.trash {
  position: absolute;
  width: 20px;
  z-index: 2;
  top: var(--Spacing-spacing-2);
  right: var(--Spacing-spacing-2);
  cursor: pointer;
}

.each-row {
  position: relative;
}

.inputs {
  gap: var(--Spacing-spacing-2);
  display: flex;
  flex-direction: column;

  :deep(textarea) {
    padding: var(--Spacing-spacing-6);
  }
}
</style>
