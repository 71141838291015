<script setup lang="ts">
import {
  useTableOfContentsVisibilityQueryParamFlag,
} from '@/ts/composables/stateful/use-table-of-contents-visibility-query-param-flag';
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';

const {
  isSetAndMobile: mobileOverlayHidden,
  isUnsetAndMobile: mobileOverlayVisible,
  removeFlagAsQueryParam,
} = useTableOfContentsVisibilityQueryParamFlag();

function onClickBackToToc(): void {
  removeFlagAsQueryParam();
}

</script>

<template>
  <div class="content-with-toc-template">
    <div class="toc" :class="{'toc--mobile-overlay-hidden': mobileOverlayHidden}">
      <slot name="toc"/>
    </div>

    <div class="content-header" :inert="mobileOverlayVisible">
      <q-btn class="back-to-toc-btn focus-visible" flat dense @click="onClickBackToToc">
        <span class="sr-only">{{ $t('aria_open_toc') }}</span>
        <svg-illustration
          name="chevron-left.svg"
          aria-hidden="true"
        />
      </q-btn>

      <slot name="content-header"/>
    </div>

    <div class="content" :inert="mobileOverlayVisible">
      <slot name="content"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>

$menu-width: 354px;

.content-with-toc-template {

  @include screen-lg {
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: $menu-width 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
    "menu header"
    "menu main";
  }
}

.toc {
  display: flex;
  flex-direction: column;
  padding: var(--Spacing-spacing-4) var(--Spacing-spacing-4) var(--Spacing-spacing-4) var(--Spacing-spacing-4);
  overflow-y: auto;

  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: var(--Neutral-UI-neutral-ui-10);

  &--mobile-overlay-hidden {
    display: none;
  }

  @include screen-md {
    padding: var(--Spacing-spacing-6) var(--Spacing-spacing-6) var(--Spacing-spacing-6) var(--Spacing-spacing-5);
  }

  @include screen-lg {
    top: 0;
    position: relative;
    height: 100%;
    overflow-y: auto;
    grid-area: menu;
    align-items: flex-start;
    align-self: stretch;
    display: block;
  }
}

.content-header {
  grid-area: header;
  padding: var(--Spacing-spacing-4);
  display: flex;
}

.content {
  grid-area: main;
  overflow: auto;
  padding: var(--Spacing-spacing-4) var(--Spacing-spacing-4) var(--Spacing-spacing-4) var(--Spacing-spacing-4);

  @include screen-lg {
    padding: var(--Spacing-spacing-6) var(--Spacing-spacing-7) var(--Spacing-spacing-7) var(--Spacing-spacing-7);
  }
}

.back-to-toc-btn {
  margin-right: var(--Spacing-spacing-1);
  @include screen-lg {
    display: none;
  }
}

</style>