<script lang="ts" setup>
import {useUserStore} from '@/ts/store/user-store';
import {useApi} from '@/ts/composables/stateful/use-api';
import {useLoadingDataRefNull} from '@/ts/composables/pure/use-loading-data-ref';
import ErrorBox from '@/vue/molecules/error-box.vue';
import RecapComponent from '@/vue/molecules/components/recap-component.vue';
import SidebarName from '@/vue/molecules/sidebar/sidebar-name.vue';

const userStore = useUserStore();

const api = useApi();

const [intervention, loading, error] = useLoadingDataRefNull(
  async() => api.me.getIntervention(userStore.homeContent!.currentIntervention.interventionId),
);
</script>
<template>
  <div>
    <sidebar-name :name="$t('session_recaps')"/>
    <div class="recap">
      <q-skeleton v-if="loading" class="recap-skeleton-select"/>
      <q-skeleton v-if="loading" class="recap-skeleton-session"/>
      <error-box v-else-if="error"/>
      <recap-component v-else-if="intervention" :intervention="intervention"/>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.recap {
  padding-top: var(--Spacing-spacing-4);
}

.recap-skeleton-select {
  height: 60px;
  margin-bottom: var(--Spacing-spacing-7);
}

.recap-skeleton-session {
  height: 500px;
  border-radius: var(--card-border-radius);
}
</style>
