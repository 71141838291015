<script lang="ts" setup>

import type BalmStep from '@/vue/atoms/stepper/balm-step.vue';
import BalmStepperIcon from '@/vue/atoms/stepper/balm-stepper-icon.vue';
import {computed} from 'vue';
import BalmBtn from '@/vue/atoms/balm-btn.vue';

const model = defineModel<number>();

const slots = defineSlots<{
  default(): typeof BalmStep;
}>();

const emit = defineEmits<{
  next: [number]
}>();

defineProps<{
  showBackButton: boolean;
  showNextButton: boolean;
}>();


const allSlots = computed(() => slots.default());
</script>
<template>
  <div class="stepper">
    <div class="stepper-head">
      <template v-for="(step, index) in allSlots" :key="step.props.index">
        <balm-stepper-icon
          :title="step.props.title"
          :icon="step.props.icon"
          :active="step.props.index === model!"
          :passed="step.props.index <= model!"
          class="stepper-header-step"
        />
        <div v-if="(index as number)! < allSlots.length - 1" class="step-separator"/>
      </template>
    </div>
    <div class="stepper-body">
      <q-carousel
        v-model="model"
        transition-prev="slide-right"
        transition-next="slide-left"
        animated
        class="q-carousel"
      >
        <q-carousel-slide
          v-for="currentStep in allSlots"
          :key="currentStep.props.index"
          :name="currentStep.props.index"
          class="carousel-slide"
        >
          <component :is="currentStep"/>
        </q-carousel-slide>
      </q-carousel>
      <div class="buttons">
        <balm-btn
          v-if="showBackButton"
          paddings="p3-7"
          theme="grey"
          class="back"
          :title="$t('back')"
          icon="arrow-left.svg"
          @click="model!--"
        />
        <balm-btn
          v-if="showNextButton"
          :label="$t('accept')"
          theme="green"
          @click="emit('next', model!)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.stepper {
  display: flex;
  flex-direction: column;
  background-color: var(--Neutral-UI-neutral-ui-0);
  border-radius: var(--card-border-radius);
}

.q-carousel {
  background-color: inherit;
  height: inherit;
}

.carousel-slide {
  padding: 0;
  background-color: initial;
}

.stepper-header-step {
  position: relative;
}

.stepper-head {
  display: none;
  border-bottom: 1px solid var(--body-background-color);
  padding: var(--Spacing-spacing-6) 0;
  margin: 0 0 var(--Spacing-spacing-6) 0;
  @include screen-sm {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @include screen-lg {
    padding: var(--Spacing-spacing-6);
  }
}

.stepper-body {
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-4);

  @include screen-lg {
    padding: 0 var(--Spacing-spacing-6) var(--Spacing-spacing-6) var(--Spacing-spacing-6);
  }
}

.step-separator {
  display: inline-block;
  flex: 1;
  margin: 0 var(--Spacing-spacing-2);
  height: 2px;
  background-color: var(--Secondary-secondary-color-1);
  width: 100%;
}

.buttons {
  display: flex;
  gap: var(--Spacing-spacing-4)
}
</style>
