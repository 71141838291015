<script setup lang="ts">
import SessionTwoBlocks from '@/vue/molecules/components/helpers/session-two-blocks.vue';
import BalmBtn from '@/vue/atoms/balm-btn.vue';

defineEmits<{
  addActivity: [],
}>();

</script>
<template>
  <session-two-blocks
    :show-btn="true"
    :description-html="$t('do_you_want')"
    :name="$t('add_a_solution')"
  >
    <template #left>
      <balm-btn
        :label="$t('create_activities_for')"
        icon="plus.svg"
        icon-position="left"
        class="add"
        @click="$emit('addActivity')"
      />
    </template>
  </session-two-blocks>
</template>
