<script setup lang="ts">
import {useRequiredTrueRule} from '@/ts/composables/pure/use-input-rules';

const model = defineModel<boolean>();
const requiredTrueRule = useRequiredTrueRule();

</script>
<template>
  <q-field v-model="model" :rules="[requiredTrueRule]" borderless dense>
    <q-checkbox v-model="model" dense>
      <slot name="label">
        <slot/>
      </slot>
    </q-checkbox>
  </q-field>
</template>
