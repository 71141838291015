<script setup lang="ts">
import SummaryGoals from '@/vue/organisms/summary-goals.vue';
import {useRouterSectionParams} from '@/ts/composables/stateful/use-route-params';
import {SessionState} from '@/ts/types/dto/interventions-dto';
import {
  computed,
  ref,
} from 'vue';
import HomeworkModal from '@/vue/organisms/homework-modal.vue';
import SessionHomeNextBlock from '@/vue/molecules/session-summary/session-home-next-block.vue';
import {useInterventionStore} from '@/ts/store/intervention-store';
import ReadOnlyView from '@/vue/molecules/read-only/read-only-view.vue';
import {useLoadingDataRefNull} from '@/ts/composables/pure/use-loading-data-ref';
import LoadingError from '@/vue/templates/loading-error.vue';
import {useApi} from '@/ts/composables/stateful/use-api';

const {iid, sid} = useRouterSectionParams();
const intervention = useInterventionStore();

const api = useApi();
const [session, loading, error] = useLoadingDataRefNull(async() => api.me.getSession(iid.value, sid.value));

const homeworkAvailable = computed(() => session.value?.state === SessionState.HOMEWORK_PENDING);
const homeworkCompleted = computed<boolean>(() => Boolean(session.value?.homework[0]?.completedDate));
const homeWorkOpen = ref(false);
const mainFlowFinished = computed(() => session.value!.state === SessionState.COMPLETED ||
  session.value!.state === SessionState.HOMEWORK_PENDING);

</script>
<template>
  <loading-error id="session-summary-page" :loading="loading" :error="error" type="component">
    <div class="session-summary-page">
      <summary-goals
        :title="$t('summary')"
        :finished="mainFlowFinished"
        :image="session!.image"
        :state="session!.state"
        :description="session!.description"
        :goals-title="$t('session_goals')"
        :goals="session!.goals"
      />
      <read-only-view
        v-if="session!.state === SessionState.COMPLETED || homeworkCompleted"
        :session="session!"
        :primary-feelings="intervention!.primaryFeelings"
        :thinking-traps="intervention!.thinkingTraps"
      />
      <homework-modal v-if="homeworkAvailable" v-model="homeWorkOpen"/>
      <session-home-next-block
        :session-id="sid"
        :intervention="intervention!"
        @open-homework="homeWorkOpen = true"
      />
    </div>
  </loading-error>
</template>
<style scoped lang="scss">
.session-summary-page {
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-4);
  @include screen-lg {
    padding-bottom: var(--Spacing-spacing-7);
  }
}
</style>
