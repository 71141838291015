import type {Intervention} from '@/ts/types/dto/interventions-dto';
import {
  inject,
  provide,
  ref,
  type Ref,
} from 'vue';
import {useLogger} from '@/ts/composables/pure/use-logger';

export function useInterventionStore(): Ref<Intervention | null> {
  const logger = useLogger();
  logger.trace('useInterventionStore')();
  return inject<Ref<Intervention | null>>('intervention')!;
}

export function useCreateIntervention(intervention: Intervention | null, logId: string): Ref<Intervention | null> {
  const logger = useLogger();
  const reactiveIntervention = ref(intervention);
  provide('intervention', reactiveIntervention);
  logger.log(`useCreateIntervention ${logId} ${intervention?.id}`)();
  return reactiveIntervention;
}
