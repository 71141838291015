<script setup lang="ts">
import InputRequiredCheckbox from '@/vue/atoms/input-required-checkbox.vue';
import BalmDialog from '@/vue/atoms/balm-dialog.vue';
import HtmlFormatted from '@/vue/molecules/html-formatted.vue';
import {
  ref,
} from 'vue';

const dialongOpened = ref<boolean>(false);
defineProps<{
  header: string;
  text: string;
  modalHtml: string;
  furtherInfoKey?: string;
}>();
</script>
<template>
  <div>
    <h2 class="agreement-title">
      {{ header }}
    </h2>
    <input-required-checkbox class="agreement-check">
      <span>{{ text }}</span>&nbsp;<wbr/>
      <span
        v-if="furtherInfoKey"
        role="button"
        tabindex="0"
        class="further-info"
        @click.stop="dialongOpened = true"
      >
        {{ furtherInfoKey }}
      </span>
    </input-required-checkbox>
    <balm-dialog v-model="dialongOpened" theme="neutral-0">
      <html-formatted :html="modalHtml" background="neutral-0"/>
    </balm-dialog>
  </div>
</template>
<style lang="scss" scoped>
.agreement-title {
  @include body-medium-3;
  margin-bottom: var(--Spacing-spacing-3);
}

.agreement-check  {
  @include body-medium-2;
  :deep(.q-field__control) {
    color: var(--Neutral-UI-neutral-ui-100)
  }
  :deep(.q-checkbox__label) {
    padding-left: var(--Spacing-spacing-4);
  }
}

.further-info {
  @include focus-visible;
  text-decoration: underline;
  cursor: pointer;
}

</style>
