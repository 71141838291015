import {RECAPTHCA_SITE_KEY} from '@/ts/utils/consts';
import {useLogger} from '@/ts/composables/pure/use-logger';

let recaptchaState: 'loading' | 'init_requires' | 'ready' = 'init_requires';
let loadingPromise = Promise.resolve();

export function useRecaptcha(): () => Promise<string | null> {
  const logger = useLogger();
  if (!RECAPTHCA_SITE_KEY) {
    logger.debug('Skipping recaptcha since siteKey is not defined')();
    return async() => Promise.resolve(null);
  }
  if (recaptchaState === 'init_requires') {
    recaptchaState = 'loading';
    let resolveLoadingPromise: () => void;
    let rejectTimeout: number = 0;
    loadingPromise = new Promise((resolve, reject) => {
      resolveLoadingPromise = resolve;
      rejectTimeout = setTimeout(reject, 10_000) as unknown as number;
    });
    logger.debug('Loading reCAPTCHA script {}', RECAPTHCA_SITE_KEY)();
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTHCA_SITE_KEY}`;
    script.async = true;
    document.head.appendChild(script);
    script.onload = (): void => {
      logger.debug('Recaptcha loaded')();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      grecaptcha.ready(() => {
        clearTimeout(rejectTimeout);
        recaptchaState = 'ready';
        resolveLoadingPromise();
        loadingPromise = Promise.resolve();
        logger.log('Recaptcha has been installed')();
      });
    };
  }
  return async(): Promise<string> => {
    if (recaptchaState === 'loading') {
      logger.debug('Waiting until recapthca finishes loading')();
      await loadingPromise;
    }
    logger.log('Getting recaptcha token')();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
    return grecaptcha.execute(RECAPTHCA_SITE_KEY!, {action: 'submit'}) as Promise<string>;
  };
}
