import type {Solution} from '@/ts/types/dto/interventions-dto';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';

export interface ComponentAnswer<T> {
  answer: T
  userComponentId?: UuidDTO; // is optional when submitting homework, so the key should be absent
}

export enum AnswerComponentType {
  QUESTIONNAIRE = 'questionnaire',
  JOURNAL = 'journal',
  CONCEPTUALIZATION_WORKSHEET = 'conceptualization_worksheet',
  PROBLEM_SOLVING_WORKSHEET = 'problem_solving_worksheet',
}

export interface SessionRequestQuestionnaire {
  type: AnswerComponentType.QUESTIONNAIRE
  questionnaireAnswers: QuestionnaireAnswers
}

export interface SessionRequestJournal {
  type: AnswerComponentType.JOURNAL;
  answerJournal: AnswerJournal;
}

export interface SessionRequestConceptualizationWorksheet {
  type: AnswerComponentType.CONCEPTUALIZATION_WORKSHEET
  answerConceptualizationWorksheet: AnswerConceptualizationWorksheet
}

export interface SessionRequestProblemSolvingWorksheet {
  type: AnswerComponentType.PROBLEM_SOLVING_WORKSHEET
  answerProblemSolvingWorksheet: AnswerProblemSolvingWorksheet
}

export enum QuestionnaireType {
  MULTICHOICE = 'multichoice',
  NUMERIC = 'numeric',
}

export interface QuestionAnswerNumericBody {
  numeric: number;
  type: QuestionnaireType.NUMERIC;
}

export interface QuestionAnswerMultiChoiceBody {
  multiChoice: UuidDTO[];
  type: QuestionnaireType.MULTICHOICE;
}

export type QuestionAnswerBody =
  QuestionAnswerMultiChoiceBody
  | QuestionAnswerNumericBody;


export interface AnswerProblemSolvingWorksheetBody {
  problem: string
  goal: string
  rewards: string[];
  solutions: Solution[]
}

export interface AnswerConceptualizationWorksheetBody {
  situation: string
  questionnaireAnswers: QuestionnaireAnswers[]
  primaryFeelingsIds: UuidDTO[]
  secondaryFeelingsIds: UuidDTO[]
  thoughts: string[]
  behaviors: string[]
  physicalReactions: string[]
  thinkingTrapsIds: UuidDTO[]
  copingThoughts: string[]
}

export interface AnswerJournalBody {
  answer: string;
}

export interface QuestionnaireAnswers<T extends QuestionAnswerBody = QuestionAnswerBody> {
  answers: T;
  userComponentId?: UuidDTO;
  questionId?: UuidDTO;
  questionnaireId?: UuidDTO;
}

export type AnswerJournal = ComponentAnswer<AnswerJournalBody>

export type AnswerProblemSolvingWorksheet = ComponentAnswer<AnswerProblemSolvingWorksheetBody>;

export type AnswerConceptualizationWorksheet = ComponentAnswer<AnswerConceptualizationWorksheetBody>;

export type SessionRequest =
  SessionRequestConceptualizationWorksheet
  | SessionRequestJournal
  | SessionRequestProblemSolvingWorksheet
  | SessionRequestQuestionnaire;

export interface PutSessionRequest {
  isContentRead: boolean;
  answers: SessionRequest[];
}

