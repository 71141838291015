import {useQuasar} from 'quasar';
import AlertDialog from '@/vue/molecules/alert-dialog.vue';

interface DialogOptions {
  headline?: string;
  cancelLabel?: string;
  okLabel?: string;
}

interface UseWarningInterface {
  showConfirmDialog(message: string, options?: DialogOptions): Promise<void>
  showAlertDialog(message: string, options?: DialogOptions): Promise<void>
}

export function useAlert(): UseWarningInterface {
  const $q = useQuasar();

  async function showConfirmDialog(message: string, options?: DialogOptions): Promise<void> {
    return new Promise((resolve, reject): void => {
      $q.dialog({
        component: AlertDialog,
        componentProps: {
          message,
          cancelBtnDisplayed: true,
          headline: options?.headline,
          cancelLabel: options?.cancelLabel,
          okLabel: options?.okLabel,
        },
      }).onOk(resolve).onCancel(reject);
    });
  }

  async function showAlertDialog(message: string, options?: DialogOptions): Promise<void> {
    return new Promise((resolve): void => {
      $q.dialog({
        component: AlertDialog,
        componentProps: {
          message,
          cancelBtnDisplayed: false,
          headline: options?.headline,
          cancelLabel: options?.cancelLabel,
          okLabel: options?.okLabel,
        },
      }).onOk(resolve);
    });
  }

  return {
    showConfirmDialog,
    showAlertDialog,
  };
}