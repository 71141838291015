<script setup lang="ts">
import {useApi} from '@/ts/composables/stateful/use-api';
import {
  QForm,
  useQuasar,
} from 'quasar';
import BalmBtn from '@/vue/atoms/balm-btn.vue';
import {ref} from 'vue';
import {useLoadingSpinner} from '@/ts/composables/pure/use-loading-notification';
import {useI18n} from 'vue-i18n';
import {
  usePasswordRules,
  useRequiredRule,
} from '@/ts/composables/pure/use-input-rules';
import {useRouter} from 'vue-router';
import {pagesPath} from '@/ts/router/pages-path';

const api = useApi();
const {t: $t} = useI18n();
const paswordRules = usePasswordRules();
const requiredRule = useRequiredRule();
const $q = useQuasar();
const router = useRouter();

const currentPassword = ref<string>('');
const newPassword = ref<string>('');
const repeatPassword = ref<string>('');

const changePassword = useLoadingSpinner(
  async() => api.me.changePassword({
    currentPassword: currentPassword.value,
    newPassword: newPassword.value,
  }),
  () => {
    $q.notify($t('password_changed'));
    void router.push(pagesPath.main.home);
  },
);

function repeatPasswordRule(val: string): boolean | string {
  return val === newPassword.value || $t('pasword_dont_match');
}

function cantUseCurrentPassword(val: string): boolean | string {
  return val !== currentPassword.value || $t('cant_use_current_password');
}
</script>
<template>
  <q-form class="change-password-page" @submit.prevent="changePassword">
    <!--https://www.w3schools.com/tags/att_input_autocomplete.asp-->
    <q-input
      v-model="currentPassword"
      type="password"
      :rules="[requiredRule]"
      :label="$t('current_password')"
      autocomplete="current-password"
    />
    <q-input
      v-model="newPassword"
      :rules="[...paswordRules, cantUseCurrentPassword]"
      :label="$t('new_password')"
      autocomplete="new-password"
      type="password"
    />
    <q-input
      v-model="repeatPassword"
      :rules="[repeatPasswordRule]"
      :label="$t('repeat_password')"
      type="password"
      autocomplete="new-password"
    />
    <balm-btn
      :label="$t('save_changes')"
      type="submit"
      theme="green"
      class="change-passw-submt"
      paddings="p2-5"
    />
  </q-form>
</template>
<style lang="scss" scoped>
.change-password-page {
  display: flex;
  width: calc(100% - var(--Spacing-spacing-9) * 2);
  overflow: auto;
  padding: var(--Spacing-spacing-8) var(--Spacing-spacing-7);
  border-radius: var(--card-border-radius);
  background-color: var(--Neutral-UI-neutral-ui-0);
  flex-direction: column;
  margin: var(--Spacing-spacing-7) auto;
}

.change-passw-submt {
  margin-top: var(--Spacing-spacing-6);
}
</style>
