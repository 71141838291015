import type {LogLevel} from 'lines-logger';
import type {Language} from '@/ts/types/component/localization.type';
import type {ZitadelGetTokenResponse,TokenData} from '@/ts/types/dto/zitadel-dto';

export class PersistentStorage {
  public get logLevel(): LogLevel | null {
    return localStorage.getItem('log-level') as LogLevel;
  }

  public clear(): void {
    localStorage.removeItem('lang');
  }

  public get language(): Language | null {
    return localStorage.getItem('lang') as Language;
  }

  public set language(value: Language | null) {
    if (value) {
      localStorage.setItem('lang', value);
    } else {
      localStorage.removeItem('lang');
    }
  }

  public get auth(): ZitadelGetTokenResponse | null {
    const auth = localStorage.getItem('auth');
    if (auth) {
      return JSON.parse(auth) as ZitadelGetTokenResponse;
    }
    return null;
  }

  get authExpired(): boolean {
    const auth = localStorage.getItem('auth');
    if (!auth) {
      return true;
    }
    const data = JSON.parse(auth) as TokenData;
    const extraTime = 60; // 60 seconds
    return Date.now() - data.setOn > (data.expires_in - extraTime) * 1000;
  }

  public set auth(data: ZitadelGetTokenResponse | null) {
    if (data) {
      const newData: TokenData = {...data, setOn: Date.now()};
      localStorage.setItem('auth', JSON.stringify(newData));
      localStorage.removeItem('codeVerifier');
    } else {
      localStorage.removeItem('auth');
      localStorage.removeItem('codeVerifier');
    }
  }

  public get codeVerifier(): string | null {
    return localStorage.getItem('codeVerifier') ?? null;
  }

  public set codeVerifier(data: string | null) {
    if (data) {
      localStorage.setItem('codeVerifier', data);
    } else {
      localStorage.removeItem('codeVerifier');
    }
  }
}
