import {defineStore} from 'pinia';

export interface OopsieStoreStoreState {
  oopsPageTemplateVisible: boolean,
}

export const useOopsieStore = defineStore<'oopsie-store', OopsieStoreStoreState, any, any>('oopsie-store', {
  state: () => ({
    oopsPageTemplateVisible: false,
  }),
});