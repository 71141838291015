import type {GenderDTO} from '@/ts/types/dto/gender-dto';
import type countryNames from 'i18n-iso-countries/langs/en.json';
import type {Language} from '@/ts/types/component/localization.type';

export type StringDateDTO = `${number}-${number}-${number}`;
export type UuidDTO = `${string}-${string}-${string}-${string}-${string}`;
export type CountryCode = keyof typeof countryNames.countries;

export interface SignUpRequest {
  email: string;
  name: string;
  gender: GenderDTO;
  countryCode: CountryCode;
  educationLevel: EducationLevelDTO;
  termsAndConditionsId: UuidDTO;
  privacyPolicyId: UuidDTO;
  marketingCode: string;
  birthYear: number;
  recaptchaToken: string|null;
  languageCode: Language;
}


export enum EducationLevelDTO {
  SOME_HIGH_SCHOOL = 'some_high_school',
  HIGH_SCHOOL_DIPLOMA = 'high_school_diploma',
  BACHELORS_DEGREE = 'bachelors_degree',
  MASTERS_DEGREE = 'masters_degree',
  PHD_OR_HIGHER = 'phd_or_higher',
  TRADE_SCHOOL = 'trade_school',
  PREFER_NOT_TO_SAY = 'prefer_not_to_say',
}
