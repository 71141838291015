<script lang="ts" setup>

import type {MultiChoice} from '@/ts/types/dto/interventions-dto';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {computed} from 'vue';
import ListItems from '@/vue/molecules/read-only/helpers/list-items.vue';

const props = defineProps<{
  question: MultiChoice;
  answers: UuidDTO[];
}>();

const stringified = computed(() => props.answers.flatMap((uuid) => props.question.options.find(opt => opt.id === uuid)!.text));
</script>

<template>
  <list-items v-if="question.maxOptions > 1" :items="stringified"/>
  <div v-else>
    {{ stringified[0] }}
  </div>
</template>
<style lang="scss" scoped>

</style>
