<script lang="ts" setup>
import {type Questionnaire, QuestionnaireType} from '@/ts/types/dto/interventions-dto';
import ReadOnlyQuestionnaireStandard
  from '@/vue/molecules/read-only/questionnaire/read-only-questionnaire-standard.vue';
import ReadOnlyQuestionnaireOnlyQuestions
  from '@/vue/molecules/read-only/questionnaire/read-only-questionnaire-only-questions.vue';
import ReadOnlyQuestionnaireGrid from '@/vue/molecules/read-only/questionnaire/read-only-questionnaire-grid.vue';
import ErrorBox from '@/vue/molecules/error-box.vue';

defineProps<{
  questionnaire: Questionnaire;
}>();
</script>

<template>
  <read-only-questionnaire-standard
    v-if="questionnaire.type === QuestionnaireType.STANDARD"
    :questionnaire="questionnaire"
  />
  <read-only-questionnaire-only-questions
    v-else-if="questionnaire.type === QuestionnaireType.SHOW_ONLY_QUESTIONS"
    :questionnaire="questionnaire"
  />
  <read-only-questionnaire-grid
    v-else-if="questionnaire.type === QuestionnaireType.GRID_LAYOUT"
    :questionnaire="questionnaire"
  />
  <error-box v-else/>
</template>
