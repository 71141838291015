import {FetchWrapper} from '@/ts/api/fetch-wrapper';
import {
  BACKEND_ADDRESS,
  ZITADEL,
} from '@/ts/utils/consts';
import {PublicApi} from '@/ts/api/modules/public-api';
import {MeApi} from '@/ts/api/modules/me-api';
import {Language} from '@/ts/types/component/localization.type';
import {ZitadelApi} from '@/ts/api/modules/zitadel-api';
import type {ZitadelGetTokenResponse} from '@/ts/types/dto/zitadel-dto';
import type {Logger} from 'lines-logger';

export class Api {
  public readonly public: PublicApi;
  public readonly zitadel: ZitadelApi;
  public readonly me: MeApi;

  constructor(
    private readonly getAccessToken: () => Promise<ZitadelGetTokenResponse | null>,
    private readonly getLanguage: () => Language,
    private readonly onLogout: () => Promise<void>,
    private readonly logger: Logger,
  ) {
    const getLangHeader = (): Record<string, string> => ({
      'Accept-Language': { // eslint-disable-line @typescript-eslint/naming-convention
        [Language.German]: 'de-DE',
        [Language.English]: 'en-US',
      }[this.getLanguage()],
    });

    this.public = new PublicApi(
      new FetchWrapper(
        `${BACKEND_ADDRESS}/api/v1/public`,
        () => getLangHeader(),
        Promise.resolve,
        this.logger,
      ),
    );
    this.zitadel = new ZitadelApi(
      new FetchWrapper(
        ZITADEL.issuer,
        async() => Promise.resolve({}),
        Promise.resolve,
        this.logger,
      ),
    );

    this.me = new MeApi(
      new FetchWrapper(
        `${BACKEND_ADDRESS}/api/v1/me`,
        async(): Promise<Record<string, string>> => {
          const token: ZitadelGetTokenResponse | null = await this.getAccessToken();
          if (!token?.access_token) {
            throw Error('No auth token found');
          }
          return {
            Authorization: `${token.token_type} ${token.access_token}`,
            ...getLangHeader(),
          };
        },
        this.onLogout,
        this.logger,
      ),
    );
  }
}
