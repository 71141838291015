<script setup lang="ts">
import {
  QImg,
  type QImgProps,
} from 'quasar';
import {computed} from 'vue';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {calculateMediaUrl} from '@/ts/utils/pure-functions';

const props = withDefaults(defineProps<{
  src: UuidDTO;
  loading?: QImgProps['loading'];
  width?: number;
  height?: number;
  left?: number;
  top?: number;
  skipLoader?: boolean;
  // https://docs.directus.io/reference/files.html#custom-transformations
  format?: 'auto' | 'jpg' | 'png' | 'tiff' | 'webp';
}>(), {
  loading: 'lazy',
  format: 'webp',
  showLoader: false,
});


const calculatedSrc = computed(() => {
  const src = calculateMediaUrl(props.src);
  if (!props.width && !props.height && !props.format) {
    return src;
  }
  const searchParams = new URLSearchParams();
  const devicePixelRatio = window.devicePixelRatio ?? 1;
  const sizeFactor = devicePixelRatio > 1 ? 1.5 : 1; // eslint-disable-line @typescript-eslint/no-magic-numbers

  if (props.width) {
    searchParams.append('width', `${Math.ceil(props.width * sizeFactor)}`);
  }
  if (props.height) {
    searchParams.append('height', `${Math.ceil(props.height * sizeFactor)}`);
  }
  if (props.left !== undefined) { // eslint-disable-line no-undefined
    searchParams.append('left', `${props.left}`);
  }
  if (props.top !== undefined) { // eslint-disable-line no-undefined
    searchParams.append('top', `${props.top}`);
  }
  if (props.format) {
    searchParams.append('format', props.format);
  }
  return `${src}?${searchParams.toString()}`;
});

</script>
<template>
  <img v-if="skipLoader" :src="calculatedSrc"/>
  <q-img 
    v-else
    :key="calculatedSrc"
    :src="calculatedSrc"
    :loading="loading"
    class="image"
    no-transition
  >
    <template #error>
      <div class="error"/>
    </template>
    <template #loading>
      <q-skeleton class="loading"/>
    </template>
  </q-img>
</template>

<style lang="scss" scoped>
.loading, .error {
  width: 100%;
  height: 100%;
}

.error {
  @include background-lines(var(--color-primary));
}
</style>
