<script lang="ts" setup>
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';

defineProps<{
  name: string;
  noX?: boolean;
}>();

defineEmits<{
  remove: [];
}>();
</script>
<template>
  <div class="feeling-chip" :class="{'no-x': noX}">
    <div class="chip-name">
      {{ name }}
    </div>

    <q-btn v-if="!noX" flat round @click="$emit('remove')">
      <svg-illustration name="x-close.svg"/>
    </q-btn>
  </div>
</template>
<style lang="scss" scoped>
.feeling-chip {
  background-color: var(--Secondary-secondary-color-2);
  border-radius: var(--button-border-radius);
  display: flex;
  align-items: center;
  flex-direction: row;
  width: fit-content;
  &.no-x {
    height: 42px;
    padding: 0 var(--Spacing-spacing-4) 0 var(--Spacing-spacing-4);
  }
  &:not(.no-x) {
    padding: 0 var(--Spacing-spacing-1) 0 var(--Spacing-spacing-4);
  }
}

.chip-name {
  display: block;
  height: 22px;
  padding: 0;
  @include body-small-3;
}
</style>
