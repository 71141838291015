import type {RouteRecordRaw} from 'vue-router';
import {pagesPath} from '@/ts/router/pages-path';
import SessionSummaryPage from '@/vue/pages/sessions/session-summary-page.vue';
import SessionProgressPage from '@/vue/pages/sessions/session-progress-page.vue';
import SessionSectionPage from '@/vue/pages/sessions/session-section-page.vue';
import SessionWrapperPage from '@/vue/pages/sessions/session-wrapper-page.vue';

export const sessionPages: RouteRecordRaw[] = [
  {
    path: pagesPath.session.sessionWrapper,
    component: SessionWrapperPage,
    children: [
      {
        path: pagesPath.session.sessionSummary,
        component: SessionSummaryPage,
      },
    ],
  },
  {
    path: pagesPath.session.sessionLeftMenu,
    component: SessionProgressPage,
    children: [
      {
        path: pagesPath.session.sessionComponent,
        component: SessionSectionPage,
      },
    ],
  },
];



