<script lang="ts" setup>
import {
  type Content,
  ContentDisplayType,
} from '@/ts/types/dto/interventions-dto';
import ReadOnlyNonCollapsableTitle from '@/vue/molecules/read-only/helpers/read-only-non-collapsable-title.vue';
import AudioPlayer from '@/vue/molecules/audio-player.vue';

defineProps<{
  content: Content
}>();
</script>

<template>
  <read-only-non-collapsable-title
    v-if="content.displayType !== ContentDisplayType.NO_TEXT"
    :header="content.name!"
    :active="false"
  >
    <div v-html="content.contentHTML"/>
    <audio-player :audio="content.audio" class="player"/>
  </read-only-non-collapsable-title>
</template>
<style lang="scss" scoped>
.player {
  margin-top: var(--Spacing-spacing-5);
  display: block;
  width: 100%;
}
</style>
