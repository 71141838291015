<script lang="ts" setup>
import ListItems from '@/vue/molecules/read-only/helpers/list-items.vue';
import ReadOnlyNonCollapsableTitle from '@/vue/molecules/read-only/helpers/read-only-non-collapsable-title.vue';

defineProps<{
  header: string;
  items: string[];
  active: boolean;
  content?: string;
}>();
</script>

<template>
  <read-only-non-collapsable-title :header="header" :active="active">
    <div v-if="content" v-html="content"/>
    <list-items :items="items"/>
  </read-only-non-collapsable-title>
</template>
