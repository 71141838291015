<script setup lang="ts">
import {BalmSidebarType} from '@/ts/types/component/balm-sidebar';
import FlatIconBtn from '@/vue/molecules/flat-icon-btn.vue';
import {useBalmSidebarMenu} from '@/ts/composables/stateful/use-balm-sidebar-menu';
import {useDrawerStore} from '@/ts/store/drawer-store';
import {storeToRefs} from 'pinia';
import SidebarTootip from '@/vue/atoms/sidebar-tootip.vue';

const {balmSidebarMenuItems} = useBalmSidebarMenu();
const drawerStore = useDrawerStore();
const {balmSidebarState} = storeToRefs(drawerStore);

function onClickItem(type: BalmSidebarType): void {
  drawerStore.openBalmSidebarDrawer(type);
}

</script>
<template>
  <ul class="balm-sidebar-toolbar" role="toolbar">
    <li
      v-for="item of balmSidebarMenuItems"
      :key="item.type"
      class="sidebaritem"
    >
      <sidebar-tootip v-if="item.type === BalmSidebarType.IMPRINT" :hide="!balmSidebarState" :label="$t('legal_documents')"/>
      <flat-icon-btn
        :icon="item.icon"
        :label="$t(item.labelKey)"
        :active="balmSidebarState === item.type"
        :use-label-as-title-attribute="true"
        padded
        @click="onClickItem(item.type)"
      />
    </li>
  </ul>
</template>
<style scoped lang="scss">
.balm-sidebar-toolbar {
  padding: var(--Spacing-spacing-5) 0;
  display: flex;
  flex-shrink: 0;
  height: 100%;
  flex-direction: column;
  align-items: center;
  gap: var(--Spacing-spacing-5);
  list-style-type: none;
  margin: 0;
}
</style>
