import {useI18n} from 'vue-i18n';

export function useRequiredRule(): (val: string) => string | true {
  const {t: $t} = useI18n();
  return (val: string) => {
    return val && val.trim().length > 0 || $t('required');
  };
}

export function useRequiredNumber(): (val: number) => string | true {
  const {t: $t} = useI18n();
  return (val: number) => {
    return val !== null || $t('required');
  };
}

export function useYearsOfBirth(): [(val: number) => string | true, (val: number) => string | true] {
  const {t: $t} = useI18n();

  function minYear(val: number): string | true {
    const MIN_BIRTH_YEAR = 1900;
    return (val > MIN_BIRTH_YEAR && val < (new Date().getFullYear())) || $t('invalid_year');
  }

  function maxYear(val: number): string | true {
    // Min 18 years old
    return val < (new Date().getFullYear() - 17) || $t('you_must_be');
  }

  return [minYear, maxYear];
}

export function useRequiredSelectRule(): (val: string[] | string) => string | true {
  const {t: $t} = useI18n();
  return (val: string[] | string) => {
    if ((Array.isArray(val) && val.length === 0) || !val) {
      return $t('required');
    }
    return true;
  };
}

export function useRequiredTrueRule(): (val: boolean) => string | true {
  const {t: $t} = useI18n();
  return (val: boolean) => {
    return Boolean(val) || $t('required');
  };
}

export function useRequiredDateRule(): (val: string) => string | true {
  const {t: $t} = useI18n();
  const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/u;
  return (val: string) => {
    return dateFormatRegex.test(val) || $t('required');
  };
}

export function useEmailRule(): (val: string) => string | true {
  const {t: $t} = useI18n();
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/u;
  return (val: string) => {
    if (!val) {
      return $t('required');
    }

    if (!emailPattern.test(val)) {
      return $t('invalid_format');
    }
    return true;
  };
}

export function usePasswordRules(): ((val: string) => string | true)[] {
  const {t: $t} = useI18n();
  return [
    (val: string): string | true => Boolean(val) || $t('password_required'),
    (val: string): string | true => val.length >= 8 || $t('password_required_8'),
    (val: string): string | true => /[A-Z]/u.test(val) || $t('password_required_upper'),
    (val: string): string | true => /[a-z]/u.test(val) || $t('password_required_lower'),
    (val: string): string | true => /\d/u.test(val) || $t('password_required_number'),
    (val: string): string | true => /[^A-Za-z0-9]/u.test(val) || $t('password_required_special'),
  ];
}
