<script setup lang="ts">

import BalmBtn from '@/vue/atoms/balm-btn.vue';

defineProps<{
  name?: string;
  descriptionHtml?: string | null;
  showBtn?: boolean;
  noCard?: boolean;
}>();

defineEmits<{
  add: [],
}>();

</script>
<template>
  <div class="session-two-blocks" :class="{'no-card': noCard, card: !noCard}">
    <div class="content">
      <div class="left" :class="{small: !!$slots.default}">
        <h5 v-if="name">
          {{ name }}
        </h5>
        <div v-if="descriptionHtml" v-html="descriptionHtml"/>
        <slot name="left"/>
      </div>
      <div v-if="$slots.default" class="right">
        <slot/>
        <balm-btn
          v-if="showBtn"
          theme="grey"
          :label="$t('add_item')"
          class="btn"
          icon="plus.svg"
          @click="$emit('add')"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">

.session-two-blocks.card {
  @include q-card-white;
}
.card {
  .content {
    padding: var(--Spacing-spacing-7);
  }
}

.content {
  display: flex;
  background-color: var(--color-netural-ui-10);
  flex-direction: row;
  width: 100%;
  gap: var(--Spacing-spacing-4);
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
}

h5 {
  color: var(--Neutral-UI-neutral-ui-100);
}

.btn {
  margin-top: var(--Spacing-spacing-4);
  margin-left: auto;
  text-transform: none;
}

.left {
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-2);

  &.small {
    width: 300px;
  }

  &:not(.small) {
    max-width: 600px;
  }
}
</style>
