import type {FetchWrapper} from '@/ts/api/fetch-wrapper';
import type {SignUpRequest} from '@/ts/types/dto/sign-in-dto';
import type {GetLegalDocsResponse} from '@/ts/types/dto/tos-dto';
import type {
  ChangePasswordRequest,
  ResetPasswordRequest,
} from '@/ts/types/dto/change-password-dto';

/**
 * {@link https://api-dev.ip.significo.dev/swagger-ui/index.html?urls.primaryName=public|Public API}
 */
export class PublicApi {
  public constructor(private readonly fetch: FetchWrapper) {
  }

  public async signUp(body: SignUpRequest): Promise<void> {
    return this.fetch.post({
      url: '/users',
      body,
    });
  }

  public async getLegalDocs(): Promise<GetLegalDocsResponse> {
    return this.fetch.get({
      url: '/legal-docs',
    });
  }

  public async changePassword(body: ChangePasswordRequest): Promise<void> {
    return this.fetch.put({
      url: '/change-password',
      body,
      emptyResponse: true,
    });
  }

  public async resetPassword(body: ResetPasswordRequest): Promise<void> {
    return this.fetch.post({
      url: '/reset-password',
      body,
      emptyResponse: true,
    });
  }
}
