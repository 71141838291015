<script lang="ts" setup>
import type {
  ConceptualizationWorksheet,
  PrimaryFeeling,
  ThinkingTrap,
} from '@/ts/types/dto/interventions-dto';
import {computed} from 'vue';
import ReadOnlyList from '@/vue/molecules/read-only/helpers/read-only-list.vue';
import ReadOnlyChips from '@/vue/molecules/read-only/helpers/read-only-chips.vue';
import ReadOnlyTraps from '@/vue/molecules/read-only/helpers/read-only-traps.vue';
import ReadOnlyQuestionnaire from '@/vue/molecules/read-only/questionnaire/read-only-questionnaire.vue';
import ReadOnlyNonCollapsableTitle from '@/vue/molecules/read-only/helpers/read-only-non-collapsable-title.vue';

const props = defineProps<{
  worksheet: ConceptualizationWorksheet;
  primaryFeelings: PrimaryFeeling[];
  thinkingTraps: ThinkingTrap[];
}>();

const feelings = computed<string[]>(() => {
  const pFeels: string[] = props.worksheet.primaryFeelingsIds.value
    .map((feelId) => props.primaryFeelings.find((feel) => feel.id === feelId)!.name);
  const allSecFeel = props.primaryFeelings.flatMap(pf => pf.secondaryFeelings);
  const sFeels: string[] = props.worksheet.secondaryFeelingsIds.value
    .map((feelId) => allSecFeel.find((sFeel) => sFeel.id === feelId)!.name);
  return [...pFeels, ...sFeels];
});


const thinkingTraps = computed<string[]>(() => {
  return  props.worksheet.thinkingTraps.value.map(uuid => props.thinkingTraps.find(tt => tt.id === uuid)!.name);
});
</script>

<template>
  <read-only-non-collapsable-title
    :content="worksheet.situation.description"
    :content2="worksheet.situation.value"
    :header="$t('situation')"
    active
  />
  <read-only-list
    v-if="worksheet.thoughts.show"
    :header="$t('thoughts')"
    :content="worksheet.thoughts.description"
    active
    :items="worksheet.thoughts.value"
  />
  <read-only-questionnaire :questionnaire="worksheet.questionnaire"/>
  <read-only-chips :header="$t('feelings')" active :items="feelings"/>
  <read-only-list
    v-if="worksheet.behaviors.show"
    :header="$t('behaviours')"
    active
    :items="worksheet.behaviors.value"
  />
  <read-only-list
    v-if="worksheet.physicalReactions.show"
    :header="$t('cues')"
    active
    :items="worksheet.physicalReactions.value"
  />
  <read-only-traps
    v-if="worksheet.thinkingTraps.show"
    :header="$t('thinking_traps')"
    active
    :items="thinkingTraps"
  />
  <read-only-list
    v-if="worksheet.copingThoughts.show"
    :header="$t('copying_thoughts')"
    active
    :items="worksheet.copingThoughts.value"
  />
</template>
<style lang="scss" scoped>
</style>
