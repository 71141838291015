<script lang="ts" setup>
import BalmLabel from '@/vue/molecules/balm-label.vue';

defineProps<{
  theme: 'blue' | 'white' | 'green' | 'red'
}>();
</script>

<template>
  <balm-label :theme="theme" :label="$t('current')"/>
</template>
