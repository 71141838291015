/* eslint-disable @typescript-eslint/naming-convention */
import type {FetchWrapper} from '@/ts/api/fetch-wrapper';
import type {Language} from '@/ts/types/component/localization.type';
import type {ZitadelGetTokenResponse} from '@/ts/types/dto/zitadel-dto';
import type {ZitadelConfig} from '@/ts/utils/consts';

/**
 * {@link https://zitadel.com/docs/apis/openidoauth/endpoints|Zitadel API}
 */
export class ZitadelApi {
  public constructor(private readonly fetch: FetchWrapper) {
  }

  public redirectToSignIn(redirectUri: string, codeChallenge: string, zitadelConfig: ZitadelConfig, lang: Language): void {
    const searchParams = new URLSearchParams({
      client_id: zitadelConfig.client_id,
      redirect_uri: redirectUri,
      response_type: 'code',
      response_mode: 'query',
      scope: `openid profile email offline_access 
              urn:zitadel:iam:org:id:${zitadelConfig.organization_resource_id} 
              urn:zitadel:iam:org:project:id:${zitadelConfig.project_resource_id}:aud 
              urn:zitadel:iam:org:projects:roles`,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
      ui_locales: lang,
    });

    window.location = `${this.fetch.backendUrl}/oauth/v2/authorize?${searchParams.toString()}` as unknown as Location;
  }

  public async getToken(code: string, clientId: string, codeVerifier: string, redirectUri: string): Promise<ZitadelGetTokenResponse> {
    return this.fetch.get({
      url: '/oauth/v2/token',
      queryParams: {
        grant_type: 'authorization_code',
        code,
        client_id: clientId,
        code_verifier: codeVerifier,
        redirect_uri: redirectUri,
      },
    });
  }

  public revokeToken(id_token: string, clientId: string, redirectUri: string): void {
    const params = new URLSearchParams({
      id_token_hint: id_token,
      post_logout_redirect_uri: redirectUri,
    });
    window.location.href = `${this.fetch.backendUrl}/oidc/v1/end_session?${params.toString()}`;
  }

  // Same refresh token can be used only once
  public async refreshToken(refreshToken: string, zitadelConfig: ZitadelConfig): Promise<ZitadelGetTokenResponse> {
    // TODO
    // this thing is broken because of project_id is set to VueSpa project id and organization is included here
    // if we remove organization from scope and replace project_resouce_id from VueSpa id to Zitadel id it will work
    // otherwise now it throws invalid_scope

    // this change was done so we need to drop register button from zitadel login page
    // and this is why user has to have organization id in JWT

    // To trigger this method. Put this code in the chrome devtools
    // a = JSON.parse(localStorage.getItem('auth'))
    // a.expires_in = 0
    // localStorage.setItem('auth', JSON.stringify(a))
    // and do some action that trigger any backend request
    return this.fetch.get({
      url: '/oauth/v2/token',
      queryParams: {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        scope: `openid profile email offline_access 
                urn:zitadel:iam:org:id:${zitadelConfig.organization_resource_id} 
                urn:zitadel:iam:org:project:id:${zitadelConfig.project_resource_id}:aud 
                urn:zitadel:iam:org:projects:roles`,
        client_id: zitadelConfig.client_id,
      },
    });
  }
}
