import {
  AnswerComponentType,
  type AnswerProblemSolvingWorksheetBody,
  type  SessionRequestProblemSolvingWorksheet,
} from '@/ts/types/dto/session-request-dto';
import type {ProblemSolvingWorksheet} from '@/ts/types/dto/interventions-dto';

export function getDefaultProblemSolvingAnswers(): AnswerProblemSolvingWorksheetBody {
  return {
    goal: '',
    problem: '',
    rewards: [''],
    solutions: [{
      activities: [''],
      badThings: '',
      name: '',
      goodThings: '',
    }],
  };
}

export function propsToValue(worksheet: ProblemSolvingWorksheet): AnswerProblemSolvingWorksheetBody {
  if (worksheet.isAnswered) {
    return {
      goal: worksheet.goal,
      rewards: worksheet.rewards,
      problem: worksheet.problem,
      solutions: worksheet.solutions,
    };
  }
  return getDefaultProblemSolvingAnswers();
}

export function valueProblemSolvingToModel(
  worksheet: ProblemSolvingWorksheet,
  answer: AnswerProblemSolvingWorksheetBody,
): SessionRequestProblemSolvingWorksheet {
  return {
    type: AnswerComponentType.PROBLEM_SOLVING_WORKSHEET,
    answerProblemSolvingWorksheet: {
      answer,
      userComponentId: worksheet.userComponentId,
    },
  };
}
