<script setup lang="ts">
import GoalShort from '@/vue/molecules/goal-short.vue';
import {
  type Goal,
  SessionState,
} from '@/ts/types/dto/interventions-dto';
import LazyImage from '@/vue/atoms/lazy-image.vue';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';

defineProps<{
  description?: string;
  title?: string;
  goalsTitle: string;
  image?: UuidDTO;
  goals: Goal[];
  finished?: boolean;
  state?: SessionState;
}>();
</script>
<template>
  <lazy-image v-if="image" :src="image" :width="1200" class="image"/>
  <div v-if="title && state !== SessionState.COMPLETED" class="summary">
    <h2>{{ title }}</h2>
    <div v-html="description"/>
    <slot/>
  </div>

  <div v-if="goals.length" class="goals-card">
    <div class="goal">
      <h2>{{ goalsTitle }}</h2>
      <div class="goals">
        <goal-short
          v-for="(goal, i) in goals"
          :key="goal.id"
          :order="i + 1"
          :goal="goal"
          :finished="finished"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.image {
  height: 400px;
  border-radius: var(--card-border-radius);
}

h2 {
  @include body-large-3;
  margin-bottom: var(--Spacing-spacing-4);
}

.summary, .goals-card {
  @include q-card-0;
  gap: var(--Spacing-spacing-6);
  padding: var(--Spacing-spacing-4);
  @include screen-lg {
    padding: var(--Spacing-spacing-7);
  }
}

.goals {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--Spacing-spacing-4);
  container-type: inline-size;
}
</style>
