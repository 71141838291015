import {computed, onMounted, onUnmounted, ref, type Ref} from 'vue';
import {ScreenSize} from '@/ts/types/component/screen-size.type';
import {useLogger} from '@/ts/composables/pure/use-logger';

const validScreenSizes = [
  ScreenSize.XS,
  ScreenSize.SM,
  ScreenSize.MD,
  ScreenSize.LG,
  ScreenSize.XL,
  ScreenSize.XXL,
];

// eslint-disable-next-line max-lines-per-function
export function useScreenSize(): {
  screenSize: Ref<ScreenSize>
  isMobileResolution: Ref<boolean>,
  isDesktopResolution: Ref<boolean>,
} {
  const logger = useLogger();

  function getCurrentScreenSize(): ScreenSize {
    const {content} = window.getComputedStyle(document.body, '::before');
    const breakpointName = content.replace(/["']/ug, '') as ScreenSize; // Remove surrounding quotes if any
    if (!validScreenSizes.includes(breakpointName)) {
      throw new Error(`Unknown screen-size ${breakpointName}.`);
    }
    return breakpointName;
  }

  const screenSize = ref<ScreenSize>(getCurrentScreenSize());

  function updateBreakpoint(): void {
    screenSize.value = getCurrentScreenSize();
  }

  onMounted((): void => {
    logger.debug('onMounted, add resize event to window')();
    window.addEventListener('resize', updateBreakpoint);
    updateBreakpoint(); // Initial update on mount
  });

  onUnmounted((): void => {
    logger.debug('onUnmounted, remove resize event from window')();
    window.removeEventListener('resize', updateBreakpoint);
  });

  const isMobileResolution = computed<boolean>(() => {
    // Returns true, if the viewport has a typical smartphone or tablet resolution.
    // Does not contain any information about whether the user actually uses a mobile device.
    // A small browser-window or a zoomed-in viewport on a desktop device will also return true.
    return [
      ScreenSize.XS,
      ScreenSize.SM,
      ScreenSize.MD,
    ].includes(screenSize.value);
  });

  const isDesktopResolution = computed<boolean>(() => {
    // returns true, if the viewport has a typical desktop resolution
    return [
      ScreenSize.LG,
      ScreenSize.XL,
      ScreenSize.XXL,
    ].includes(screenSize.value);
  });

  return {
    screenSize,
    isMobileResolution,
    isDesktopResolution,
  };
}