<script setup lang="ts">
import FlatIconBtn from '@/vue/molecules/flat-icon-btn.vue';
import type {Solution} from '@/ts/types/dto/interventions-dto';

const model = defineModel<Solution>();

defineProps<{
  showDelete: boolean;
  showHeader: boolean;
}>();

defineEmits<{
  delete: [],
}>();

</script>
<template>
  <div class="solution">
    <template v-if="showHeader">
      <span class="header">{{ $t('solution') }}</span>
      <span class="header">{{ $t('good_things_about') }}</span>
      <span class="header">{{ $t('bad_things_about') }}</span>
    </template>
    <div class="trash-holder">
      <q-input
        v-model="model!.name"
        class="input"
        type="textarea"
      />
      <flat-icon-btn v-if="showDelete" icon="trash.svg" class="trash-icon" @click="$emit('delete')"/>
    </div>
    <q-input
      v-model="model!.goodThings"
      class="input"
      type="textarea"
    />
    <q-input
      v-model="model!.badThings"
      class="input"
      type="textarea"
    />
  </div>
</template>
<style scoped lang="scss">
.input {
  background-color: var(--color-white);

  :deep(textarea) {
    padding: var(--Spacing-spacing-6);
  }
}

.trash-holder {
  position: relative;
}

.trash-icon {
  position: absolute;
  right: var(--Spacing-spacing-2);
  bottom: var(--Spacing-spacing-2);
}

.header {
  @include body-medium-3;
}


.solution {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr;
  gap: var(--Spacing-spacing-4);

  > * {
    flex: 1;
  }
}
</style>
