import {
  computed,
  type ComputedRef,
  type Ref,
} from 'vue';
import {
  type Intervention,
  PublicationState,
  type Session,
  SessionState,
} from '@/ts/types/dto/interventions-dto';
import {useLogger} from '@/ts/composables/pure/use-logger';

export interface UseInterventionRes {
  inProgress: ComputedRef<boolean>;
  hasContent: ComputedRef<boolean>;
  currentSession: ComputedRef<Session | null>;
}

export function useProvidedIntervention(intervention: ComputedRef<Intervention | null> | Ref<Intervention | null>): UseInterventionRes {
  const logger = useLogger();
  const inProgress = computed(() => intervention.value?.publicationState === PublicationState.IN_PROGRESS);
  const hasContent = computed(() => Boolean(intervention.value) && intervention.value!.sessions.length > 0);
  const currentSession = computed<Session | null>(() => {
    if (!intervention.value) {
      return null;
    }
    // get first session that is in status of unlock or homework pending
    // otherwise show the next session to the state of closed
    let res: Session | null = intervention.value!.sessions
      .find(ses => [SessionState.UNLOCK, SessionState.HOMEWORK_PENDING].includes(ses.state))
      ?? null;
    if (!res) {
      const lastCompleted = intervention.value!.sessions.findLastIndex(ses => ses.state === SessionState.COMPLETED);
      if (intervention.value!.sessions[lastCompleted + 1]) {
          res = intervention.value!.sessions[lastCompleted + 1];
      }
    }

    if (!res && intervention.value!.sessions.length > 0) {
      logger.error('No current session found, returning the first session in the list.')();
      [res] = intervention.value!.sessions;
    }
    return res;
  });
  return {inProgress, hasContent, currentSession};
}
