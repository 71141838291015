<script setup lang="ts">
import {ref} from 'vue';
import type {LegalDocItem} from '@/ts/types/dto/tos-dto';
import {useLoadingDataRefNull} from '@/ts/composables/pure/use-loading-data-ref';
import {useApi} from '@/ts/composables/stateful/use-api';
import LoadingError from '@/vue/templates/loading-error.vue';
import HtmlFormatted from '@/vue/molecules/html-formatted.vue';

defineProps<{
  imprint: LegalDocItem;
}>();

enum Tabs {
  IMPRINT = 'imprint',
  PRIVACY_POLICY = 'privacy_policy',
  TERMS_OF_USE = 'terms_of_use',
}

const api = useApi();

const tab = ref<Tabs>(Tabs.IMPRINT);
const [toc, loading, error] = useLoadingDataRefNull(async() => api.public.getLegalDocs());
</script>
<template>
  <div>
    <loading-error id="sidebar-legal-docs" :loading="loading" type="component" :error="error">
      <q-tabs v-model="tab">
        <q-tab :label="$t('imprint')" :name="Tabs.IMPRINT"/>
        <q-tab :label="$t('privacy_policy')" :name="Tabs.PRIVACY_POLICY"/>
        <q-tab :label="$t('terms_of_use')" :name="Tabs.TERMS_OF_USE"/>
      </q-tabs>

      <q-tab-panels v-model="tab" animated class="sidebar-panel">
        <q-tab-panel :name="Tabs.IMPRINT">
          <html-formatted :html="imprint.legalCopyHTML" background="neutral-10"/>
        </q-tab-panel>
        <q-tab-panel :name="Tabs.PRIVACY_POLICY">
          <loading-error
            id="sidebar-legaldocs-privacy"
            :loading="loading"
            type="skeleton"
          >
            <html-formatted :html="toc!.privacyPolicy.legalCopyHTML" background="neutral-10"/>
          </loading-error>
        </q-tab-panel>
        <q-tab-panel :name="Tabs.TERMS_OF_USE">
          <loading-error
            id="sidebar-legaldocs-tac"
            :loading="loading"
            type="skeleton"
          >
            <html-formatted :html="toc!.termsAndConditions.legalCopyHTML" background="neutral-10"/>
          </loading-error>
        </q-tab-panel>
      </q-tab-panels>
    </loading-error>
  </div>
</template>
<style lang="scss" scoped>
.sidebar-panel {
  background-color: inherit;

  :deep(.q-tab-panel) {
    padding: var(--Spacing-spacing-4);
  }
}
</style>

