<script setup lang="ts">
import {ref} from 'vue';
import {useUserStore} from '@/ts/store/user-store';
import {storeToRefs} from 'pinia';
import UserAvatar from '@/vue/molecules/user-avatar.vue';
import {useDrawerStore} from '@/ts/store/drawer-store';
import {useScreenSize} from '@/ts/composables/stateful/use-screen-size';
import UserMenuDrawer from '@/vue/organisms/header/user-menu-drawer.vue';
import {QBtn} from 'quasar';

const userStore = useUserStore();
const {userData} = storeToRefs(userStore);

const drawerStore = useDrawerStore();
const {isUserDrawerOpen} = storeToRefs(drawerStore);

const {isMobileResolution} = useScreenSize();

function onClickOpenMenu(): void {
  drawerStore.openUserDrawer();
}

const btnOpenMenuOnMobile = ref<QBtn|undefined>();
function onFocusUserMenuButton(): void {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  btnOpenMenuOnMobile.value?.$el?.focus();
};

</script>

<template>
  <div v-if="userData" class="user-menu">
    <q-btn-dropdown
      v-if="!isMobileResolution"
      v-model="isUserDrawerOpen"
      class="btn"
      flat
      dense
    >
      <template #label>
        <user-avatar :hide-username-on-mobile="true"/>
      </template>
      <user-menu-drawer/>
    </q-btn-dropdown>

    <q-btn
      v-if="isMobileResolution"
      ref="btnOpenMenuOnMobile"
      class="btn"
      flat
      dense
      @click="onClickOpenMenu"
    >
      <user-avatar :hide-username-on-mobile="true"/>
    </q-btn>

    <user-menu-drawer
      v-if="isMobileResolution"
      @requestOpenerFocus="onFocusUserMenuButton"
    />
  </div>
</template>

<style lang="scss" scoped>

.user-menu {
  display: flex;
}

.btn {
  @include focus-visible;
}

</style>
