import {
  nextTick,
  type Ref,
} from 'vue';
import type {ComponentPublicInstance} from '@vue/runtime-core';
import {useLogger} from '@/ts/composables/pure/use-logger';

export function useScrollTo(form: Ref<ComponentPublicInstance>, reserveSpace: number): () => void {
  const logger = useLogger();
  return () => {
    const oldScrollHeight = ((form.value.$el as HTMLElement).firstChild as HTMLElement).scrollHeight;
    void nextTick(() => {
      const containerRect = form.value.$el as HTMLElement;
      logger.debug(`Scrolling to ${oldScrollHeight - reserveSpace}`)();
      containerRect.scrollTo({top: oldScrollHeight - reserveSpace, behavior: 'smooth'});
    });
  };
}
