<script setup lang="ts">

import SessionLeftBlock from '@/vue/organisms/session-left-block.vue';
import {useInterventionStore} from '@/ts/store/intervention-store';
import {computed} from 'vue';
import {
  type Session,
  SessionState,
} from '@/ts/types/dto/interventions-dto';
import PartLeftBlock from '@/vue/molecules/part-left-block.vue';
import {PartLeftBlockClass} from '@/ts/types/component/part-left-block';
import {useInterventionLinks} from '@/ts/composables/stateful/use-links';
import DescriptionLeftEnter from '@/vue/molecules/description-left-enter.vue';

const intervention = useInterventionStore();

interface ExtendedSess {
  session: Session;
  forceState?: PartLeftBlockClass;
}

const finishedSessions = computed(() => {
  if (!intervention.value) {
    return [];
  }
  const res: ExtendedSess[] = [];
  for (let index = 0; index < intervention.value.sessions.length; index++) {
    if (intervention.value.sessions[index].state !== SessionState.LOCK) {
      res.push({session: intervention.value.sessions[index]});
    } else if (intervention.value.sessions[index - 1].state === SessionState.COMPLETED) {
      res.push({session: intervention.value.sessions[index], forceState: PartLeftBlockClass.LAST_IN_PROGRESS});
    }
  }
  return res;
});

const nextSessionName = computed<string | null>(() => {
  if (!intervention.value) {
    return null;
  }
  if (finishedSessions.value.length < intervention.value.sessions.length) {
    return intervention.value.sessions[finishedSessions.value.length].name;
  }
  return null;
});

const {aboutLink} = useInterventionLinks();
</script>
<template>
  <div class="session-description-menu">
    <description-left-enter :name="intervention!.name" :to="aboutLink!" size="m3"/>
    <div class="sessions">
      <session-left-block
        v-for="extSess in finishedSessions"
        :key="extSess.session.id"
        :session-id="extSess.session.id"
        :force-state="extSess.forceState"
      />
      <part-left-block
        v-if="nextSessionName"
        :title="$t('next_session')"
        :state="PartLeftBlockClass.NOT_STARTED"
        :description="nextSessionName"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.sessions {
  @include dashed-between-elements(var(--Spacing-spacing-2));
  width: 100%;
  margin-top: var(--Spacing-spacing-3);
}
</style>
