<script setup lang="ts">
import type {Content} from '@/ts/types/dto/interventions-dto';
import ContentBlock from '@/vue/molecules/components/helpers/content-block.vue';

defineProps<{
  content: Content;
}>();


</script>
<template>
  <content-block
    :name="content.name"
    :audio="content.audio"
    class="content-component"
    :image="content.image"
    :display-type="content.displayType"
    :content="content.contentHTML"
  />
</template>
<style scoped lang="scss">
.content-component {
  @include q-card-white;
  padding: var(--Spacing-spacing-4);
  @include screen-lg {
    padding: var(--Spacing-spacing-7);
  }
}

</style>
