<script lang="ts" setup>
import {type InterventionPreview, PublicationState} from '@/ts/types/dto/interventions-dto';
import {computed} from 'vue';
import {useI18n} from 'vue-i18n';
import BalmBtn from '@/vue/atoms/balm-btn.vue';
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';
import {getVueLink} from '@/ts/composables/stateful/use-route-params';
import {pagesPath} from '@/ts/router/pages-path';

const {t: $t} = useI18n();

const props = defineProps<{
  intervention: InterventionPreview
}>();

const cardLink = computed(() => getVueLink(pagesPath.intervention.interventionInfo, {iid: props.intervention.id}));
</script>

<template>
  <div class="intervention-footer">
    <div v-if="intervention.publicationState === PublicationState.MAINTENANCE" class="label">
      <span>{{ `${$t('back_shortly')}` }}</span>
    </div>
    <div v-else-if="intervention.publicationState === PublicationState.PREVIEW" class="label">
      <span>{{ `${$t('coming_soon')}` }}</span>
    </div>
    <div v-else-if="intervention.publicationState === PublicationState.COMPLETED" class="label completed">
      <svg-illustration name="check.svg"/>
      <span>{{ `${$t('completed_on')}` }}</span>
    </div>
    <balm-btn
      v-else
      class="continue-btn"
      :label="PublicationState.IN_PROGRESS === intervention.publicationState ? $t('continue_path') : $t('explore_path')"
      :to="cardLink"
      theme="white"
      paddings="p3-7"
      icon="arrow-narrow-right.svg"
    />
  </div>
</template>
<style scoped lang="scss">
.continue-btn {
  width: 100%;
}

.label {
  display: flex;
  @include body-medium-3;

  &.completed {
    > span {
      margin-left: var(--Spacing-spacing-2);
    }
  }
}
</style>
