import {
  computed,
  type ComputedRef,
} from 'vue';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {
  PageState,
  type Session,
} from '@/ts/types/dto/interventions-dto';

import {useInterventionStore} from '@/ts/store/intervention-store';
import {
  useSession,
  type UseSessionRes,
} from '@/ts/composables/pure/use-provided-session';


export interface UseSessionCurrentRes {
  currentPageId: ComputedRef<number | null>;
}

export function useSessionId(id: ComputedRef<UuidDTO | null>): UseSessionRes {
  const interventionRef = useInterventionStore();
  const interventionComp = computed(() => interventionRef.value!);
  const session = computed<Session | null>(() => interventionComp.value?.sessions.find(ses => ses.id === id.value) ?? null);
  return useSession(interventionComp, session);
}

export function useSessionProgress(sid: ComputedRef<UuidDTO | null>): UseSessionCurrentRes {
  const {session} = useSessionId(sid);
  const currentPageId = computed(() => {
    if (!session.value) {
      return null;
    }
    const index = session.value!.pages.findIndex(page => page.state !== PageState.COMPLETED );
    if (index < 0) {
      return null;
    }
    return index + 1;
  });
  return {currentPageId};
}
