<script setup lang="ts">
import type {
  AnswerProblemSolvingWorksheetBody,
  SessionRequestProblemSolvingWorksheet,
} from '@/ts/types/dto/session-request-dto';
import ExampleSingleInput from '@/vue/molecules/components/helpers/example-single-input.vue';
import {
  computed,
  onBeforeMount,
  ref,
  watch,
} from 'vue';
import SessionTwoBlocks from '@/vue/molecules/components/helpers/session-two-blocks.vue';
import type {ProblemSolvingProps} from '@/ts/types/component/problem-solving';
import PossibleSolutions from '@/vue/molecules/components/problem-solving/possible-solutions.vue';
import {useLogger} from '@/ts/composables/pure/use-logger';
import ExampleMultipleInput from '@/vue/molecules/components/helpers/example-multiple-input.vue';
import TableComponent from '@/vue/molecules/components/table-component.vue';
import type {QuasarOption} from '@/ts/types/component/select-multichoice';
import type {Solution} from '@/ts/types/dto/interventions-dto';
import PossibleActivities from '@/vue/molecules/components/problem-solving/possible-activities.vue';
import {
  getDefaultProblemSolvingAnswers,
  propsToValue,
  valueProblemSolvingToModel,
} from '@/ts/composables/pure/use-problem-solving-mapper';
import AddActivityBtnBlock from '@/vue/molecules/components/problem-solving/add-activity-btn-block.vue';

const props = defineProps<ProblemSolvingProps>();
const model = defineModel<SessionRequestProblemSolvingWorksheet[] | null>();

const logger = useLogger();
const value = ref<AnswerProblemSolvingWorksheetBody>(getDefaultProblemSolvingAnswers());
const possibleActivitiesIndexes = ref<number[]>([0]);

function deleteSol(index: number): void {
  possibleActivitiesIndexes.value = possibleActivitiesIndexes.value.filter((id) => id !== index);
  value.value.solutions.splice(index, 1);
}

const remainingActivitiesIndexes = computed<QuasarOption<number>[]>(() => value.value.solutions
  .map((solution: Solution, index: number) => ({
    value: index,
    label: solution.name,
  }))
  .filter((sol: QuasarOption<number>, index) => !possibleActivitiesIndexes.value.includes(index)));

onBeforeMount(() => {
  logger.debug(`onBeforeMount ${props.worksheet.contentId}`)();
  value.value = propsToValue(props.worksheet);
  if (!model.value) {
    model.value = [valueProblemSolvingToModel(props.worksheet, value.value)];
  }
});

watch(
  value,
  (newValue) => {
    logger.debug(`watch ${props.worksheet.contentId} {}`, newValue)();
    model.value = [valueProblemSolvingToModel(props.worksheet, newValue)];
  },
  {deep: true},
);

function updateIndex(from: number, to: number): void {
  value.value.solutions[to].activities = value.value.solutions[from].activities;
  value.value.solutions[from].activities = [''];
  possibleActivitiesIndexes.value[possibleActivitiesIndexes.value.findIndex((id) => id === from)] = to;
}

function addSolution(): void {
  value.value.solutions.push({
    activities: [''],
    badThings: '',
    name: '',
    goodThings: '',
  });
}
</script>
<template>
  <div class="wrapper">
    <example-single-input
      :id="`problem-${props.worksheet.contentId}-problem`"
      v-model="value.problem"
      :name="$t('problem')"
      :description-html="worksheet.descriptionProblem"
      :example-html="worksheet.exampleProblem"
    />
    <example-single-input
      :id="`problem-${props.worksheet.contentId}-goal`"
      v-model="value.goal"
      :name="$t('goal')"
      :description-html="worksheet.exampleGoal"
      :example-html="worksheet.goal"
    />
    <session-two-blocks :description-html="worksheet.solutionsInstructions" :name="$t('solutions')"/>
    <table-component :table="worksheet.table"/>
    <possible-solutions
      v-model="value.solutions"
      :example-html="worksheet.exampleSolution"
      :description-html="worksheet.descriptionSolution"
      @delete="deleteSol"
      @add="addSolution"
    />
    <session-two-blocks :description-html="worksheet.actionsInstructions" :name="$t('actions')"/>
    <possible-activities
      v-for="scInd in possibleActivitiesIndexes"
      :key="scInd"
      v-model="value.solutions[scInd].activities"
      :options="remainingActivitiesIndexes"
      :solution="value.solutions[scInd].name"
      :index="scInd"
      :description-html="worksheet.descriptionActivity"
      :example-html="worksheet.exampleActivity"
      @update:index="(newVal: number) => updateIndex(scInd, newVal)"
    />
    <add-activity-btn-block v-if="remainingActivitiesIndexes.length > 0"/>
    <example-multiple-input
      v-model="value.rewards"
      :name="$t('rewards')"
      :description-html="worksheet.descriptionRewards"
      :example-html="worksheet.exampleRewards"
    />
  </div>
</template>
<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-4);
}
</style>
