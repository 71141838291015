import {useRouter} from 'vue-router';
import {computed, type ComputedRef} from 'vue';
import {useScreenSize} from '@/ts/composables/stateful/use-screen-size';

const QUERY_NAME = 'toc'; // toc = table of contents
const TRUE_VALUE = 'hidden';

interface UseRouteQueryParamFlagResult {
  isSet: ComputedRef<boolean>,
  isSetAndMobile: ComputedRef<boolean>,
  isUnsetAndMobile: ComputedRef<boolean>,
  addFlagAsQueryParam(): void,
  removeFlagAsQueryParam(): void,
  encodedSearchParams: ComputedRef<string>,
}

export function useTableOfContentsVisibilityQueryParamFlag(): UseRouteQueryParamFlagResult {
  const router = useRouter();
  const {isMobileResolution} = useScreenSize();

  const isSet = computed((): boolean => {
    return router.currentRoute.value.query[QUERY_NAME] === TRUE_VALUE;
  });

  const isSetAndMobile = computed((): boolean => {
    return isMobileResolution.value && isSet.value;
  });

  const isUnsetAndMobile = computed((): boolean => {
    return isMobileResolution.value && !isSet.value;
  });

  function addFlagAsQueryParam(): void {
    void router.push({query: {
        ...router.currentRoute.value.query,
        [QUERY_NAME]: TRUE_VALUE,
      }});
  }

  function removeFlagAsQueryParam(): void {
    void router.push({query: {
        ...router.currentRoute.value.query,
        [QUERY_NAME]: null,
      }});
  }

  const encodedSearchParams = computed(() => {
    return isMobileResolution.value ? new URLSearchParams(
      {
        [QUERY_NAME]: TRUE_VALUE,
      },
    ).toString() : '';
  });

  return {
    isSet,
    isSetAndMobile,
    isUnsetAndMobile,
    addFlagAsQueryParam,
    removeFlagAsQueryParam,
    encodedSearchParams,
  };
}