<script lang="ts" setup>
import {
  type Page,
  PageState,
} from '@/ts/types/dto/interventions-dto';
import {computed} from 'vue';
import {
  getVueLink,
  useRouterSectionParams,
} from '@/ts/composables/stateful/use-route-params';
import {pagesPath} from '@/ts/router/pages-path';
import PartLeftBlock from '@/vue/molecules/part-left-block.vue';
import {PartLeftBlockClass} from '@/ts/types/component/part-left-block';

const props = defineProps<{
  page: Page;
  index: number
}>();

const {sid, iid, pid} = useRouterSectionParams();
const pageState = computed<PartLeftBlockClass>(() => {
  if (props.index === pid.value) {
    return PartLeftBlockClass.CURRENT_PAGE;
  }
  const states: Record<PageState, PartLeftBlockClass> = {
    [PageState.COMPLETED]: PartLeftBlockClass.COMPLETED,
    [PageState.lOCK]:PartLeftBlockClass.NOT_STARTED,
    [PageState.UNLOCK]: PartLeftBlockClass.LAST_IN_PROGRESS,
  };
  return states[props.page.state];
});

const pageLink = computed(() => {
  return getVueLink(pagesPath.session.sessionComponent, {
    iid: iid.value,
    sid: sid.value,
    pid: props.index,
  });
});

</script>
<template>
  <part-left-block
    :to="pageLink"
    :description="page.name"
    :title="$t('page_n', {n: index})"
    :state="pageState"
  />
</template>
