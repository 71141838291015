import {loggerInstance} from '@/ts/instances/logger-instance';
import type {HotJarInnerData, UserInfoData, UserInfoSetter} from '@/ts/types/hotjar';
import type {Language} from '@/ts/types/component/localization.type';
import Hotjar from '@hotjar/browser';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import type {Logger} from 'lines-logger';


export interface HotJarProxy {
  setInfo(data: UserInfoData|null): void;
  setLanguage(data: Language): void
}

export class HotJar implements HotJarProxy {
  private userInfo: UserInfoSetter | null = null;
  private userId: UuidDTO|null = null;
  private userLang: Language|null = null;

  private readonly logger: Logger;

  constructor(id: number, sv: number) {
    this.logger = loggerInstance.getLogger('hotjar');
    this.logger.log(`Starting hotjar ${id} v${sv}`)();
    Hotjar.init(id, sv);
  }

  setInfo(data: UserInfoData|null): void {
    if (data) {
      this.userInfo = {
        marketingCodeExternalId: data.marketingCodeExternalId,
        birthYear: data.birthYear,
        educationLevel: data.educationLevel,
        gender: data.gender,
        registrationDate: data.registrationDate,
      };
      this.userId = data.id;
      const info: HotJarInnerData = {
        ...this.userInfo!,
        preferredLanguage: this.userLang,
      };
      this.logger.debug('Updated hotjar identify {}', info)();
      Hotjar.identify(this.userId, info  as unknown as Record<string, string>);
    } else {
      this.userInfo = null;
      this.userId = null;
      // TODO, do we need to reset user?
    }
  }

  setLanguage(data: Language): void {
    this.userLang = data;
    if (this.userInfo) {
      const info: HotJarInnerData = {
        ...this.userInfo,
        preferredLanguage: this.userLang,
      };
      this.logger.debug('Updated hotjar identify {}', info)();
      Hotjar.identify(this.userId, info as unknown as Record<string, string>);
    }
    // since we don't have user data, we can't call identify
  }
}
