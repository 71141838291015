<script lang="ts" setup>
import {
  onBeforeMount,
  ref,
  watch,
} from 'vue';
import {useI18n} from 'vue-i18n';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import type {PrimaryFeeling} from '@/ts/types/dto/interventions-dto';
import FeelingInput from '@/vue/molecules/components/helpers/feeling-input.vue';
import {useLogger} from '@/ts/composables/pure/use-logger';

const props = defineProps<{
  feelings: PrimaryFeeling[];
}>();
const model = defineModel<UuidDTO[]>('ids');
const isOpen = defineModel<boolean>('isOpen');

// array of array is required for 2 level loop which is flattened in model
const value = ref<UuidDTO[][]>([]);

const logger = useLogger();
const {t: $t} = useI18n();


function getCurrentArrayValue(val: UuidDTO[]): UuidDTO[][] {
  // fill with 2nd level feelings
  const res: UuidDTO[][] = props.feelings.map((feeling) => feeling.secondaryFeelings.filter(sf => val.includes(sf.id)).map(sf => sf.id));
  // add first level feelings
  props.feelings.forEach((feeling, int) => {
    if (val.includes(feeling.id)) {
      res[int].push(feeling.id);
    }
  });
  return res;
}

onBeforeMount(() => {
  value.value = getCurrentArrayValue(model.value!);
  logger.debug('setting value to {}', value.value)();
});

watch(value, (newVal) => {
  logger.debug('value changed', newVal)();
  // prevent infinitive watch from below
  if (newVal.reduce((totalLength, currentItem) => totalLength + currentItem.length, 0) !== model.value!.length) {
    model.value = newVal.flat();
    logger.debug('updating model', model.value)();
  }
}, {deep: true});


watch(model, (newModelValue) => {
  logger.debug('model changed', newModelValue)();
  // prevent infinitive watch from above
  if (newModelValue!.length !== value.value.reduce((totalLength, currentItem) => totalLength + currentItem.length, 0)) {
    value.value = getCurrentArrayValue(newModelValue!);
    logger.debug('updating value', value.value)();
  }
}, {deep: true});


</script>
<template>
  <q-dialog v-model="isOpen">
    <div class="card">
      <q-btn
        v-close-popup
        flat
        icon="close"
        class="close"
        round
      />
      <p>{{ $t('select_what_you') }}</p>
      <div class="feeling">
        <feeling-input v-for="(feeling, i) in feelings" :key="feeling.id" v-model="value[i]" :feeling="feeling"/>
      </div>
    </div>
  </q-dialog>
</template>
<style scoped lang="scss">
.card {
  display: flex;
  flex-direction: column;
  max-width: inherit;
  @include q-card-white;
  gap: var(--Spacing-spacing-4);
  padding: var(--Spacing-spacing-4);
}

.close {
  position: absolute;
  right: 5px;
  top: 5px;
  margin-left: auto;
}

.feeling {
  display: flex;
  flex-direction: row;
  gap: var(--Spacing-spacing-4);

  > * {
    width: 180px;
  }
}
</style>
