import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import type {
  Option,
  QuestionMultiChoice,
} from '@/ts/types/dto/interventions-dto';

export function calculateNumber(options: Option[], model: UuidDTO[] | undefined, question: QuestionMultiChoice): number | null {
  function idToValue(input: UuidDTO[]): number {
    return options.find(op => op.id === input[0])!.value;
  }

  if (model?.[0]) {
    return idToValue(model);
  }
  if (question.multiChoiceSelectedIds?.length > 0) {
    return idToValue(question.multiChoiceSelectedIds);
  }
  const defaultId = options.find(op => op.defaultAnswer);
  if (defaultId) {
    return defaultId.value;
  }
  return null;
}
