<script lang="ts" setup>

import type {MultiChoice} from '@/ts/types/dto/interventions-dto';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {computed} from 'vue';
import NoClickBtn from '@/vue/molecules/no-click-btn.vue';

const props = defineProps<{
  question: MultiChoice;
  answers: UuidDTO[];
}>();

const stringified = computed(() => props.answers.flatMap((uuid) => props.question.options.find(opt => opt.id === uuid)!.text));
</script>

<template>
  <div class="ro-grid-answer">
    <no-click-btn v-for="label in stringified" :key="label" :title="label" theme="p1-3"/>
  </div>
</template>
<style lang="scss" scoped>
.ro-grid-answer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--Spacing-spacing-3);
}
</style>
