import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';

export enum BalmSidebarType {
  GLOSSARY = 'glossary',
  SESSION_RECAP = 'recap',
  RESOURCES = 'resources',
  FAQ = 'faq',
  IMPRINT = 'imprint',
}

export interface SideBarGroupedSection {
  id: UuidDTO;
  name: string;
  items: SideBarSectionItem[];
}

export interface SideBarSectionItem {
  label: string;
  html: string;
  id: UuidDTO;
}

export interface BalmSidebarMenuItem {
  type: BalmSidebarType,
  labelKey: string,
  icon: string,
}
