<script setup lang="ts">
import type {QuestionNumeric} from '@/ts/types/dto/interventions-dto';
import {
  onBeforeMount,
  ref,
  watch,
} from 'vue';
import {
  type QuestionAnswerNumericBody,
  QuestionnaireType,
} from '@/ts/types/dto/session-request-dto';
import {useLogger} from '@/ts/composables/pure/use-logger';

const props = defineProps<{
  question: QuestionNumeric;
}>();


const model = defineModel<QuestionAnswerNumericBody|null>();
const value = ref(props.question.numeric.minValue);
const logger = useLogger();
onBeforeMount(() => {
  logger.debug('onBeforeMount')();
  value.value = props.question.numeric.minValue;
  model.value = {
    numeric: props.question.numeric.minValue,
    type: QuestionnaireType.NUMERIC,
  };
});

watch(value, (newVal) => {
  logger.debug('watch')();
  model.value = {
    numeric: newVal,
    type: QuestionnaireType.NUMERIC,
  };
});

</script>
<template>
  <div class="card">
    <div class="question" v-html="question.name"/>
    <q-slider v-model="value" :min="question.numeric.minValue" :max="question.numeric.maxValue"/>
  </div>
</template>
<style scoped lang="scss">
.question {
  margin: var(--Spacing-spacing-2) 0;
}

.card {
  @include q-card-white;
  padding: var(--Spacing-spacing-2);
}
</style>
