import type {RouteRecordRaw} from 'vue-router';
import {pagesPath} from '@/ts/router/pages-path';
import PublicSignUpPage from '@/vue/pages/public/public-sign-up-page.vue';
import PublicSignInRedirectPage from '@/vue/pages/public/public-sign-in-redirect-page.vue';
import PublicContinueAuthPage from '@/vue/pages/public/public-continue-auth-page.vue';
import NotFoundPage from '@/vue/pages/not-found-page.vue';

export const publicPages: RouteRecordRaw[] = [
  {
    path: pagesPath.public.signUp,
    component: PublicSignUpPage,
  },
  {
    path: pagesPath.public.public,
    redirect: pagesPath.public.signUp,
  },
  {
    path: pagesPath.public.signInRedirect,
    component: PublicSignInRedirectPage,
  },
  {
    path: pagesPath.public.continueAuth,
    component: PublicContinueAuthPage,
  },
  {
    path: '/:catchAll(.*)',
    component: NotFoundPage,
    meta: {
      disablePageScrollingInTemplate: true,
    },
  },
];
