<script setup lang="ts">
import {onBeforeMount} from 'vue';
import {useApi} from '@/ts/composables/stateful/use-api';
import {persistentStorage} from '@/ts/instances/persistant-storage';
import {useRouter} from 'vue-router';
import {pagesPath} from '@/ts/router/pages-path';
import {useLoadingNotification} from '@/ts/composables/pure/use-loading-notification';
import {ZITADEL} from '@/ts/utils/consts';
import {getRedirectUri} from '@/ts/composables/stateful/use-auth';

const api = useApi();
const router = useRouter();

const [submit, _] = useLoadingNotification(
  // in PR the url could be ?code=asd#/public, which means vueRoute().query doesnt work
  async() => api.zitadel.getToken(
    new URLSearchParams(window.location.search).get('code')!,
    ZITADEL.client_id,
    persistentStorage.codeVerifier!,
    getRedirectUri(),
  ),
  (data) => {
    persistentStorage.auth = data;
    void router.push(pagesPath.main.home);
  },
);

onBeforeMount(async() => {
  await submit();
});
</script>
<template>
  <div/>
</template>
