<script lang="ts" setup>
import {useI18n} from 'vue-i18n';
import {GenderDTO} from '@/ts/types/dto/gender-dto';
import {useRequiredSelectRule} from '@/ts/composables/pure/use-input-rules';

const {t: $t} = useI18n();
const requiredRule = useRequiredSelectRule();
const genderOptions = {
  [GenderDTO.PREFER_NOT_TO_SAY]: $t('prefer_not_to_say'),
  [GenderDTO.MALE]: $t('male'),
  [GenderDTO.FEMALE]: $t('female'),
  [GenderDTO.NON_BINARY]: $t('non_binary'),
};

const options = Object.entries(genderOptions)
  .map(([value, label]) => ({label, value: value as GenderDTO}));

const model = defineModel<{ label: string, value: GenderDTO }|null>();
</script>
<template>
  <q-select
    v-model="model"
    :options="options"
    :label="$t('gender')"
    :rules="[requiredRule]"
  />
</template>
