<script setup lang="ts">

import LanguageSwitcher from '@/vue/molecules/language-switcher.vue';

defineProps<{
  name: string;
  html: string;
}>();

</script>
<template>
  <div>
    <div class="headline-row">
      <h2 class="text-heading-1">
        {{ name }}
      </h2>
      <div class="language-switcher-wrapper">
        <language-switcher/>
      </div>
    </div>
    <div class="content" v-html="html"/>
  </div>
</template>
<style lang="scss" scoped>
.headline-row {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: var(--Spacing-spacing-3);
  @include screen-sm {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.content :deep(a) {
  text-decoration: underline;
}

.language-switcher-wrapper {
  display: flex;
  justify-content: end;
  padding: var(--Spacing-spacing-1);
}
</style>
