<template>
  <div class="page-container">
    <router-view/>
  </div>
</template>
<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style lang="scss">
@import '@/assets/sass/global-styles';
@import '@/assets/sass/global-variables';
</style>
<style lang="scss" scoped>
.page-container {
  display: flex;
  max-width: 100%;
  height: 100%;
}
</style>
