<script setup lang="ts">
import {
  onBeforeMount,
  useSlots,
  watch,
} from 'vue';
import OopsTemplate from '@/vue/templates/oops-template.vue';
import {useLogger} from '@/ts/composables/pure/use-logger';
import {useQuasar} from 'quasar';
import ErrorBox from '@/vue/molecules/error-box.vue';
import {DISABLE_LOADING} from '@/ts/utils/consts';

const slots = useSlots();
const logger = useLogger();
const $q = useQuasar();
const props = defineProps<{
  error?: string | null;
  loading: boolean;
  type: 'component' | 'page' | 'page-ignore-header' | 'skeleton';
  id: string;
}>();

defineSlots<{
  loader?(): any;
  error?(): any;
  default(): any;
}>();

onBeforeMount(() => {
  logger.debug(`onBeforeMount ${props.id} ${props.loading}`)();
  if (props.loading && !DISABLE_LOADING && props.type !== 'skeleton') {
    $q.loading.show();
  }
});


watch(() => props.loading, (isLoading) => {
  logger.debug(`isLoading {} ${props.id}`, isLoading)();
  if (!DISABLE_LOADING && props.type !== 'skeleton') {
    if (isLoading) {
      $q.loading.show();
    } else {
      $q.loading.hide();
    }
  }
});

</script>
<template>
  <slot v-if="error && slots.error" name="error"/>
  <oops-template
    v-else-if="error && (type === 'page' || type === 'page-ignore-header')"
    :ignore-header="type === 'page-ignore-header'"
    text-style="dark"
    :text="$t('the_server_encountered')"
    error-type="crash"
  />
  <error-box v-else-if="error"/>
  <template v-else-if="loading">
    <slot v-if="slots.loader" name="loader"/>
    <q-skeleton v-else-if="type === 'skeleton'" class="load-err-skel"/>
  </template>
  <slot v-else name="default"/>
</template>
<style lang="scss" scoped>
.load-err-skel {
  border-radius: var(--card-border-radius);
  height: 400px;
}
</style>
