<script setup lang="ts">
import {
  type Table,
  type TableCell,
  TableDisplayType,
} from '@/ts/types/dto/interventions-dto';
import {computed} from 'vue';

const props = withDefaults(defineProps<{
  table: Table;
  merged?: boolean
}>(), {
  merged: false,
});

interface ComponentRow {
  y: number;
  columns: TableCell[];
}

const tableClass = computed(() => {
  const mapTab: Record<TableDisplayType, string> = {
    [TableDisplayType.COLUMN_HEADER_ROW_BORDERS]: 'row-borders',
    [TableDisplayType.ROW_HEADER_COLUMN_BORDERS]: 'column-borders',
  };
  return `${mapTab[props.table.displayType]}${props.merged ? ' merged' : ''}`;
});

const rows = computed(() => {
  const res: ComponentRow[] = [];
  for (const cell of props.table.cells) {
    if (!res[cell.y]) {
      res[cell.y] = {y: cell.y, columns: []}; // eslint-disable-line id-length
    }
    res[cell.y].columns[cell.x] = cell;
  }
  // eslint-disable-next-line @typescript-eslint/prefer-for-of, id-length
  for (let y = 0; y < res.length; y++) {
    if (!res[y]) {
      throw Error(`Invalid table structure, missing ${y} row`);
    }
    // eslint-disable-next-line @typescript-eslint/prefer-for-of, id-length
    for (let x = 0; x < res[y].columns.length; x++) {
      if (!res[y].columns[x]) {
        throw Error(`Invalid table structure, missing ${y}-${x} cell`);
      }
    }
  }

  return res;
});

</script>
<template>
  <div class="table-component" :class="tableClass">
    <table>
      <tr v-for="row in rows" :key="row.y">
        <td v-for="cell in row.columns" :key="`${cell.y}-${cell.x}`">
          <div v-html="cell.text"/>
        </td>
      </tr>
    </table>
  </div>
</template>
<style scoped lang="scss">
.row-borders {
  table {
    border-collapse: collapse;
  }

  td {
    padding: var(--Spacing-spacing-3) 0;

    &:first-child {
      @include body-medium-3;
      min-width: 200px;
      color: var(--Neutral-UI-neutral-ui-100);
    }
  }

  tr {
    border-bottom: 2px solid var(--Neutral-UI-neutral-ui-10);
  }
}

.column-borders {
  table {
    border-collapse: collapse;
  }

  td:first-child {
    @include body-medium-3;
  }

  tr:first-child {
    @include heading-5;

    td:first-child {
      @include heading-5;
    }

    td {
      padding: var(--Spacing-spacing-3) var(--Spacing-spacing-2);
    }
  }

  td {
    @include body-medium-2;
    padding: var(--Spacing-spacing-5) var(--Spacing-spacing-2);
  }

  tr {
    border-bottom: 2px solid var(--Neutral-UI-neutral-ui-10);
  }
}

.table-component {

  table {
    width: 100%;
  }

  &:not(.merged) {
    padding: var(--Spacing-spacing-7);
    @include q-card-white;

    tr:first-child td {
      padding-top: 0;
    }
  }
}

</style>
