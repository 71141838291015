<script lang="ts" setup>
import {
  SessionState,
  type Page,
  type PrimaryFeeling,
  type Session,
  type ThinkingTrap,
} from '@/ts/types/dto/interventions-dto';
import ReadOnlyPageComponent from '@/vue/molecules/read-only/read-only-page-component.vue';
import {
  computed,
  ref,
} from 'vue';

const props = defineProps<{
  session: Session;
  primaryFeelings: PrimaryFeeling[];
  thinkingTraps: ThinkingTrap[];
}>();

const homeworkCompleted = computed<boolean>(() => Boolean(props.session.homework[0]?.completedDate));

const showOnlyMyContent = ref<boolean>(false);

const pages = computed<Page[]>(() => {
  let pageList: Page[] = [];
  if (homeworkCompleted.value) {
    pageList = [...pageList, ...props.session.homework[0].pages];
  }
  if (props.session.state === SessionState.COMPLETED) {
    pageList = [...pageList, ...props.session.pages];
  }
  return pageList;
});
</script>

<template>
  <q-checkbox
    v-model="showOnlyMyContent"
    :label="$t('show_only_my_answers')"
  />
  <div class="read-only-view">
    <read-only-page-component
      v-for="(page, index) in pages"
      :key="page.id"
      :page="page"
      :index="index + 1"
      :primary-feelings="primaryFeelings"
      :thinking-traps="thinkingTraps"
      :only-my-content="showOnlyMyContent"
    />
  </div>
</template>
<style lang="scss" scoped>
.read-only-view {
  @include q-card-white;
}
</style>
