import {
  type Ref,
  ref,
  watch,
} from 'vue';
import {useQuasar} from 'quasar';
import {useI18n} from 'vue-i18n';
import {extractError} from '@/ts/composables/pure/use-loading-data-ref';
import {loggerInstance} from '@/ts/instances/logger-instance';
import {DISABLE_LOADING} from '@/ts/utils/consts';

const logger = loggerInstance.getLogger('async');

export function useLoadingNotification<R, P extends any[]>(
  cb: (...args: P) => R extends undefined ? Promise<void> : Promise<R>,
  onSuccess?: (a: R, ...args: P) => void,
): [
  (...args: P) => Promise<void>,
  Ref<boolean>,
] {
  const loading: Ref<boolean> = ref<boolean>(false);
  const $q = useQuasar();
  const id = String(cb);
  const {t: $t} = useI18n();

  async function submitHandler(...args: P): Promise<void> {
    loading.value = true;
    logger.trace(`Executing ${id}`)();
    let succeeded: boolean = false;
    let res: R | null = null;
    try {
      res = await cb(...args) as R;
      loading.value = false;
      succeeded = true;
      logger.trace(`Successfully executed ${id}`)();
    } catch (err) {
      logger.error(`Failed ${id} because of {}`, err)();
      loading.value = false;
      succeeded = false;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      $q.notify({
        type: 'negative',
        message: extractError(err, $t),
      });
    }
    // move this thing out of try catch
    if (succeeded && onSuccess) {
      onSuccess(res!, ...args);
    }
  }

  return [submitHandler, loading];
}


/**
 * Proper way to handle any asynchronous operation, e.g. http request.
 * Upon error will call quasar notification.
 * Upon success will call cb.
 * Upon load will set {loading} state to true.
 * To start an asynchronous operation you have to call returned submitHandler
 *
 * @param cb will be called when you call submitHandler
 * @param onSuccess if specified, will be called upon successfully operation completion
 * @returns {loading} will be set to true/false when operation is in progress.
 *
 * @returns [submitHandler] is a trigger, that must be called to execute cb function argument
 * @example
 * const [submitHandler, loading] = useAsyncOperation(
 *   async(answers: CreateQuestionnaireAnswerDTO[]) => api.postOnboardQuestionnaireAnswers(answers),
 * );
 */
export function useLoadingSpinner<R, P extends any[]>(
  cb: (...args: P) => R extends undefined ? Promise<void> : Promise<R>,
  onSuccess?: (a: R, ...args: P) => void,
): (...args: P) => Promise<void> {
  const $q = useQuasar();
  const [fn, loading] = useLoadingNotification(cb, (...args) => {
    if (!DISABLE_LOADING) {
      $q.loading.hide();
    }
    // eslint-disable-next-line babel/no-unused-expressions, @typescript-eslint/no-unused-expressions
    onSuccess && onSuccess(...args);
  });
  watch(loading, () => {
    if (!DISABLE_LOADING) {
      if (loading.value) {
        $q.loading.show();
      } else {
        $q.loading.hide();
      }
    }
  });

  return fn;
}
