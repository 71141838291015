<script setup lang="ts">
import {ContentDisplayType} from '@/ts/types/dto/interventions-dto';
import LazyImage from '@/vue/atoms/lazy-image.vue';
import {computed} from 'vue';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import AudioPlayer from '@/vue/molecules/audio-player.vue';
import {useLogger} from '@/ts/composables/pure/use-logger';

const props = defineProps<{
  name: string | null;
  content?: string;
  audio?: UuidDTO | null;
  image?: UuidDTO | null;
  displayType?: ContentDisplayType;
}>();

const logger = useLogger();

const gridLayout = computed(() => {
  if (props.audio && props.image && props.content) {
    return 'audio-below';
  }
  if (props.name && !props.content && !props.image && !props.displayType) {
    return 'name-only';
  }
  if (!props.displayType) {
    logger.debug('No display type provided, defaulting to text-left')();
    return 'text-left';
  }
  const classMap: Record<ContentDisplayType, string> = {
    [ContentDisplayType.TEXT_LEFT]: 'text-left',
    [ContentDisplayType.TEXT_RIGHT]: 'text-right',
    [ContentDisplayType.TEXT_TOP]: 'text-top',
    [ContentDisplayType.TEXT_BOTTOM]: 'text-bottom',
    [ContentDisplayType.NO_TEXT]: 'no-text',
  };
  return classMap[props.displayType];
});

// TODO setup proper width
const imageWidth = computed(() => props.displayType === ContentDisplayType.NO_TEXT ? 1700 : 750);
</script>
<template>
  <div class="content-block" :class="gridLayout">
    <h4 v-if="name" class="title">
      {{ name }}
    </h4>
    <div v-if="content" class="content" v-html="content"/>
    <lazy-image v-if="image" :src="image" class="image" :width="imageWidth"/>
    <audio-player :audio="audio" class="audio"/>
  </div>
</template>
<style scoped lang="scss">
.image {
  grid-area: media;
  max-height: 400px;
  border-radius: var(--card-border-radius);
}

.title {
  @include body-large-3;
  grid-area: title;
  text-align: left;
}

.audio {
  grid-area: media;
  width: 100%;
  margin: var(--Spacing-spacing-2) 0;
}

.content {
  text-align: start;
  grid-area: content;

  :deep(pre) {
    white-space: pre-wrap; // override so text never goes beyond block width
  }
  :deep(ul) {
    margin: 0; // override so text never goes beyond block width
  }
}

.content-block {
  column-gap: var(--Spacing-spacing-9);
  row-gap: var(--Spacing-spacing-4);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "title media"
    "content media";
}

.name-only {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  column-gap: 0;
  row-gap: 0;
}

.content-block:not(.no-text) {
  grid-template-rows: auto 1fr;
}

.text-left {
  grid-template-areas:
  "title media"
  "content media";
}

.audio-below {
 grid-template-areas:
  "title media"
  "content media"
  "audio audio";
  .audio {
    grid-area: audio;
  }
}

// should be a double selector to override padding: var(4) in parent component
.content-block.no-text {
  grid-template-areas: "media";
  grid-template-columns: 1fr;
  .image {
    margin: 0;
  }
  padding: 0;
  .audio, .content, .title {
    display: none;
  }
}

.text-right {
  grid-template-areas:
  "media title"
  "media content";
}

.text-top {
  grid-template-areas:
  "title title"
  "content content"
  "media media";
}

.text-bottom {
  grid-template-areas:
  "title title"
  "media media"
  "content content";
}
</style>
