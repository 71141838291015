/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  createI18n,
  type I18n,
} from 'vue-i18n';

// these imports are generated by i18-bundle-plugin.ts
import en from '@/locales/en.json';
import de from '@/locales/de.json';

import {loggerInstance} from '@/ts/instances/logger-instance';
import {persistentStorage} from '@/ts/instances/persistant-storage';
import {Language} from '@/ts/types/component/localization.type';
import {MULTI_LANGUAGE, LANGUAGE} from '@/ts/utils/consts';
import {hotjarInstance} from '@/ts/instances/hotjar-instance';


export function i18nFactory(): I18n {
  const logger = loggerInstance.getLogger('i18n');
  const messages = {
    en,
    de,
  };
  logger.debug('Created translations en: {}', messages)();

  const savedLang = persistentStorage.language;
  let locale: Language = LANGUAGE as Language;
  if (!MULTI_LANGUAGE) {
    logger.debug(`Multi language is off, forcing default language ${locale}`)();
  } else if (savedLang) {
    locale = savedLang;
    logger.debug(`Restoring language setting from user cache to ${locale}`)();
  } else {
    const browserLang = navigator.languages.map(lang => new Intl.Locale(lang).language);
    const appLang = Object.values(Language);
    // fall back to German by default
    const supportedBrowserLang = browserLang.find(bl => appLang.includes(bl as Language)) as Language;
    if (supportedBrowserLang) {
      logger.debug(`Detected browser language ${supportedBrowserLang} thus using ${locale}`)();
      locale = supportedBrowserLang;
    } else {
      logger.debug(`Browser language ${browserLang.join(' ')} is not supported`)();
    }
  }
  hotjarInstance.setLanguage(locale);
  logger.debug(`Set user Locale to ${locale}`)();


  return createI18n({
    legacy: false,
    globalInjection: true,
    locale,
    messages,
  });
}
