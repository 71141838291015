<script lang="ts" setup>

import SvgIllustration from '@/vue/atoms/svg-illustration.vue';

defineProps<{
  icon: string,
  active?: boolean,
  padded?: boolean,
  label?: string, // Optionality caused by legacy reasons. Not visible. Should be always set. TODO: make it mandatory
  useLabelAsTitleAttribute?: boolean;
}>();

defineEmits<{
  click: [],
}>();

</script>
<template>
  <q-btn
    round
    flat
    class="focus-visible"
    :dense="!padded"
    :class="{active}"
    :title="useLabelAsTitleAttribute ? label : null"
    @click="$emit('click')"
  >
    <svg-illustration :name="icon"/>
    <span class="sr-only">{{ label }}</span>
  </q-btn>
</template>
<style lang="scss" scoped>
.active {
  background-color: var(--Secondary-secondary-color-1);
}
</style>
