/* eslint-disable @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars */
import {type HotJarProxy, HotJar} from '@/ts/utils/hotjar';
import {HOTJAR} from '@/ts/utils/consts';
import type {UserInfoSetter} from '@/ts/types/hotjar';
import type {Language} from '@/ts/types/component/localization.type';


const hotjarInstance: HotJarProxy = HOTJAR ?
  new HotJar(HOTJAR.id, HOTJAR.sv)
  : {
    setInfo(data: UserInfoSetter | null): void {},
    setLanguage(data: Language): void {},
  };

export {hotjarInstance};
