import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  type Router,
  type RouteRecordRaw,
  type RouterHistory,
} from 'vue-router';
import {mainPages} from '@/ts/router/pages/main-pages';
import {ROUTER_BASE_URL} from '@/ts/utils/consts';
import {publicPages} from '@/ts/router/pages/public-pages';

import {pagesPath} from '@/ts/router/pages-path';
import {persistentStorage} from '@/ts/instances/persistant-storage';


export function routerFactory(): Router {
  const routes: RouteRecordRaw[] = [
    mainPages,
    ...publicPages,
  ];
  const history: RouterHistory = ROUTER_BASE_URL ? createWebHashHistory(ROUTER_BASE_URL) : createWebHistory();

  const router = createRouter({
    history,
    routes,
  });
  router.beforeEach((to, from, next) => {
    if (to.matched[0]?.meta?.auth && !persistentStorage.auth?.access_token) {
      next(pagesPath.public.signUp);
    } else {
      next();
    }
  });
  return router;
}
