<script lang="ts" setup>
import {useRequiredSelectRule} from '@/ts/composables/pure/use-input-rules';
import {computed} from 'vue';
import type {QuasarOption} from '@/ts/types/component/select-multichoice';
import {useI18n} from 'vue-i18n';
import {EducationLevelDTO} from '@/ts/types/dto/sign-in-dto';

const model = defineModel<EducationLevelDTO>();
const {t: $t} = useI18n();

const educations = computed<QuasarOption<EducationLevelDTO>[]>(() => Object.values(EducationLevelDTO).map(value => ({
  label: $t(value as string),
  value,
})));


const requiredRule = useRequiredSelectRule();

const value = computed<QuasarOption<EducationLevelDTO>>({
  get() {
    return educations.value.find(dcl => dcl.value === model.value)!;
  },
  set(newValue) {
    model.value = newValue.value;
  },
});

</script>
<template>
  <q-select
    v-model="value"
    use-input
    :options="educations"
    :label="$t('education_level')"
    name="education"
    :rules="[requiredRule]"
  />
</template>
