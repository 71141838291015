<script setup lang="ts">
import type {ContentResource} from '@/ts/types/dto/home-dto';
import {computed} from 'vue';
import type {SideBarGroupedSection} from '@/ts/types/component/balm-sidebar';
import SidebarName from '@/vue/molecules/sidebar/sidebar-name.vue';
import {groupByCategory} from '@/ts/composables/pure/use-content-sidebar';
import SidebarSection from '@/vue/molecules/sidebar/sidebar-section.vue';

const props = defineProps<{
  resources: ContentResource[];
}>();

const grouped = computed<SideBarGroupedSection[]>(() => groupByCategory(props.resources, item => ({
  id: item.id,
  html: item.description!,
  label: item.name,
})));

</script>
<template>
  <sidebar-name :name="$t('resources')"/>
  <sidebar-section
    v-for="group in grouped"
    :key="group.id"
    :name="group.name"
    :items="group.items"
  />
</template>

