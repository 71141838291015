<script setup lang="ts">

import SessionTwoBlocks from '@/vue/molecules/components/helpers/session-two-blocks.vue';
import ExampleBlock from '@/vue/molecules/components/helpers/example-block.vue';
import {useRequiredRule} from '@/ts/composables/pure/use-input-rules';

defineProps<{
  name: string;
  exampleHtml?: string | null;
  descriptionHtml?: string | null;
}>();

const model = defineModel<string>();
const requiredRule = useRequiredRule();

</script>
<template>
  <session-two-blocks :name="name" :description-html="descriptionHtml" :show-btn="false">
    <template #left>
      <example-block v-if="exampleHtml" :example-html="exampleHtml"/>
    </template>
    <div>
      <q-input
        v-model="model"
        bg-color="white"
        class="input"
        :rules="[requiredRule]"
        type="textarea"
      />
    </div>
  </session-two-blocks>
</template>
<style scoped lang="scss">
.input :deep(textarea) {
  padding: var(--Spacing-spacing-6);
}
</style>
