import {
  ROUTER_BASE_URL,
  ZITADEL,
} from '@/ts/utils/consts';
import {persistentStorage} from '@/ts/instances/persistant-storage';
import {pagesPath} from '@/ts/router/pages-path';
import {useApi} from '@/ts/composables/stateful/use-api';
import {useLang} from '@/ts/composables/stateful/use-lang';


//
// Generates a secure random string of the specified length.
// Same as Math.random for hex string output but secure
//
function generateSecureRandomString(byteLength: number): string {
  // Create an array with the specified number of bytes
  const array = new Uint8Array(byteLength);

  // Populate the array with secure random values
  window.crypto.getRandomValues(array);

  // Convert the byte array to a string of hexadecimal characters
  // 16 0-9a-f characters per byte
  // 2 is 2 symb max
  return Array.from(array, byte => (`0${byte.toString(16)}`).slice(-2)).join('');
}

function arrayBufferToBase64(buffer: ArrayBuffer): string {
  // Step 1: Convert ArrayBuffer to Uint8Array
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;

  // Step 2: Create binary string from Uint8Array
  for (let index = 0; index < len; index++) {
    binary += String.fromCharCode(bytes[index]);
  }

  // Step 3: Encode binary string to Base64
  return window.btoa(binary);
}


//
// Creates a PKCE (Proof Key for Code Exchange):
//
async function getCodeChallenge(codeVerifier: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await window.crypto.subtle.digest('SHA-256', data);
  const base64Digest = arrayBufferToBase64(digest);
  // Make it urls safe for query params
  return base64Digest
    .replace(/\+/gu, '-')
    .replace(/\//gu, '_')
    .replace(/[=]/gu, '');
}

export function getRedirectUri(): string {
  const appUrl = ROUTER_BASE_URL ?
  `${window.location.origin}${ROUTER_BASE_URL.substring(0, ROUTER_BASE_URL.length - 1)}#`
  : window.location.origin;
  return `${appUrl}${pagesPath.public.continueAuth}`;
}

export function useSignIn() {
  const api = useApi();
  const {lang} = useLang();
  return async function signIn(): Promise<void> {
    // zitadel uses 108 characetsr for code verifier
    const codeVerifier = generateSecureRandomString(102);
    const codeChallenge = await getCodeChallenge(codeVerifier);
    persistentStorage.codeVerifier = codeVerifier;
    api.zitadel.redirectToSignIn(getRedirectUri(), codeChallenge, ZITADEL, lang.value);
  };
}
