<script lang="ts" setup>
defineProps<{
  theme: 'blue' | 'white' | 'green' | 'red'
  label: string
}>();
</script>

<template>
  <div class="current" :class="theme">
    {{ label }}
  </div>
</template>
<style lang="scss" scoped>
.current {
  border-radius: var(--button-border-radius);
  @include satoshi-10;
  color: var(--Neutral-UI-neutral-ui-100);
  height: fit-content;
  padding: var(--Spacing-spacing-1) var(--Spacing-spacing-3);
  display: flex;
  align-items: center;
  &.white {
    background-color: var(--Neutral-UI-neutral-ui-10)
  }
  &.blue {
    background-color: var(--Secondary-secondary-color-1);
  }
  &.green {
    color: var(--color-white);
    background-color: var(--Primary-primary-color-2);
  }
  &.red {
    color: var(--color-white);
    background-color: var(--Primary-primary-color-3);
  }
}
</style>
