<script setup lang="ts">
import SessionTwoBlocks from '@/vue/molecules/components/helpers/session-two-blocks.vue';
import ExampleBlock from '@/vue/molecules/components/helpers/example-block.vue';
import PossibleSolution from '@/vue/molecules/components/problem-solving/possible-solution.vue';
import type {Solution} from '@/ts/types/dto/interventions-dto';

defineProps<{
  exampleHtml: string;
  descriptionHtml: string;
}>();

const model = defineModel<Solution[]>();

defineEmits<{
  add: [];
  delete: [number];
}>();

</script>
<template>
  <session-two-blocks
    :name="$t('possible_solutions')"
    :description-html="descriptionHtml"
    :show-btn="true"
    @add="$emit('add')"
  >
    <template #left>
      <example-block :name="$t('solution')" :example-html="exampleHtml"/>
    </template>
    <div class="solutions">
      <possible-solution
        v-for="(key, i) in model"
        :key="i"
        v-model="model![i]"
        :show-header="i === 0"
        :show-delete="model!.length > 1"
        @delete="$emit('delete', i)"
      />
    </div>
  </session-two-blocks>
</template>
<style scoped lang="scss">
.solutions {
  display: flex;
  flex-direction: column;
  gap: var(--Spacing-spacing-4);
}
</style>
