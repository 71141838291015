import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import {MEDIA_URL} from '@/ts/utils/consts';

export function uniqueOnly<T>(array: T[]): T[] {
  return [...new Set(array)];
}

export function uniqueOnlyById<T>(array: T[], getter: (value: T) => number | string): T[] {
  return array.filter((item, index) => array.findIndex((inner) => getter(inner) === getter(item)) === index);
}

export async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms)); // eslint-disable-line no-promise-executor-return
}

export function getRandomId(): string {
  return Math.floor(Math.random() * Date.now()).toString(36);
}


export function calculateMediaUrl(id: UuidDTO): string {
  return `${MEDIA_URL}/${id}`;
}

export function groupBy<T>(xs: T[], keyExtractor: (item: T) => string): Record<string, T[]> {
  return xs.reduce<Record<string, T[]>>((resVal, curVal) => {
    const key = keyExtractor(curVal);
    if (!resVal[key]) {
      resVal[key] = [];
    }
    resVal[key].push(curVal);
    return resVal;
  }, {});
}

/* eslint-disable @typescript-eslint/no-magic-numbers */
export function hexToRGBA(hex: string, alpha: number): string {
  const red = parseInt(hex.slice(1, 3), 16);
  const green = parseInt(hex.slice(3, 5), 16);
  const blue = parseInt(hex.slice(5, 7), 16);
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}

// Format ISO datetime string as YYYYMMDDTHHMMSSZ for ICS files
export function formatICSDate(utcDateStr: string): string {
  return `${utcDateStr.replace(/[-:]/gu, '').split('.')[0]}Z`;
}

