<script setup lang="ts">
import MainNavigation from '@/vue/organisms/header/main-navigation.vue';
import MobileOnlyBalmSidebarMenu from '@/vue/organisms/sidebar/mobile-only-balm-sidebar-toolbar.vue';
import {useScreenSize} from '@/ts/composables/stateful/use-screen-size';
import {useDrawerStore} from '@/ts/store/drawer-store';
import {storeToRefs} from 'pinia';
import type {NavigationItem} from '@/ts/types/component/navigation';
import DrawerOnMobile from '@/vue/molecules/drawer-on-mobile.vue';
import FlatIconBtn from '@/vue/molecules/flat-icon-btn.vue';
import {useOopsieStore} from '@/ts/store/oopsie.store';

defineProps<{
  mainNavigationItems?: NavigationItem[];
}>();

const emit = defineEmits<{
  clickMainNavigationItem: [string],
  requestOpenerFocus: [],
}>();

const {isMobileResolution} = useScreenSize();
const drawerStore = useDrawerStore();
const {isMenuDrawerOpen} = storeToRefs(drawerStore);

const oopsieStore = useOopsieStore();
const {oopsPageTemplateVisible} = storeToRefs(oopsieStore);

function onClickClose(): void {
  drawerStore.closeHamburgerMenuDrawer();
  emit('requestOpenerFocus');
}

</script>

<template>
  <drawer-on-mobile :is-open="isMenuDrawerOpen" @swipe-or-esc-to-close="onClickClose">
    <div class="menu" :class="{'menu--drawer-closed': (isMobileResolution && !isMenuDrawerOpen)}">
      <div v-if="isMobileResolution" class="menu-header">
        <span class="text-md-bold">{{ $t('main_menu_title') }}</span>
        <flat-icon-btn
          icon="x-close.svg"
          :label="$t('aria_close_main_menu')"
          @click="onClickClose"
        />
      </div>
      <main-navigation
        v-if="mainNavigationItems"
        :main-navigation-items="mainNavigationItems"
        @clickMainNavigationItem="emit('clickMainNavigationItem', $event)"
      />
      <mobile-only-balm-sidebar-menu v-if="isMobileResolution && !oopsPageTemplateVisible"/>
    </div>
  </drawer-on-mobile>
</template>

<style lang="scss" scoped>
.menu {
  position: relative;
  z-index: 1;

  &--drawer-closed {
    display: none;
  }

  @include screen-lg {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.menu-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  gap: var(--Spacing-spacing-2);
  height: 50px;
  align-items: center;
  padding: 0 var(--Spacing-spacing-1);
  margin-bottom: var(--Spacing-spacing-4);
}
</style>
