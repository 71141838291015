/*
 eslint-disable max-lines, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
 */

import type {Logger} from 'lines-logger';
import type {
  DeleteParams,
  GetParams,
  ParamsCommon,
  PostMultipartParams,
  PostParams,
  PutParams,
} from '@/ts/types/component/http-wrapper.type';
import {
  AppErrorCode,
  ErrorCodesDto,
} from '@/ts/types/dto/error-codes-dto';
import {AppError} from '@/ts/utils/errors';

export class FetchWrapper {
  private static readonly httpAccessDenied = 401;
  private static readonly httpNoContent = 204;
  private static readonly httpNotFound = 404;
  private static readonly httpOK = 200;
  private static readonly httpLast = 299;

  constructor(
    public readonly backendUrl: string,
    private readonly getGlobalHeaders: () => Promise<Record<string, string>> | Record<string, string>,
    private readonly onAccessDenied: () => Promise<void>,
    private readonly logger: Logger,
  ) {
  }

  public async get<T>(data: GetParams): Promise<T> {
    return this.makeRequest<T>(
      'GET',
      null,
      data,
    );
  }

  public async delete<T>(data: DeleteParams): Promise<T> {
    return this.makeRequest<T>(
      'DELETE',
      null,
      data,
    );
  }

  public async post<T>(data: PostParams): Promise<T> {
    return this.makeRequest<T>(
      'POST',
      JSON.stringify(data.body),
      data,
    );
  }

  public async postUrlEncoded<T>(data: PostParams<Record<string, string>>): Promise<T> {
    return this.makeRequest<T>(
      'POST',
      new URLSearchParams(data.body!).toString(),
      data,
    );
  }

  public async postMultiData<T>(data: PostMultipartParams): Promise<T> {
    return this.makeRequest<T>(
      'POST',
      data.body,
      data,
    );
  }

  public async put<T>(data: PutParams): Promise<T> {
    return this.makeRequest<T>(
      'PUT',
      JSON.stringify(data.body),
      data,
    );
  }

  private async makeRequest<T>(
    method: 'DELETE' | 'GET' | 'POST' | 'PUT',
    body: BodyInit | null,
    data: ParamsCommon,
  ): Promise<T> {
    let urlParams = '';
    if (data.queryParams) {
      urlParams = `?${new URLSearchParams(data.queryParams).toString()}`;
    }
    this.logger.log(
      `Fetch ${method}:${this.backendUrl}${data.url}${data.queryParams ? ' urlSearch:' : ''}{} ${body ? ' body:' : ''}{}`,
      data.queryParams ?? '',
      body ?? '',
    )();
    const formattedUrl = `${this.backendUrl}${data.url}${urlParams}`;
    let result: Response;
    try {
      result = await fetch(formattedUrl, {
        method,
        mode: 'cors',
        headers: await this.getAllHeaders(data.headers),
        redirect: 'manual', // avoid zitadel redirect, and we don't use reditect on backend or anything else
        body,
      });
    } catch (err: unknown) {
      this.logger.error(`error happened during fetching ${formattedUrl}`, err)();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      throw new AppError(`${method}:${data.url} ${(err as any)?.message}`, AppErrorCode.NETWORK_ERROR);
    }

    if (result.status === FetchWrapper.httpAccessDenied) {
      await this.onAccessDenied();
      throw new AppError(`401 for ${method}:${data.url}`, AppErrorCode.NETWORK_ERROR);
    }

    const resultBody = await result.text();
    return this.handleResponse<T>(result, data, resultBody, method);
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  private handleResponse<T>(result: Response, data: ParamsCommon, resultBody: string, method: 'DELETE' | 'GET' | 'POST' | 'PUT'): T {
    const isOk = result.status >= FetchWrapper.httpOK && result.status <= FetchWrapper.httpLast;
    if (result.status === FetchWrapper.httpNoContent || (data.emptyResponse && isOk)) {
      return null as any as T;
    }
    if (result.status === FetchWrapper.httpNotFound && data.handle404) {
      return null as any as T;
    }
    let response: any;
    try {
      response = JSON.parse(resultBody) as T;
    } catch (error) {
      throw Error(resultBody);
    }

    if (result.ok) {
      return response as T;
    }

    if (Object.values(ErrorCodesDto).includes(response?.errorCode)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument,
      throw new AppError(response.message ?? `${method}:${data.url} ${response.message}`, response.errorCode);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      throw new AppError(`Unknown err ${method}:${data.url} ` +
        `: ${response?.errorCode}, message: ${response?.message}`, ErrorCodesDto.UNKNOWN);
    }
  }

  private async getAllHeaders(originHeaders?: Record<string, string>): Promise<Record<string, string>> {
    return {
      'Content-Type': 'application/json', // eslint-disable-line @typescript-eslint/naming-convention
      ...originHeaders,
      ...(await this.getGlobalHeaders()),
    };
  }
}
