<script setup lang="ts">
import {useLoadingSpinner} from '@/ts/composables/pure/use-loading-notification';
import BalmBtn from '@/vue/atoms/balm-btn.vue';
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';
import {useSignIn} from '@/ts/composables/stateful/use-auth';
import {useScreenSize} from '@/ts/composables/stateful/use-screen-size';

const {isDesktopResolution} = useScreenSize();
const signIn = useSignIn();

const toSignIn = useLoadingSpinner(async() => signIn());
</script>
<template>
  <div class="sign-up-header">
    <h1 class="headline">
      {{ $t('sign_up') }}
      <svg-illustration name="right-down-arrow.svg"/>
    </h1>
    <div class="header-right">
      <span class="have-acc-text">{{ $t('already_have_account') }}</span>
      <balm-btn
        :label="$t('sign_in')"
        class="sign-in-btn"
        paddings="p2-5"
        :theme="isDesktopResolution ? 'blue' : 'plain-underline'"
        :icon="isDesktopResolution ? 'arrow-narrow-right.svg' : undefined"
        @click="toSignIn"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.sign-up-header {
  margin-bottom: var(--Spacing-spacing-4);
  display: flex;
  justify-content: space-between;
  @include screen-lg {
    padding: var(--Spacing-spacing-3) 0 0 0;
  }
}

.headline {
  @include heading-5;
  display: flex;
  align-items: center;
  gap: var(--Spacing-spacing-2);
  @include screen-lg {
    @include heading-1;
  }
}

.have-acc-text {
  display: none;
  @include screen-lg {
    display: inline;
    @include body-medium-2;
  }
}

.header-right {
  display: flex;
  align-items: center;
  gap: var(--Spacing-spacing-4);
  color: var(--Neutral-UI-neutral-ui-100);
}

.sign-in-btn {
  position: relative;
  left: var(--Spacing-spacing-3);
}
</style>
