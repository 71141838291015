<script lang="ts" setup>

defineProps<{
  items: string[];
}>();
</script>

<template>
  <ul>
    <li v-for="(item, i) in items" :key="i">
      {{ item }}
    </li>
  </ul>
</template>
<style lang="scss" scoped>
ul {
  padding-left: var(--Spacing-spacing-4);
}
</style>
