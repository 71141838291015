<script lang="ts" setup>
import FeelingChip from '@/vue/molecules/feeling-chip.vue';
import ReadOnlyNonCollapsableTitle from '@/vue/molecules/read-only/helpers/read-only-non-collapsable-title.vue';

defineProps<{
  header: string;
  items: string[];
  active: boolean;
}>();
</script>

<template>
  <read-only-non-collapsable-title :header="header" :active="active">
    <div class="read-only-chips-body">
      <feeling-chip v-for="item in items" :key="item" :name="item" no-x/>
    </div>
  </read-only-non-collapsable-title>
</template>
<style lang="scss" scoped>
.read-only-chips-body {
  display: flex;
  gap: var(--Spacing-spacing-2);
}
</style>
