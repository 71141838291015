<script setup lang="ts">

import type {ThinkingTrap} from '@/ts/types/dto/interventions-dto';
import ThinkingTrapChip from '@/vue/molecules/thinking-trap-chip.vue';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import SessionTwoBlocks from '@/vue/molecules/components/helpers/session-two-blocks.vue';
import FormRequired from '@/vue/atoms/form-required.vue';
import {useLogger} from '@/ts/composables/pure/use-logger';

defineProps<{
  descriptionHtml?: string;
  traps: ThinkingTrap[];
}>();

const model = defineModel<UuidDTO[]>();
const logger = useLogger();

function onSelect(trap: ThinkingTrap): void {
  if (model.value?.includes(trap.id)) {
    logger.trace(`emit remove trap ${trap.id}`)();
    model.value = model.value!.filter((id) => id !== trap.id);
  } else {
    const emittedValue: UuidDTO[] = [...(model.value ?? [])!, trap.id];
    logger.trace(`emit add thinking trap => ${String(emittedValue)}`)();
    model.value = emittedValue; // push doesn't work since we need to emit a new array
  }
}

</script>
<template>
  <session-two-blocks :name="$t('thinking_traps')" :description-html="descriptionHtml">
    <div class="traps-holder">
      <thinking-trap-chip
        v-for="trap in traps"
        :key="trap.id"
        :name="trap.name"
        :active="model!.includes(trap.id)"
        @click="onSelect(trap)"
      />
      <form-required id="thinking-traps" :valid="model!.length > 0" :message="$t('select_one_option')"/>
    </div>
  </session-two-blocks>
</template>
<style lang="scss" scoped>
.traps-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--Spacing-spacing-2);
}
</style>
