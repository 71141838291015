<script setup lang="ts">
import type {Questionnaire} from '@/ts/types/dto/interventions-dto';
import {computed, onBeforeMount, ref} from 'vue';
import {
  AnswerComponentType,
  type QuestionnaireAnswers,
  type SessionRequestQuestionnaire,
} from '@/ts/types/dto/session-request-dto';
import QuestionComponent from '@/vue/molecules/components/question/question-component.vue';
import {useLogger} from '@/ts/composables/pure/use-logger';
import BalmDialog from '@/vue/atoms/balm-dialog.vue';
import BalmBtn from '@/vue/atoms/balm-btn.vue';
import BalmProgressCount from '@/vue/atoms/balm-progress-count.vue';
import QuestionnaireScore from '@/vue/molecules/components/questionnaire/questionnaire-score.vue';
import BalmSlider from '@/vue/atoms/balm-slider.vue';

const props = defineProps<{
  questionnaire: Questionnaire;
  loading: boolean;
}>();

const model = defineModel<SessionRequestQuestionnaire[] | null>();
const isModalOpen = defineModel<boolean>('isModalOpen');
const emit = defineEmits<{
  update: []
  finish: []
}>();

const currentQuestionIndex = ref(0);
const logger = useLogger();
const totalSteps = computed(() => {
  if (props.questionnaire.hasScore) {
    return props.questionnaire.questions.length + 1;
  }
  return props.questionnaire.questions.length;
});
const insideQuestionnaire = computed(() => !(props.questionnaire.hasScore && currentQuestionIndex.value === totalSteps.value - 1));

const value = computed<QuestionnaireAnswers | null>({
  get() {
    return model.value?.[currentQuestionIndex.value]?.questionnaireAnswers ?? null;
  },
  set(newValue) {
    const res = model.value ?? [];
    res[currentQuestionIndex.value] = {
      questionnaireAnswers: newValue!,
      type: AnswerComponentType.QUESTIONNAIRE,
    };
    model.value = [...res];
  },
});

function save(): void {
  logger.debug('Saving questionnaire')();
  currentQuestionIndex.value++;
  if (currentQuestionIndex.value === props.questionnaire.questions.length) {
    emit('update');
    if (!props.questionnaire.hasScore) {
      emit('finish');
      currentQuestionIndex.value--; // roll back from outside right value
    }
  } else if (currentQuestionIndex.value > props.questionnaire.questions.length) {
    emit('finish');
    currentQuestionIndex.value--; // roll back from outside right value
  }
}

onBeforeMount(() => {
  logger.debug(`onBeforeMont ${props.questionnaire.id}`)();
  const index = props.questionnaire.questions.findLastIndex((val) => val.isAnswered);
  currentQuestionIndex.value = index < 0 ? 0 : totalSteps.value - 1;
});

</script>

<template>
  <balm-dialog v-model="isModalOpen" type="h900" theme="grey-10">
    <div class="questionnaire-standard-modal">
      <h2 class="text-heading-1 headline">
        {{ questionnaire.name }}
      </h2>
      <q-form class="form" @submit="save">
        <div v-if="insideQuestionnaire">
          <balm-slider :slide-index="currentQuestionIndex" :total-slides="totalSteps">
            <question-component
              v-model="value"
              :questionnaire-id="questionnaire.id"
              :question="questionnaire.questions[currentQuestionIndex]"
              @ready-to-go-next="save"
            />
          </balm-slider>
        </div>
        <questionnaire-score v-else :score="questionnaire.score" :loading="loading"/>
        <div class="question-standard-bot">
          <balm-progress-count :total="totalSteps" :current="currentQuestionIndex"/>
          <div class="btns">
            <balm-btn v-if="currentQuestionIndex > 0" icon="arrow-left.svg" theme="grey" @click="currentQuestionIndex--"/>
            <balm-btn v-if="totalSteps - 1 !== currentQuestionIndex" type="submit" :label="$t('next')" icon="arrow-narrow-right.svg"/>
            <balm-btn
              v-else
              type="submit"
              :loading="loading"
              :label="$t('complete_assessment')"
              icon="check.svg"
              theme="green"
              :disable="loading"
            />
          </div>
        </div>
      </q-form>
    </div>
  </balm-dialog>
</template>
<style scoped lang="scss">
.questionnaire-standard-modal {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.btns {
  display: flex;
  gap: var(--Spacing-spacing-5);
}

.headline {
  margin-bottom: var(--Spacing-spacing-12);
}

.form {
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.question-standard-bot {
  display: flex;
  margin-top: auto;
  padding-top: var(--Spacing-spacing-5);
  justify-content: space-between;
}
</style>
