<script setup lang="ts">

import BalmDialog from '@/vue/atoms/balm-dialog.vue';
import HtmlFormatted from '@/vue/molecules/html-formatted.vue';
import {
  computed,
} from 'vue';
import {LegalModel} from '@/ts/types/component/public-sign-up-page';
import type {GetLegalDocsResponse} from '@/ts/types/dto/tos-dto';

const props = defineProps<{
  legalDocs: GetLegalDocsResponse;
}>();

const legalModel = defineModel<LegalModel | null>('legalModel', {required: true});

const dialogOpened = computed({
  get: () => Boolean(legalModel.value),
  set: (value: boolean) => {
    if (value) {
      throw Error('WTF');
    } else {
      legalModel.value = null;
    }
  },
});

const modalHtml = computed(() => {
  if (!legalModel.value || !props.legalDocs) {
    return '';
  } else if (legalModel.value === LegalModel.IMPRINT) {
    return props.legalDocs!.imprint.legalCopyHTML;
  } else if (legalModel.value === LegalModel.TERMS_OF_SERVICE) {
    return props.legalDocs!.termsAndConditions.legalCopyHTML;
  } else if (legalModel.value === LegalModel.PRIVACY_POLICY) {
    return props.legalDocs!.privacyPolicy.legalCopyHTML;
  }
  throw Error('invalid legal model');
});
</script>
<template>
  <balm-dialog v-model="dialogOpened" theme="neutral-0" type="fit-content">
    <html-formatted :html="modalHtml" background="neutral-0"/>
  </balm-dialog>
</template>
<style scoped lang="scss">

</style>
