import {
  AnswerComponentType,
  type SessionRequestConceptualizationWorksheet,
} from '@/ts/types/dto/session-request-dto';
import type {ConceptualizationWorksheetData} from '@/ts/types/dto/interventions-dto';
import type {
  ConceptualizationComponentProps,
  ConceptualizationComponentValue,
} from '@/ts/types/component/conceptualization-component';


export function getQuestionnaireAnswers(
  newValue: ConceptualizationComponentValue,
  props: ConceptualizationComponentProps,
): SessionRequestConceptualizationWorksheet[] {
  const res: SessionRequestConceptualizationWorksheet[] = [{
    type: AnswerComponentType.CONCEPTUALIZATION_WORKSHEET,
    answerConceptualizationWorksheet: {
      answer: {
        situation: newValue.situation,
        questionnaireAnswers: newValue.questionnaire.map(quest => quest.questionnaireAnswers),
        primaryFeelingsIds: newValue.primaryFeelingsIds,
        secondaryFeelingsIds: newValue.secondaryFeelingsIds,
        physicalReactions: newValue.physReactions,
        behaviors: newValue.behaviors,
        thinkingTrapsIds: newValue.thinkingTraps,
        copingThoughts: newValue.copyingThoughts,
        thoughts: newValue.thoughts,
      },
    },
  }];
  if (props.worksheet.userComponentId) {
    res[0].answerConceptualizationWorksheet.userComponentId = props.worksheet.userComponentId;
  }
  return res;
}

export function getConceptualizationData(data: ConceptualizationWorksheetData<string[]>): string[] {
  if (!data.show) {
    return [];
  }
  if (data.value && data.value.length > 0) {
    return data.value;
  }
  return [''];
}

export function getDefaultQuestionnaireAnswers(props: ConceptualizationComponentProps): ConceptualizationComponentValue {
  return {
    situation: props.worksheet.situation.value || '',
    thoughts: getConceptualizationData(props.worksheet.thoughts),
    behaviors: getConceptualizationData(props.worksheet.behaviors),
    physReactions: getConceptualizationData(props.worksheet.physicalReactions),
    copyingThoughts: getConceptualizationData(props.worksheet.copingThoughts),
    primaryFeelingsIds: props.worksheet.primaryFeelingsIds.value || [],
    secondaryFeelingsIds: props.worksheet.secondaryFeelingsIds.value || [],
    questionnaire: [], // questionnaire is set automatically by a child component
    thinkingTraps: props.worksheet.thinkingTraps.value ?? [],
  };
}
