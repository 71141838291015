<script setup lang="ts">
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';

defineProps<{
  to: string;
  name: string;
  size: 'h1' | 'm3';
}>();
</script>
<template>
  <div class="inter-name" :class="size">
    <q-btn flat :to="to" class="inner-left focus-visible">
      <svg-illustration name="chevron-left.svg"/>
    </q-btn>
    <div>{{ name }}</div>
    <svg-illustration name="right-down-arrow.svg" inner-class="inner-right"/>
  </div>
</template>
<style lang="scss" scoped>
.inter-name {
  display: flex;
  align-items: center;
  margin-bottom: var(--Spacing-spacing-2);

  @include screen-lg {
    margin-bottom: var(--Spacing-spacing-4);
  }

  &.h1 {
    @include heading-5;

    @include screen-lg {
      @include heading-1;
    }
  }
  &.m3 {
    @include body-medium-3;
  }

  .inner-left {
    padding: 0;
    color: var(--Neutral-UI-neutral-ui-50);
    margin-right: var(--Spacing-spacing-2);
  }

  :deep(.inner-right) {
    margin-left: var(--Spacing-spacing-3);
  }
}
</style>
