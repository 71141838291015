<script setup lang="ts">

import type {
  PrimaryFeeling,
  SecondaryFeeling,
} from '@/ts/types/dto/interventions-dto';
import type {UuidDTO} from '@/ts/types/dto/sign-in-dto';
import FeelingsPopup from '@/vue/molecules/components/helpers/feelings-popup.vue';
import {
  computed,
  ref,
} from 'vue';
import SessionTwoBlocks from '@/vue/molecules/components/helpers/session-two-blocks.vue';
import FeelingChip from '@/vue/molecules/feeling-chip.vue';
import SvgIllustration from '@/vue/atoms/svg-illustration.vue';
import FormRequired from '@/vue/atoms/form-required.vue';

const props = defineProps<{
  feelings: PrimaryFeeling[];
  descriptionHtml?: string;
}>();

defineEmits<{
  add: [],
}>();

const primaryModel = defineModel<UuidDTO[]>('primary');
const secondaryModel = defineModel<UuidDTO[]>('secondary');

const open = ref(false);

const values = computed<UuidDTO[]>({
  get() {
    return [...primaryModel.value!, ...secondaryModel.value!] as UuidDTO[];
  },
  set(value: UuidDTO[]) {
    primaryModel.value = value.filter((id) => props.feelings.some((feeling) => feeling.id === id));
    secondaryModel.value = value.filter((id) => !props.feelings.some((feeling) => feeling.id === id));
  },
});

const feelingsMap = computed(() => {
  const all: Record<UuidDTO, string> = {};
  props.feelings.forEach((feeling) => {
    all[feeling.id] = feeling.name;
    feeling.secondaryFeelings.forEach((secondaryFeeling: SecondaryFeeling) => {
      all[secondaryFeeling.id] = secondaryFeeling.name;
    });
  });
  return all;
});

function removeItem(val: UuidDTO): void {
  if (primaryModel.value!.includes(val)) {
    primaryModel.value?.splice(primaryModel.value!.indexOf(val), 1);
  } else {
    secondaryModel.value?.splice(secondaryModel.value!.indexOf(val), 1);
  }
}
</script>
<template>
  <session-two-blocks :name="$t('feelings')" :description-html="descriptionHtml">
    <div class="right">
      <p class="p">
        {{ $t('input_your_feelings') }}
      </p>
      <div class="feelings">
        <feeling-chip
          v-for="value in values"
          :key="value"
          :name="feelingsMap[value]"
          @remove="removeItem(value)"
        />
        <div>
          <!--          // TODO-->
          <q-btn round flat class="btn" @click="open = true">
            <svg-illustration name="plus.svg"/>
          </q-btn>
          <form-required id="feelings-block" :valid="values.length > 0" :message="$t('select_one_option')"/>
        </div>
      </div>
    </div>
    <feelings-popup v-model:ids="values" v-model:is-open="open" :feelings="feelings"/>
  </session-two-blocks>
</template>
<style scoped lang="scss">
.feelings {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  gap: var(--Spacing-spacing-2)
}

.right {
  background-color: var(--color-white);
  padding: var(--Spacing-spacing-4);
  width: 100%;
}

.p {
  @include body-medium-3;
}

.btn {
  flex-shrink: 0;
}
</style>
