<script lang="ts" setup>
import {
  onMounted,
  ref,
  watch,
} from 'vue';
import {useLogger} from '@/ts/composables/pure/use-logger';

const props = defineProps<{
  valid: boolean;
  message: string;
  id: string;
}>();

const logger = useLogger();
const hiddenRequire = ref<HTMLInputElement | null>(null);

function changeValidity(): void {
  if (!hiddenRequire.value) {
    // onWatch is called before onMounted
    logger.debug('hiddenRequire is not mounted yet, skipping validity check')();
    return;
  }
  if (props.valid) {
    hiddenRequire.value!.setCustomValidity('');
  } else {
    hiddenRequire.value!.setCustomValidity(props.message);
  }
}

onMounted(() => {
  logger.debug(`onMounted ${props.id}`)();
  changeValidity();
});

watch(() => props.valid, () => {
  logger.debug(`watch values ${props.id}`)();
  changeValidity();
});


</script>
<template>
  <input ref="hiddenRequire" type="text" class="hidden-require"/>
</template>
<style lang="scss" scoped>
.hidden-require {
  opacity: 0;
  //border: 0;
  padding: 0;
  height: 0;
  width: 0;
}
</style>
