<script lang="ts" setup>
import type {ProblemSolvingWorksheet} from '@/ts/types/dto/interventions-dto';
import ReadOnlySolution from '@/vue/molecules/read-only/helpers/read-only-solution.vue';
import ReadOnlyTable from '@/vue/molecules/read-only/read-only-table.vue';
import ReadOnlyNonCollapsableTitle from '@/vue/molecules/read-only/helpers/read-only-non-collapsable-title.vue';
import ReadOnlyCollapsableHeader from '@/vue/molecules/read-only/helpers/read-only-collapsable-header.vue';
import ReadOnlyList from '@/vue/molecules/read-only/helpers/read-only-list.vue';

defineProps<{
  worksheet: ProblemSolvingWorksheet;
}>();
</script>

<template>
  <read-only-non-collapsable-title
    :content="worksheet.descriptionProblem"
    :content2="worksheet.problem"
    :header="$t('problem')"
    active
  />
  <read-only-non-collapsable-title
    :content="worksheet.descriptionGoal"
    :content2="worksheet.problem"
    :header="$t('goal')"
    active
  />
  <read-only-table v-if="worksheet.table" :table="worksheet.table"/>
  <read-only-collapsable-header
    :content="worksheet.solutionsInstructions"
    :active="false"
    default-opened
  >
    <template #header>
      <div class="possible-solutions-title">
        <b>{{ $t('possible_solutions') }}</b>
        <div v-html="worksheet.solutionsInstructions"/>
      </div>
    </template>
    <read-only-solution
      v-for="(solution, i) in worksheet.solutions"
      :key="solution.name + i"
      active
      :index="i + 1"
      :solution="solution"
    />
  </read-only-collapsable-header>
  <read-only-list
    :header="$t('rewards')"
    active
    :content="worksheet.descriptionRewards"
    :items="worksheet.rewards"
  />
</template>
<style lang="scss" scoped>
.possible-solutions-title {
  b {
    @include body-medium-3;
    display: block;
    margin-bottom: var(--Spacing-spacing-3);
  }
}

</style>
