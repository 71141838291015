<script lang="ts" setup>
import type {Questionnaire} from '@/ts/types/dto/interventions-dto';
import ReadOnlyQuestioinCard from '@/vue/molecules/read-only/questionnaire/read-only-questioin-card.vue';
import ReadOnlyNonCollapsableTitle from '@/vue/molecules/read-only/helpers/read-only-non-collapsable-title.vue';

defineProps<{
  questionnaire: Questionnaire;
}>();
</script>

<template>
  <read-only-non-collapsable-title :header="questionnaire.name" active>
    <div class="read-only-questionnaire-body">
      <div v-if="questionnaire.description" class="description">
        {{ questionnaire.description }}
      </div>
      <div class="read-only-questionnaire-grid-body">
        <read-only-questioin-card
          v-for="question in questionnaire.questions"
          :key="question.userComponentId"
          :question="question"
        />
      </div>
    </div>
  </read-only-non-collapsable-title>
</template>
<style lang="scss" scoped>

.read-only-questionnaire-grid-body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--Spacing-spacing-4);
}

.description {
  margin-bottom: var(--Spacing-spacing-5);
}
</style>
